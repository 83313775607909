import { ITimeslotsItem } from 'interfaces/requests.inerface';
import { baseApi } from '../store';

export const tcrequestsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    sendTcRequest: builder.mutation({
      query: data => ({
        url: '/tcrequests/',
        method: 'POST',
        body: data
      }),
    }),
    getTimeslots: builder.query<ITimeslotsItem[], void>({
      query: () => ({
        url: '/timeslots/',
        method: 'GET',
      }),
      providesTags: ['Timeslots']
    }),
  })
});

export const {
  useSendTcRequestMutation,
  useGetTimeslotsQuery,
} = tcrequestsApi;
