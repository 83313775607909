import { FC } from 'react';
import classnames from 'classnames';

import styles from './explorer-list-item.module.css';

const ExplorerListItemSkelet: FC = () => (
  <div className={classnames(styles.root, styles.loading, 'skeleton-loader')} />
);

export default ExplorerListItemSkelet;
