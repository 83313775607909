import {
  useGetOnboardingQuery,
  useGetProfileDataQuery,
  useGetProfilePermissionsQuery,
} from 'store/user/userApi';

const MainQuery = ({ isLogged }: { isLogged?: boolean }) => {
  useGetProfileDataQuery(undefined, { skip: !isLogged });
  useGetProfilePermissionsQuery(undefined, { skip: !isLogged });
  useGetOnboardingQuery(undefined, { skip: !isLogged });
  return null;
};

export default MainQuery;
