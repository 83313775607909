import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tags } from '@forma/forma-ui-kit';

import styles from './document-info.module.css';

const DocumentInfoSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <span className={classNames(styles.fileIcon, 'skeleton-loader')} />
        <div className={classNames(styles.title, 'skeleton-loader')} />
        <div className={classNames(styles.edit, 'skeleton-loader')} />
      </div>
      <div className={styles.controls}>
        <div className={classNames(styles.buttonSkelet, 'skeleton-loader')} />
        <div className={classNames(styles.buttonSkelet, 'skeleton-loader')} />
        <div className={classNames(styles.buttonSkelet, 'skeleton-loader')} />
        <div style={{ flexGrow: 1 }} />
        <div className={classNames(styles.buttonSkelet, 'skeleton-loader')} />
        <div className={classNames(styles.buttonSkelet, 'skeleton-loader')} />
      </div>
      <div className={styles.features}>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('extension')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('file_size')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('file_upload_date')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('validity')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={classNames(styles.featuresItem, styles.fullWidth)}>
          <div className={styles.featureLabel}>{t('file_description')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={classNames(styles.featuresItem, styles.fullWidth)}>
          <div className={styles.featureLabel}>{t('categories')}:</div>
          <div className={styles.featureValue}>
            <Tags
              className={styles.categories}
              items={[
                { name: '', className: 'skeleton-loader' },
                { name: '', className: 'skeleton-loader' },
                { name: '', className: 'skeleton-loader' },
                { name: '', className: 'skeleton-loader' }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentInfoSkelet;
