import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './explorer-grid-item.module.css';

interface ExplorerGridItemBackProps {
  onClick: () => void,
  isActive: boolean
}

const ExplorerGridItemBack: FC<ExplorerGridItemBackProps> = ({ onClick, isActive }) => {
  const { t } = useTranslation();
  let timer: NodeJS.Timeout|null = null;

  const handleClick = (e: React.MouseEvent) => {
    if (timer) clearTimeout(timer);
    if (e.detail === 1) {
      e.preventDefault();
      timer = setTimeout(() => onClick(), 200);
    } else if (e.detail === 2) onClick();
  };

  return (
    <div className={classNames(styles.root, isActive && styles.active)}>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src="/icons/folders_back.svg"
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {t('go_back')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExplorerGridItemBack;
