import { baseApi } from '../store';

interface ISetTimeslotData {
  timeslotId: string,
  gmt: string,
  roistatVisit?: string
}

interface ISetTimeslotResult extends ISetTimeslotData {
  id: string,
  managerId: string,
  date: string
}

export const amoApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setOnboarding: builder.mutation({
      query: data => ({
        url: '/amo/onboarding',
        method: 'POST',
        body: data
      }),
    }),
    setTimeslot: builder.mutation<ISetTimeslotResult, ISetTimeslotData>({
      query: data => ({
        url: '/amo/timeslots',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Timeslots']
    }),
    sendAmoTcRequest: builder.mutation<{ status: boolean }, { phone: string, social: string }>({
      query: data => ({
        url: '/amo/tcrequest',
        method: 'POST',
        body: data
      }),
    }),

  }),
});

export const {
  useSetOnboardingMutation,
  useSetTimeslotMutation,
  useSendAmoTcRequestMutation
} = amoApi;
