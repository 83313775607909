import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'interfaces/users.interface';
import { RootState } from '../store';
import { defaultLocale } from '../../i18n';

interface UserState {
  language: string,
  profile?: IUser,
  permissions?: string[],
  onboarding: { [key: string]: boolean }|null
}

const initialState: UserState = {
  language: localStorage.getItem('i18nextLng') ?? defaultLocale,
  onboarding: null
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUserLang: (state, action) => {
      state.language = action.payload;
    },
    setUserData: (state, action) => {
      state.profile = action.payload;
    },
    setUserPhone: (state, action) => {
      if (state.profile) state.profile.phone = action.payload;
    },
    setUserEmail: (state, action) => {
      if (state.profile) state.profile.login = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setOnboardingItems: (state, action) => {
      state.onboarding = action.payload;
    }
  }
});

export default userSlice.reducer;

export const { setUserData, setUserEmail, setUserPhone, setUserPermissions, setUserLang, setOnboardingItems } = userSlice.actions;

export const selectUserPermissions = (state: RootState) => state.user.permissions;
export const selectUserData = (state: RootState) => state.user.profile;
export const selectUserLang = (state: RootState) => state.user.language;
export const selectOnboarding = (state: RootState) => state.user.onboarding;
