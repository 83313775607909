import { ICounteragentsList, ICounteragent, ICounteragentType } from 'interfaces/counteragents.interface';
import { baseApi } from '../store';

export const counteragentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCounteragents: builder.query<ICounteragentsList, { filter?: string, from?: number, to?: number, type?: ICounteragentType }>({
      query: data => ({
        url: `/counteragents/`,
        method: 'GET',
        params: data
      }),
      providesTags: ['Counteragents']
    }),
    getCounteragentById: builder.query<ICounteragent, string>({
      query: id => ({
        url: `/counteragents/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Counteragents', id: id }]
    }),
    createCounteragent: builder.mutation<ICounteragent, Omit<ICounteragent, 'id'>>({
      query: data => ({
        url: `/counteragents/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Counteragents']
    }),
    addCounteragents: builder.mutation<ICounteragent[], Omit<ICounteragent, 'id'>[]>({
      query: data => ({
        url: `/counteragents/batch`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Counteragents']
    }),
    updateCounteragent: builder.mutation<ICounteragent, ICounteragent>({
      query: data => ({
        url: `/counteragents/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Counteragents']
    }),
    removeCounteragent: builder.mutation<ICounteragent, string>({
      query: id => ({
        url: `/counteragents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Counteragents']
    }),
  })
});

export const {
  useGetCounteragentsQuery,
  useGetCounteragentByIdQuery,
  useLazyGetCounteragentByIdQuery,
  useCreateCounteragentMutation,
  useUpdateCounteragentMutation,
  useAddCounteragentsMutation,
  useRemoveCounteragentMutation,
} = counteragentsApi;
