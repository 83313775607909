import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import parser from 'ua-parser-js';
import i18n from '../i18n';
import { logoutUser } from './auth/authSlice';
import { setUserData } from './user/userSlice';
import { addNotification } from './notifications/notificationsSlice';
import { baseApi } from './store';

import type { RootState } from './store';

const API_URL = process.env.REACT_APP_API_URL;

const query = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers, { getState } ) => {
    const token = (getState() as RootState).auth.token;
    const language = (getState() as RootState).user.language;
    const { os, device, browser } = parser(navigator.userAgent);

    if (token) headers.set('authorization', `Bearer ${token}`);
    if (language) headers.set('X-Language', `${language}`);

    headers.set('window-w', window.screen.width+'');
    headers.set('window-h', window.screen.height+'');
    headers.set('app-os', `${os.name} ${os.version}`);
    headers.set('app-browser', `${browser.name} ${browser.version}`);
    if (device.vendor) headers.set('app-device', `${device.vendor} ${device.model}`);

    return headers;
  },
});

interface ErrorData {
  error?: string[]
}

const baseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const t = i18n.t;
  const result = await query(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401) {
      // api.dispatch(addNotification({ contente: t('errors.auth_error'), type: 'ERROR' }));
      api.dispatch(logoutUser());
      api.dispatch(setUserData(null));
      api.dispatch(baseApi.util.resetApiState());
    } else if (result.error.status === 'FETCH_ERROR') {
      api.dispatch(addNotification({ content: t('errors.fetch_error'), type: 'ERROR' }));
    }

    const errorData = result.error.data as ErrorData;
    if (errorData?.error) {
      errorData.error.forEach((text) => {
        api.dispatch(addNotification({ content: text, type: 'ERROR' }));
      });
    }
  }

  return result;
};

export default baseQuery;
