import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { ButtonsContainer, Button, LoadingButton, Input, PhoneInput, phonemasks } from '@forma/forma-ui-kit';
import { addNotification } from 'store/notifications/notificationsSlice';
import UserGroups from 'views/profile/UserGroups';
import {
  useGetUserDataQuery,
  useSetUserDataMutation,
  useGetUserGroupsQuery,
  useSetUserGroupsMutation
} from 'store/user/userApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { selectUserPermissions } from 'store/user/userSlice';
import getValidationSchema from 'data/validationSchema';

import ChangePasswordModal from './ChangePasswordModal';

import styles from './Profile.module.css';

interface FormValues {
  name: string,
  login: string
  phone: string
}

const fields: Array<keyof FormValues> = [ 'name', 'login', 'phone' ];

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeUsers = userPermissions?.includes('users');
  const [ isShowChangePass, setShowChangePass ] = useState<boolean>(false);
  const [ newUserGroups, setNewUserGroups ] = useState<{ [key: string]: boolean }|null>(null);

  const { data: user, error: pageError } = useGetUserDataQuery(id, { skip: !id });
  const { data: groups } = useGetGroupsListQuery(undefined, { skip: !canChangeUsers });
  const { data: userGroups } = useGetUserGroupsQuery(id, { skip: !id });

  const [ saveUserData, { isLoading: isSaveDataLoading } ] = useSetUserDataMutation();
  const [ saveUserGroups, { isLoading: isSaveGoupsLoading } ] = useSetUserGroupsMutation();

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    if (!id) navigate('/users');
    // if (id && user && user.id === id) navigate('/profile', { replace: true });
    // eslint-disable-next-line
  }, [id, pageError]);

  const { register, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: user && yupResolver(getValidationSchema(fields))
  });

  useEffect(() => {
    if (!user) return;
    fields.forEach(key => {
      if (key === 'phone') {
        // todo: change to universal format phone
        const x = (user.phone && user.phone.length >= 11) && user.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
        setValue(key, x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '' );
      } else {
        setValue(key, user[key]);
      }
    });
    // eslint-disable-next-line
  }, [user]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (newUserGroups) saveUserGroups({ id: user.id, groupids: Object.keys(newUserGroups).filter(key => !!newUserGroups[key]) });
    const result = await saveUserData(data);

    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  return (
    <AdminLayout
      title={t('user_profile')}
      breadcrumbs={{ items: [ { name: t('settings'), to: '/profile', as: Link }, { name: t('user_profile') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('user_profile')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {!user ? (
            [1, 2, 3].map(key => (
              <div className={classNames(styles.input, 'skeleton-loader')} key={key} />
            ))
          ) : (
            fields.map(key => (
              <Controller
                control={control}
                name={key}
                key={key}
                render={({ field: { value = '', ...rest } }) => {
                  if (key === 'phone') return (
                    <PhoneInput
                      id={key}
                      label={t(`fields.${key}`)}
                      placeholder={t(`placeholders.${key}`)}
                      containerClass={styles.inputContainer}
                      labelClass={styles.label}
                      className={classNames(styles.input, styles.phone)}
                      error={!!errors?.[key]?.message}
                      errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                      {...rest}
                      {...register}
                      value={value}
                      defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                      masks={phonemasks}
                    />
                  );
                  else return (
                    <Input
                      id={key}
                      label={t(`fields.${key}`)}
                      placeholder={t(`placeholders.${key}`)}
                      inputMode={key === 'login' ? 'email' : 'text'}
                      containerClass={styles.inputContainer}
                      labelClass={styles.label}
                      className={styles.input}
                      error={!!errors?.[key]?.message}
                      errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                      {...rest}
                      {...register}
                      value={value}
                      readOnly={key === 'login'}
                    />
                  );
                }}
              />
            ))
          )}
        </div>
        <h2 className={styles.title}>{t('user_permissions_groups')}</h2>
        <UserGroups
          groups={groups}
          selected={userGroups}
          onChange={setNewUserGroups}
        />
        <ButtonsContainer className={styles.buttons}>
          <Button viewStyle="secondary" shadow={true} disabled={true}>{t('change_email')}</Button>
          <Button viewStyle="secondary" shadow={true} onClick={() => setShowChangePass(true)}>{t('change_password')}</Button>
          <LoadingButton
            type="submit"
            isLoading={isSaveGoupsLoading || isSaveDataLoading}
            viewStyle="primary"
            shadow={true}
            disabled={!isValid}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer>
      </form>
      <ChangePasswordModal open={isShowChangePass} onClose={() => setShowChangePass(false)} />
    </AdminLayout>
  );
};

export default UserProfile;
