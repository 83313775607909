import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageRedirect = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const lang = pathname.slice(1, pathname.length);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      navigate('/');
    }
    //eslint-disable-next-line
  }, [lang]);

  return null;
};

export default LanguageRedirect;
