import * as yup from 'yup';

const validationSchema = {
  login: yup.string().required(null).min(3, null).trim().email('validation_email'),
  name: yup.string().required(null).min(2, 'validation_name_length').max(250, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-ZЁё\- ]+$/, 'validation_name_symbols'),
  fullName: yup.string().required(null).min(2, 'validation_name_length').max(250, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-ZЁё\- ]+$/, 'validation_name_symbols'),
  familyName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  firstName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  middleName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  phone: yup.string().required(null).transform(value => value.replace(/[^0-9]/g, ''))
    .matches(/(357|371|356|386|385|382|372|374)\d{8}|(77|32|36|34|31|48|40|33|46|994|995|996|353|359|420|421)[0-9]{9}|(7|0|44|1|43|30|3|358)\d{10}|(4)\d{11}/, 'validation_phone'),
  oldPassword: yup.string().required(null).min(6, 'validation_password_length'),
  password: yup.string().required(null).min(8, 'validation_password_length')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'validation_password_symbols'),
  confirm_password: yup.string().required(null).min(1, null).oneOf([ yup.ref('password'), null ], 'validation_password_missmatch'),
  passwordLogin: yup.string().required(null).min(6, null),
  groupids: yup.string().required(null),
  social: yup.string().required(null),
  company: yup.string().required(null),
  target: yup.string().required(null),
  documentName: yup.string().required(null).min(3, null),
  // inn: yup.string().required(null).oneOf([ yup.string().length(10, null) ], [ yup.string().length(12, null) ], 'validation_inn'),
};

const getValidationSchema = (fields) => yup.object().shape(
  // fields.reduce((prev, current) => ({ ...prev, [current === 'passwordLogin' ? 'password' : current]: validationSchema[current] }), {})
  Object.keys(fields).reduce((prev, current) => {
    let itemKey = null;
    let itemSchema = null;

    if (typeof fields[current] === 'object') {
      itemKey = current;
      // console.log(fields[current], Array.isArray(fields[current]))
      if (Array.isArray(fields[current])) {
        itemSchema = yup.array().of(getValidationSchema(fields[current])).min(1).required(); // array
      } else {
        itemSchema = getValidationSchema(fields[current]); // object
      }
    } else {
      itemKey = fields[current] === 'passwordLogin' ? 'password' : fields[current];
      itemSchema = validationSchema[fields[current]]; // string
    }

    return ({ ...prev, [itemKey]: itemSchema });
  }, {})
);

export default getValidationSchema;
