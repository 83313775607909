import { FC, ReactNode, useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { parse, format } from 'date-fns';
import classNames from 'classnames';
import { Button, SearchInput, Select, ModalChooseDate, ThemeContext } from '@forma/forma-ui-kit';

import { categories_colors } from 'data/mock';
import { IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './safe-documents-header.module.css';

interface SafeDocumentsHeaderProps {
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  },
  onChangeSearch: (value: string) => void,
  onChangeFilter: (data: { dtToFrom?: string, dtToTo?: string, dtFilter?: string, categoriesid?: string }) => void,
  onClickAdd: () => void,
  canAddFiles?: boolean
}

const SafeDocumentsHeader: FC<SafeDocumentsHeaderProps> = ({ categories, onChangeSearch, onChangeFilter, onClickAdd, canAddFiles }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);

  const options = categories.items && Object.values(categories.items).map(({ id, name, color }) => ({
    label:
      <span
        className={styles.label}
        style={{ color: categories_colors[color]?.color, backgroundColor: categories_colors[color]?.background }}
      >
        {name}
      </span>,
    value: id
  }));

  const getPeriodOptions = (dates: string|null, icon: ReactNode) => {
    return [
      {
        label: t('all_time'),
        value: 'all',
        displayLabel: icon
      },
      {
        label: t('week_before_end_contract'),
        value: 'weekToDtTo',
        displayLabel: icon
      },
      {
        label: t('month_before_end_contract'),
        value: 'monthToDtTo',
        displayLabel: icon
      },
      {
        label: t('docs_uploaded_last_month'),
        value: 'uploadedIn30days',
        displayLabel: icon
      },
      {
        label: <div><div>{dates ? t('selected_period') : t('select_period')}</div>{dates && <div>{dates}</div>}</div>,
        value: 'changeDate',
        className: `changeDate ${dates && 'rc-select-item-option-selected'}`,
        displayLabel: icon
      }
    ];
  };

  const icon = <ReactSVG src="/icons/calendar.svg" wrapper="span" className={classNames(styles.selectIcon, styles.active)} />;
  const [ periodOptions, setPeriodOptions ] = useState(getPeriodOptions(null, icon));
  const [ isSelectDateOpen, setSelectDateOpen ] = useState(false);
  const [ selectedPeriod, setSelectedPeriod ] = useState('');


  const handleSelectCategories = (e: { target: { name: string, value: string }}) => {
    onChangeFilter({ categoriesid: e.target.value });
  };

  const handleSelectPeriod = (e: { target: { name: string, value: string }}) => {
    if (e.target.value === 'changeDate') {
      setSelectDateOpen(true);
      setSelectedPeriod('');
    } else {
      setPeriodOptions(getPeriodOptions(null, icon));
      setSelectedPeriod(e.target.value);
      onChangeFilter({ dtFilter: e.target.value, dtToFrom: '', dtToTo: '' });
    }
  };

  const handleChangeDate = (date: string) => {
    const [ from, to ] = date.split(' - ').map((dateStr) => parse(dateStr, 'dd.MM.yyyy', new Date()));
    onChangeFilter({ dtToFrom: format(from, 'yyyy-MM-dd'), dtToTo: format(to, 'yyyy-MM-dd'), dtFilter: '' });
    setPeriodOptions(getPeriodOptions(date, icon));
    setSelectedPeriod('');
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('enter_file_contragent_names')}
      />
      <div className={classNames(styles.select, styles.selectPeriod)}>
        <Select
          name="filter_period"
          onChange={handleSelectPeriod}
          options={periodOptions}
          placeholder={<ReactSVG src="/icons/calendar.svg" wrapper="span" className={styles.selectIcon} />}
          customLabelKey="displayLabel"
          value={selectedPeriod}
          showArrow={false}
          dropdownWidth={viewport === 'phone' ? 280 : 345}
        />
      </div>
      <div className={classNames(styles.select, styles.selectCategories)}>
        <Select
          name="filter_group"
          onChange={handleSelectCategories}
          options={options}
          placeholder={t('category')}
          dropdownWidth={240}
          multiple
        />
      </div>
      {canAddFiles &&
        <Button
          className={styles.button}
          onClick={onClickAdd}
          viewStyle="secondary"
          shadow
          icon={<ReactSVG src="/icons/file-add.svg" wrapper="span" />}
        >
          {t('add_document')}
        </Button>
      }
      <ModalChooseDate
        open={isSelectDateOpen}
        onClose={() => setSelectDateOpen(false)}
        onApply={handleChangeDate}
        onCancel={() => setSelectDateOpen(false)}
      />
    </div>
  );
};

export default SafeDocumentsHeader;
