import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './tariff-subscription.module.css';

const TariffSubscriptionSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{t('subscription.subscription')}</h3>
      <div className={styles.description}>
        <div className={classNames(styles.descriptionRow, 'skeleton-loader')} />
        <div className={classNames(styles.descriptionRow, 'skeleton-loader')} />
        {/* {t('subscription.tariff')} {tariff.name} {t('subscription.for')} {t('count_months', { count: selected.durationMonth })} ({t('subscription.until')} {format(new Date(tariffEnd), 'dd.MM.yyyy')}) */}
      </div>
      <div className={styles.durations}>
        {[...Array(3)].map((item, index) => (
          <button
            className={classNames(styles.duration, styles.disabled)}
            key={index}
          >
            <span className={classNames(styles.durationText, 'skeleton-loader')} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default TariffSubscriptionSkelet;
