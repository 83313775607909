import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Modal, SearchInput } from '@forma/forma-ui-kit';
import Dropdown from '../Dropdown';
import SelectSwitcher from '../SelectSwitcher';
import VariableCreateModal from '../VariableCreateModal';

import { useAppDispatch } from 'store/hooks';
import { setOnboardingModal } from 'store/common/commonSlice';
import { IVariablesHierarhyItem, IVariableItem, IUserVariableItem } from 'interfaces/variables.interface';

import styles from './variable-select-modal.module.css';

interface VariableSelectModalProps {
  open: boolean,
  onClose: () => void,
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  selectedVariables?: string[],
  userVariables?: { [key: string]: IUserVariableItem },
  onToggleVar: (variable: IVariableItem|IUserVariableItem, checked: boolean, group?: { id: string, name: string }) => void,
  icon?: (checked: boolean) => ReactElement|false,

  onAdd?: (variable: IUserVariableItem) => void,
  onChange?: (variable: IUserVariableItem, checked: boolean) => void,
  onRemove?: (id: string) => void,
}

const VariableSelectModal: FC<VariableSelectModalProps> = ({
  open, onClose, variablesGroups, availableVariables, userVariables, onToggleVar, selectedVariables,
  onAdd, onChange, onRemove, icon
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ searchValue, setSearchValue ] = useState('');

  const [ sectionId, setSectionId ] = useState<number|null>(null);
  const [ editVariable, setEditVariable ] = useState<IUserVariableItem|null>(null);
  const [ isShowModalCustom, setShowModalCustom ] = useState<boolean>(false);

  const groupsArray = variablesGroups &&
    Object.values(variablesGroups).reduce((acc: IVariablesHierarhyItem['groups'], { groups }) => [ ...acc, ...groups ], []);

  const handleClose = () => {
    setSearchValue('');
    onClose();
  };

  const handleClickEdit = (data: IUserVariableItem) => {
    if (data.sections.length) setSectionId(data.sections[0]);
    setEditVariable(data);
    setShowModalCustom(true);
  };

  const handleClickCreate = (sectionId: number) => {
    setSectionId(sectionId);
    setShowModalCustom(true);
  };

  const handleCloseCreate = () => {
    setSectionId(null);
    setEditVariable(null);
    setShowModalCustom(false);
  };

  const handleAddVariable = (variable: IUserVariableItem) => {
    setShowModalCustom(false);
    const group = groupsArray?.find(({ groupId }) => groupId === variable.folderid);
    onToggleVar(variable, true, group && { id: group.groupId, name: group.name });
    if (onAdd) onAdd(variable);
  };

  return (
    <Modal
      size="medium"
      open={open}
      title={t('add_variable')}
      onClose={handleClose}
    >
      <div className={styles.modalSelect}>
        <div className={styles.topButtons}>
          <Button
            viewStyle="textPrimary"
            className={styles.howToButton}
            onClick={() => dispatch(setOnboardingModal('add_variables_modal'))}
          >
            {t('guide.howto_add_variables')}
          </Button>
        </div>

        <SearchInput placeholder={t('enter_variable_name')} onSearch={setSearchValue} />

        {searchValue ? (
          <div className={classNames(styles.searchResults, 'styled-scrollbar')}>
            {availableVariables && Object.values(availableVariables)
              .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
              .map(variable => {
                const group = groupsArray?.find(({ items }) => items.includes(variable.id));
                return (
                  <div className={styles.dropdownSwitch} key={variable.id}>
                    <SelectSwitcher
                      icon={icon}
                      label={
                        <span className={styles.searchItemLabel}>
                          {variable.name}
                          <span className={styles.searchItemGroup}>
                            {group?.name}
                          </span>
                        </span>
                      }
                      onChange={checked => onToggleVar(variable, checked, group && { id: group.groupId, name: group.name })}
                      checked={!!selectedVariables?.includes(variable.id)}
                    />
                  </div>
                );
              })
            }
            {userVariables && Object.values(userVariables)
              .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
              .map(variable => (
                <div className={styles.dropdownSwitch} key={variable.id}>
                  <SelectSwitcher
                    icon={icon}
                    label={
                      <span className={styles.searchItemLabel}>
                        {variable.name}
                        <span className={styles.searchItemGroup}>
                          {groupsArray?.find(({ items }) => items.includes(variable.id))?.name}
                        </span>
                      </span>
                    }
                    onChange={checked => onToggleVar(variable, checked)}
                    checked={!!selectedVariables?.includes(variable.id)}
                  />
                </div>
              ))
            }
          </div>
        ) : (
          variablesGroups && Object.values(variablesGroups).map(({ id: scopeId, scope, groups, name }) => (
            <Dropdown
              id={`select_section_${scope}`} // id for tests
              title={name}
              key={scope}
            >
              <>
                <div className={styles.groups}>
                  {groups?.map(({ groupId, name, items }) => (
                    <Dropdown
                      id={`variables_group_${groupId}`} // id for tests
                      title={name}
                      viewStyle="white"
                      size="medium"
                      key={scope + groupId}
                    >
                      <div className={styles.dropdownSwitches}>
                        {availableVariables && items.map((id) => (
                          <div className={styles.dropdownSwitch} key={scope + groupId + id}>
                            <SelectSwitcher
                              id={`variable_${groupId}_${id}`} // id for and tests
                              icon={icon}
                              label={availableVariables[id].name}
                              onChange={checked => onToggleVar(availableVariables[id], checked, { id: groupId, name })}
                              checked={!!selectedVariables?.includes(id)}
                            />
                          </div>
                        ))}
                        {!!userVariables && (
                          Object.values(userVariables).map(({ id, name: varName, sections, folderid, ...rest }) => {
                            if (folderid !== groupId) return null;
                            return (
                              <div className={styles.dropdownSwitch} key={scope + groupId + id}>
                                <SelectSwitcher
                                  id={`variable_${scope}_${id}`} // id for and tests
                                  icon={icon}
                                  label={varName}
                                  onChange={checked =>
                                    onToggleVar({ id, name: varName, sections, ...rest }, checked, { id: folderid, name })
                                  }
                                  checked={!!selectedVariables?.includes(id)}
                                  onClickEdit={() => handleClickEdit(userVariables[id])}
                                />
                              </div>
                            );
                          })
                        )}
                      </div>
                    </Dropdown>
                  ))}
                  {!!(userVariables
                    && Object.keys(userVariables).length
                    && Object.values(userVariables).find(({ sections, folderid }) => (sections?.includes(scopeId)) && !folderid)
                  ) && (
                    <Dropdown className="select-variables-group" title={t('other_requisites')} viewStyle="white" size="medium">
                      <div className={styles.dropdownSwitches}>
                        {Object.values(userVariables).map(({ id, name, sections, folderid, ...rest }) => {
                          if (sections && !sections.includes(scopeId)) return null;
                          if (folderid) return null;
                          return (
                            <div className={styles.dropdownSwitch} key={`${scope}_${id}`}>
                              <SelectSwitcher
                                id={`variable_container_${scope}_${id}`} // id for tests
                                icon={icon}
                                label={name}
                                onChange={checked => onToggleVar({ id, name, sections, ...rest }, checked)}
                                checked={!!selectedVariables?.includes(id)}
                                onClickEdit={() => handleClickEdit(userVariables[id])}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Dropdown>
                  )}
                </div>

                <div className={styles.modalButtons}>
                  <Button
                    viewStyle="primary"
                    size="small"
                    onClick={() => handleClickCreate(scopeId)}
                    data-testid="create_custom_variable"
                    fullWidth
                  >
                    {t('create_custom_variable')}
                  </Button>
                </div>
              </>
            </Dropdown>
          ))
        )}
      </div>

      {isShowModalCustom &&
        <VariableCreateModal
          sectionId={sectionId}
          data={editVariable}
          variablesGroups={variablesGroups}
          onChange={onChange}
          onAdd={handleAddVariable}
          onRemove={onRemove}
          onClose={handleCloseCreate}
        />
      }
    </Modal>
  );
};

export default VariableSelectModal;
