import { FC, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ButtonsContainer, LoadingButton, Modal, nanoid } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import TrialEndModal from 'views/Guide/TrialEndModal';
import copyToClipboard from 'helpers/copyToClipboard';
import webView from 'helpers/webview';
import { TRIAL_ID } from 'data/constants';

import { ISidesListEditData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';
import {
  useRenderTemplateMutation,
  useLazyDownloadRenderedTemplateQuery,
  ITemplateRenderData,
  useRenderHtmlMutation
} from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useGetDocumentByIdQuery } from 'store/documents/documentsApi';

import styles from './ModalChangeDocument.module.css';

interface ModalChangeDocumentProps {
  open: boolean,
  onClose: () => void,
  id: string,
  name: string
}

const ModalChangeDocumentSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_pdf')}
        </LoadingButton>
        <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_docx')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );
};

const ModalChangeDocument: FC<ModalChangeDocumentProps> = ({ open, onClose, id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ searchParams ] = useSearchParams();
  const debug = searchParams.get('debug');

  const { data: documentRender } = useGetDocumentByIdQuery(id, { skip: !open });
  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ rerenderTemplate, { isLoading: isRerenderDownloading } ] = useLazyDownloadRenderedTemplateQuery();
  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderTemplateMutation();
  const [ renderHtml ] = useRenderHtmlMutation();

  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>([]);;
  const [ isChangedData, setChangedData ] = useState<boolean>(false);

  const [ isOpenTrialLock, setOpenTrialLock ] = useState<boolean>(false);
  const [ isOpenTrialEnd, setOpenTrialEnd ] = useState<boolean>(false);

  const handleCheckTrial = (mode: 'sign'|'external') => {
    const isTrial = currentTariff?.tariff.id === TRIAL_ID;
    const isActive = currentTariff?.isActive;

    if (!isTrial) return true;
    if (!isActive) {
      setOpenTrialEnd(true);
      return false;
    } else {
      if (mode === 'sign') {
        setOpenTrialLock(true);
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    if (open) window?.ReactNativeWebView?.postMessage('documentIsOpen');
    else window?.ReactNativeWebView?.postMessage('documentClosed');
  }, [open]);

  const handleClickRender = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
  ) => {
    if (!documentRender) return null;
    renderHtml({ id: documentRender.template.id, name, sideValues }).then(result => {
      if ('data' in result) {
        copyToClipboard(result.data);
        dispatch(addNotification({ content: t('copied'), type: 'SUCCESS' }));
      }
    }).catch(e => {});
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
    isChanged: boolean
  ) => {
    if (!documentRender) return null;
    if (!isChanged) {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.renderDownload(documentRender.template.id, documentRender.id, `${name}.${format}`, format);
      } else {
        rerenderTemplate({ id: documentRender.template.id, rerenderId: documentRender.id, format }).unwrap().then(url => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${name}.${format}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          onClose();
        }).catch(e => {});
      }
    } else {
      if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
        setOpenTrialEnd(true);
        return;
      }

      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.renderTemplate(documentRender.template.id, `${name}.${format}`, { name, format, sideValues });
      } else {
        const data: ITemplateRenderData = {
          id: documentRender.template.id,
          name,
          format,
          sideValues
        };

        renderTemplate(data).unwrap().then(url => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${name}.${format}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          onClose();
        }).catch(e => {});
      }
    }
  };

  // const sides = useMemo(() => documentRender?.sides.map((data, index) => ({ ...data, id: data.id ?? index+1+'' })), [documentRender]);
  // const valueSides = useMemo(() => (documentRender && sides) && documentRender.sideValues?.reduce((acc, data, index) => ({
  //   ...acc,
  //   [sides[index].id]: {
  //     ...data,
  //     id: sides[index].id,
  //     tableValues: data.tableValues?.length > 0 ? (
  //       data.tableValues.map(({ products, ...table }) => ({
  //         ...table,
  //         products: products.map(product => ({ id: nanoid(8), ...product }))
  //       }))
  //     ) : [],
  //     tattrValues: data.tattrValues ?? {},
  //     tattrErrors: {},
  //     // isValid: true,
  //   }
  // }), {}), [documentRender, sides]);

  const { sides, valueSides } = useMemo(() => {
    if (!documentRender?.sides) return { sides: undefined, valueSides: undefined };

    const sides: ITemplateSide[] = [];
    const valueSides: ISidesListEditData = {};
    for (let i=0; i < documentRender.sides.length; i++) {
      const side = documentRender.sides[i];
      const id = side.id ?? i+1+'';
      sides.push({ ...side, id });

      const sideValues = documentRender.sideValues?.[i] ?? {};
      valueSides[id] = {
        ...sideValues,
        id,
        tableValues: sideValues.tableValues?.length > 0 ? (
          sideValues.tableValues.map(({ products, ...table }) => ({
            ...table,
            products: products.map(product => ({ id: nanoid(8), ...product })),
            customProductsIds: []
          }))
        ) : [],
        tattrValues: sideValues.tattrValues ?? {},
        tattrErrors: {},
        // isValid: true,
      };
    }

    return { sides, valueSides };
  }, [documentRender]);

  return <Modal
    open={open}
    onClose={onClose}
    title={name}
    width="100%"
    maxWidth="1150px"
  >
    {documentRender ? (
      <div className={styles.root}>
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => documentRender && handleClickDownload(documentRender.name, sidesData, 'pdf', isChangedData)}
            disabled={!isFillingValid}
            isLoading={isRerenderDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_pdf')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => documentRender && handleClickDownload(documentRender.name, sidesData, 'docx', isChangedData)}
            disabled={!isFillingValid}
            isLoading={isRerenderDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_docx')}
          </LoadingButton>
        </ButtonsContainer>
        {sides && (
          <FillDocument
            className={styles.filling}
            name={documentRender?.name}
            sides={sides}
            onClickDownload={handleClickDownload}
            onClickRender={debug ? handleClickRender : undefined}
            isDownloadLoading={isDownloadLoading || isRerenderDownloading}
            valueSides={valueSides}
            setIsFillingValid={setIsFillingValid}
            setSidesDataForInnerRenderButton={setSidesData}
            onSetChanged={() => setChangedData(true)}
            showButtons={false}
            showHead={false}
            checkAllowSelect={handleCheckTrial}
          />
        )}
        <TrialEndModal
          open={isOpenTrialEnd}
          onClose={setOpenTrialEnd}
          title={t('trial_period_end')}
          description={t('trial_end_render_description')}
          image={<img src="/images/trial-end.svg" alt="" />}
        />
        <TrialEndModal
          open={isOpenTrialLock}
          onClose={setOpenTrialLock}
          title={t('sign_only_for_paid')}
          description={t('sign_only_for_paid_description')}
          image={<img src="/images/trial-lock.svg" alt="" />}
        />
      </div>
    ) : (
      <ModalChangeDocumentSkelet />
    )}
  </Modal>;
};

export default ModalChangeDocument;