import classnames from 'classnames';

import styles from './template-item.module.css';

const Placeholder = () => {
  return (
    <div className={classnames(styles.root, styles.loading, 'skeleton-loader')} />
  );
};

export default Placeholder;
