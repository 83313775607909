import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, nanoid } from '@forma/forma-ui-kit';
import VariationEdit from '../VariationEdit';

import { ITemplateSide, ITemplateVariation } from 'interfaces/templates.interface';

import styles from './variations-edit-modal.module.css';

interface VariationsEditModalProps {
  open: boolean,
  onClose: () => void,
  sideId: string,
  sides: ITemplateSide[],
  data?: ITemplateVariation,
  onSave: (sideId: string, data: ITemplateVariation) => void
}

const initialVariation = { id: '', title: '', multiple: false, parents: [], variants: [] };

const VariationsEditModal: FC<VariationsEditModalProps> = ({
  open, onClose, sideId, data: variation, onSave
}) => {
  const { t } = useTranslation();
  const [ data, setData ] = useState<ITemplateVariation>(variation ?? initialVariation);

  useEffect(() => {
    setData(variation ?? initialVariation);
  }, [variation]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      size="large"
      open={open}
      title={t('edit_variations_block')}
      onClose={handleClose}
      buttonsClassName={styles.modalButtons}
      buttons={[
        { children: t('cancel'), viewStyle: 'tertiary' },
        {
          children: t('save'),
          viewStyle: 'primary',
          disabled: !data.variants.length,
          onClick: () => onSave(sideId, { ...data, id: data.id ? data.id : nanoid(8) })
        }
      ]}
    >
      <div className={styles.modalSelect}>
        <VariationEdit {...data} onChange={setData} />
      </div>
    </Modal>
  );
};

export default VariationsEditModal;
