import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Tags, Button, RemoveModal, ThemeContext } from '@forma/forma-ui-kit';
import { files_icons, categories_colors } from 'data/mock';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IAttachmenItem } from 'interfaces/attachments.interface';
import { selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './document-info.module.css';

interface DocumentInfoProps {
  data: IAttachmenItem,
  onClickEdit: (id: string) => void,
  onClickAddSubitem: (id: string) => void,
  onClickCopyLink: (id: string) => void,
  onClickDownload: (ids: string[]) => void,
  isDownloadLoading: boolean,
  onRemove: (id: string) => void
}

const FeatureItem = ({ label, value, className }: { label: ReactNode, value: ReactNode, className?: string }) => {
  return (
    <div className={classNames(styles.featuresItem, className)}>
      <div className={styles.featureLabel}>{label}:</div>
      <div className={styles.featureValue}>{value}</div>
    </div>
  );
};

const DocumentInfo: FC<DocumentInfoProps> = ({
  data, onClickEdit, onClickAddSubitem, onClickCopyLink, onClickDownload, isDownloadLoading, onRemove,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userPermissions = useAppSelector(selectUserPermissions);
  const { viewport } = useContext(ThemeContext);
  const [ isOpenRemove, setOpenRemove ] = useState<boolean>(false);
  const { id, name, ext, description, size, categories, uploadedAt, dtFrom, dtTo, filename, inactive } = data;

  const kb = Number((size/1000).toFixed());
  const mb = Number((kb/1000).toFixed());

  const validDates = [ dtFrom && format(new Date(dtFrom), 'dd.MM.yyyy'), dtTo && format(new Date(dtTo), 'dd.MM.yyyy') ];

  useEffect(() => {
    if (userPermissions && !userPermissions.includes('attachments_view')) {
      dispatch(addNotification({ content: t('attachments.permissions_view_error'), type: 'ERROR' }));
      navigate('/templates');
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  return (
    <div className={styles.root}>
      {inactive && (
        <div className={styles.outOfDate}>
          {t('out_of_date')}
        </div>
      )}
      <div className={styles.heading}>
        <img src={files_icons[ext] ? files_icons[ext] : files_icons.other} className={styles.fileIcon} alt="" />
        <div className={styles.title}>{name}</div>
      </div>
      <div className={styles.controls}>
        {userPermissions?.includes('attachments_download') && (
          <Button
            viewStyle="textLight"
            icon={isDownloadLoading ? <ReactSVG src="/icons/loading.svg" wrapper="span" /> : <ReactSVG src="/icons/download.svg" wrapper="span" />}
            title={t('download')}
            onClick={() => onClickDownload([ id ])}
            // isLoading={isDownloadLoading}
          />
        )}
        <Button
          viewStyle="textLight"
          icon={<ReactSVG src="/icons/copy.svg" wrapper="span" />}
          title={t('copy_link')}
          onClick={() => onClickCopyLink(id)}
        />
        {userPermissions?.includes('attachments_add') && (
          <Button
            viewStyle="textLight"
            icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
            title={viewport !== 'phone' ? t('add_document') : undefined}
            onClick={() => onClickAddSubitem(id)}
          />
        )}
        <div style={{ flexGrow: 1 }} />
        {userPermissions?.includes('attachments_add') && (
          <Button
            viewStyle="textLight"
            icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
            title={t('edit')}
            onClick={() => onClickEdit(id)}
          />
        )}
        {userPermissions?.includes('attachments_delete') && (
          <Button
            viewStyle="textLight"
            icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
            title={t('delete')}
            onClick={() => setOpenRemove(true)}
          />
        )}
      </div>
      <div className={styles.features}>
        <FeatureItem
          label={t('extension')}
          value={ext}
        />
        <FeatureItem
          label={t('size')}
          value={mb > 0 ? `${mb} MB` : `${kb} KB`}
        />
        <FeatureItem
          label={t('creation_date')}
          value={uploadedAt && format(new Date(uploadedAt), 'dd.MM.yyyy')}
        />
        <FeatureItem
          className={styles.fullWidth}
          label={t('validity')}
          value={validDates.join(' - ')}
        />
        <FeatureItem
          className={classNames(styles.fullWidth, styles.description)}
          label={t('file_description')}
          value={description}
        />
        <FeatureItem
          className={styles.fullWidth}
          label={t('categories')}
          value={
            <Tags
              className={styles.categories}
              items={categories?.map(({ name, color }) => ({
                name: name,
                color: categories_colors[color]?.color,
                backgroundColor: categories_colors[color]?.background
              }))}
            />
          }
        />
      </div>
      <RemoveModal
        open={isOpenRemove}
        onClose={setOpenRemove}
        onRemove={() => onRemove(id)}
        title={t('deleting_file')}
        itemName={name ?? filename}
      />
    </div>
  );
};

export default DocumentInfo;
