import { FC, useEffect, useState, KeyboardEvent, ClipboardEvent } from 'react';
import { Trans } from 'react-i18next';
import { Input, LoadingIndicator } from '@forma/forma-ui-kit';

import styles from './code-input.module.css';

interface CodeInputProps {
  isLoading?: boolean,
  onComplete: (code: string) => void,
  isError?: boolean
}

const CODE_LENGTH = 6;

const CodeInput: FC<CodeInputProps> = ({ isLoading, onComplete, isError }) => {
  const [ code, setCode ] = useState<string[]>([...Array(CODE_LENGTH).keys()].map(() => ''));

  useEffect(() => {
    if (code.length === CODE_LENGTH && code.every(symbol => !!symbol)) onComplete(code.join(''));
    // eslint-disable-next-line
  }, [code]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    // if (!e.metaKey) e.preventDefault();
    if (!e.key.match(/[0-9]|Backspace/)) return;

    let symbol = e.key;
    if (e.key === 'Backspace') symbol = '';

    const hasOldSymbol = !!code[index];

    setCode(prev => {
      const next = [ ...prev ];
      next[index] = symbol;
      return next;
    });

    if (symbol) document.getElementById(`code_${index+1}`)?.focus();
    else if (!hasOldSymbol) document.getElementById(`code_${index-1}`)?.focus();
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const pastedNumbers = clipboardData.getData('Text').substring(0, 10).split('').filter(symbol => !!symbol.match(/[0-9]/));
    setCode(prev => [...Array(CODE_LENGTH).keys()].map((index) => pastedNumbers[index] ?? ''));
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingIndicator color="var(--primary-color)" size={54} />
      ) : (
        <div className={styles.inputs}>
          {[...Array(CODE_LENGTH/2).keys()].map(index => (
            <Input
              id={`code_${index}`}
              name={`code_${index}`}
              containerClass={styles.inputContainer}
              className={styles.input}
              onKeyDown={e => handleKeyDown(e, index)}
              onPaste={e => handlePaste(e)}
              value={code[index]}
              maxLength={1}
              error={isError}
              autoFocus={index === 0}
              inputMode="decimal"
              autoComplete="off"
              key={index}
            />
          ))}
          <div className={styles.divider} />
          {[...Array(CODE_LENGTH/2).keys()].map(index => (
            <Input
              id={`code_${index+CODE_LENGTH/2}`}
              name={`code_${index+CODE_LENGTH/2}`}
              containerClass={styles.inputContainer}
              className={styles.input}
              onKeyDown={e => handleKeyDown(e, index+CODE_LENGTH/2)}
              onPaste={e => handlePaste(e)}
              value={code[index+CODE_LENGTH/2]}
              maxLength={1}
              error={isError}
              inputMode="decimal"
              autoComplete="off"
              key={index+CODE_LENGTH/2}
            />
          ))}
        </div>
      )}
      <div className={styles.errorText}>
        {isError && <Trans i18nKey="errors.confirm_code_incorrect" />}
      </div>
    </div>
  );
};

export default CodeInput;
