import { IUserGroupsItem } from 'interfaces/users.interface';
import { baseApi } from '../store';

interface IAddGroupData {
  name: string,
  permissions: string[]
}

export const groupsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getGroupsList: builder.query<IUserGroupsItem[], void>({
      query: () => ({
        url: `/usergroups/`,
        method: 'GET'
      }),
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation<IUserGroupsItem, IAddGroupData>({
      query: data => ({
        url: `/usergroups/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroup: builder.mutation<IUserGroupsItem, IUserGroupsItem>({
      query: data => ({
        url: `/usergroups/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/usergroups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Groups', 'Users'],
    }),
  })
});

export const {
  useGetGroupsListQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation
} = groupsApi;
