import { FC, ReactElement, useContext } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, ThemeContext } from '@forma/forma-ui-kit';

import styles from './documents-item.module.css';

interface DocumentsItemProps {
  id: string,
  name: string,
  icon: string,
  createdAt: string,
  confirmed?: boolean,
  confirmedAt?: string,
  contextMenu: ReactElement,
  onClick: () => void,
}

const DocumentsItem: FC<DocumentsItemProps> = ({
  name, icon, createdAt, confirmed, confirmedAt, contextMenu, onClick
}) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);

  if (viewport === 'phone') return (
    <div className={styles.item}>
      <ReactSVG
        src={icon}
        className={styles.icon}
        onClick={onClick}
        wrapper="div"
      />
      <div className={styles.itemContent}>
        <div className={styles.info}>
          <div className={styles.name} onClick={onClick}>
            {name}
          </div>
          <div className={styles.controls}>
            {contextMenu}
          </div>
        </div>
        <div className={styles.date}>
          <div><span className={styles.dateLabel}>{t('created')}:</span> {format(new Date(createdAt), 'dd.MM.yyyy')}</div>
          {(confirmed && confirmedAt) && (
            <div><span className={styles.dateLabel}>{t('signed')}:</span> {format(new Date(confirmedAt), 'dd.MM.yyyy')}</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <TableRow className={styles.row}>
      <TableCell className={classNames(styles.cell, styles.icon)} onClick={onClick}>
        <ReactSVG src={icon} wrapper="div" />
      </TableCell>
      <TableCell className={classNames(styles.cell, styles.name)} onClick={onClick}>
        <div className={styles.nameOverflow}>
          {name}
        </div>
      </TableCell>
      <TableCell className={classNames(styles.cell, styles.date)} onClick={onClick}>
        {format(new Date(createdAt), 'dd.MM.yyyy')}
      </TableCell>
      <TableCell className={classNames(styles.cell, styles.date)} onClick={onClick}>
        {(confirmed && confirmedAt) && format(new Date(confirmedAt), 'dd.MM.yyyy')}
      </TableCell>
      <TableCell className={styles.cell}>
        {contextMenu}
      </TableCell>
    </TableRow>
  );
};

export default DocumentsItem;
