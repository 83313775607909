import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import SafeDocumentsList from 'views/SafeDocumentsList';
import webView from 'helpers/webview';
import DocumentModal from './DocumentModal';
import DocumentCreateModal from './DocumentCreateModal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  useGetAttachmentsTreeQuery,
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery,
  useRemoveAttachmentMutation,
} from 'store/attachments/attachmentsApi';
import { selectOnboarding, selectUserPermissions } from 'store/user/userSlice';
import { setOnboardingModal } from 'store/common/commonSlice';

const SafeDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const onboarding = useAppSelector(selectOnboarding);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ params, setParams ] = useState({ ...getFromTo(1, 1000) });
  const [ openId, setOpenId ] = useState<string|undefined>();
  const [ isShowAdd, setShowAdd ] = useState<boolean>(false);
  const [ addingParentId, setAddingParentId ] = useState<string|null>(null);
  const page = searchParams.get('page');

  const userPermissions = useSelector(selectUserPermissions);

  const { data: attachments, isFetching: isPageLoading, error: pageError }
    = useGetAttachmentsTreeQuery({ ...params, ...getFromTo(page, 1000) });
  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [ createCategory, { isLoading: isCreateCategoryLoading } ] = useCreateAttachmentCategoryMutation();

  const [ downloadAttachment, { isFetching: isDownloadLoading } ] = useLazyDownloadAttachmentQuery();
  const [ downloadAttachmentsArchive, { isFetching: isDownloadArchiveLoading } ] = useLazyDownloadAttachmentsArchiveQuery();
  const [ removeAttachment, { isLoading: isRemoveLoading }] = useRemoveAttachmentMutation();

  useEffect(() => {
    if (onboarding && !onboarding.safe_modal) dispatch(setOnboardingModal('safe_modal'));
    // eslint-disable-next-line
  }, [onboarding]);

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (userPermissions) {
      const canViewAttachments = userPermissions.includes('attachments_view');
      if (!canViewAttachments) {
        navigate('/');
      }
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  useEffect(() => {
    setOpenId(id);
  }, [id]);

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, name: value }));
    handleChangePage(1);
  };

  const handleChangeFilter = (params: { [key: string]: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };

  const handleDowloadFiles = (ids: string[], fileName?: string|null) => {
    if (ids.length === 1) {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownload(ids[0], fileName ?? 'Document');
      } else {
        downloadAttachment(ids[0]).unwrap().then((url: string) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName ?? 'Document';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {});
      }
    } else {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownloadArchive(ids, 'Documents.zip');
      } else {
        downloadAttachmentsArchive({ ids: ids }).unwrap().then((url: string) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {});
      }
    }
  };

  const handleRemoveFiles = (ids: string[]) => {
    ids.forEach(id => {
      removeAttachment(id);
    });
  };

  const handleCloseModal = () => {
    if (id) navigate('/safe');
    else setOpenId(undefined);
  };

  return (
    <AdminLayout
      title={t('safe')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('safe')}</PageTitle>
      <SafeDocumentsList
        active={id}
        items={attachments?.attachments}
        onChangeSearch={handleChangeSearch}
        onChangeFilter={handleChangeFilter}
        onDownload={handleDowloadFiles}
        isDownloadLoading={isDownloadLoading || isDownloadArchiveLoading}
        onClickItem={setOpenId}
        onRemove={handleRemoveFiles}
        isRemoveLoading={isRemoveLoading}
        onClickAdd={() => setShowAdd(true)}
        categories={{
          items: categories,
          onCreate: createCategory,
          isLoading: isCreateCategoryLoading
        }}
        pagination={{
          isLoading: isPageLoading,
          onChange: handleChangePage,
          count: attachments?.count ?? 0,
          page: page,
          size: 1000
        }}
      />
      <DocumentModal
        id={openId}
        open={!!openId}
        onClose={handleCloseModal}
        onClickAdd={id => {
          setShowAdd(true);
          setAddingParentId(id);
        }}
      />
      <DocumentCreateModal
        open={!!isShowAdd}
        onClose={() => {
          setShowAdd(false);
          setAddingParentId(null);
        }}
        parentId={addingParentId}
      />
    </AdminLayout>
  );
};

export default SafeDocuments;