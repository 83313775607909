import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { nanoid } from '@forma/forma-ui-kit';
import DistributeSidesList from './DistributeSidesList';
import DistributeSidesGroups from './DistributeSidesGroups';
import { SortSide } from './utils';

import { ITemplatesListSides } from 'interfaces/templates.interface';

import styles from './distribute-sides.module.css';

interface DragItemData {
  fromSide?: number,
  templateId: string,
  templateName: string,
  name: string,
  sideIndex: number,
}

interface DistributeSidesProps {
  isLoading?: boolean,
  templatesSides?: ITemplatesListSides,
  sortedSides: SortSide[],
  onSortSides: (sides: SortSide[]) => void
}

const DistributeSides: FC<DistributeSidesProps> = ({
  isLoading, templatesSides, sortedSides, onSortSides
}) => {
  const { t } = useTranslation();

  const unsortedSides = templatesSides && Object.values(templatesSides).flatMap((template) => (
    template.sides
      .map((_, index) => ({ templateId: template.id, sideIndex: index }))
      .filter((templateSide, index) => !sortedSides.find(({ sideIndices }) => (
        sideIndices.find(({ templateId, sideIndex }) => templateId === template.id && sideIndex === index)
      )))
  ));

  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: { distance: 3 }
  }));

  const handleDropToSide = ({ active, over }: DragEndEvent) => {
    const side = active.data.current as DragItemData;
    if (!active?.data.current || !over?.data.current || active.data.current.fromSide === over.data.current.index) return;

    const dropSideIndex = over?.data.current?.index;

    const next = [ ...sortedSides ];

    const searchIndex = next[dropSideIndex].sideIndices.findIndex(({ templateId, sideIndex }) => (
      templateId === side.templateId && sideIndex === side.sideIndex
    ));

    if (searchIndex === -1) next[dropSideIndex].sideIndices.push({
      templateId: side.templateId,
      sideIndex: side.sideIndex
    });

    if (side.fromSide || side.fromSide === 0) {
      const searchIndex = next[side.fromSide].sideIndices.findIndex(({ templateId, sideIndex }) => (
        templateId === side.templateId && sideIndex === side.sideIndex
      ));

      if (searchIndex !== -1) next[side.fromSide].sideIndices.splice(searchIndex, 1);
    }
    onSortSides(next);
  };

  const handleAddSide = () => {
    onSortSides([ ...sortedSides, { id: nanoid(8), name: `${t('side')} ${sortedSides.length+1}`, sideIndices: [] }]);
  };

  const handleRemoveSide = (index: number) => {
    const next = [ ...sortedSides ];
    next.splice(index, 1);
    onSortSides(next);
  };

  const handleRenameSide = (index: number, name: string) => {
    const next = [ ...sortedSides ];
    next[index] = { ...next[index] };
    next[index].name = name;
    onSortSides(next);
  };

  return (
    <div className={styles.root}>
      <DndContext
        onDragEnd={handleDropToSide}
        sensors={sensors}
      >
        <div className={styles.column}>
          <DistributeSidesList
            isLoading={isLoading}
            sides={unsortedSides}
            templatesSides={templatesSides}
          />
        </div>
        <div className={styles.column}>
          <DistributeSidesGroups
            isLoading={isLoading}
            sides={sortedSides}
            templatesSides={templatesSides}
            onAddSide={handleAddSide}
            onRemoveSide={handleRemoveSide}
            onRenameSide={handleRenameSide}
          />
        </div>
      </DndContext>
    </div>
  );
};

export default DistributeSides;
