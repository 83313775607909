import { FC, ReactNode, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonsContainer, SearchInput, Tags, ThemeContext } from '@forma/forma-ui-kit';
import ViewSwitch from 'views/FileExplorer/ExplorerHead/ViewSwitch';
import CreateTemplateModal from 'views/FileExplorer/CreateTemplateModal';

import { useAppDispatch } from 'store/hooks';
import { setOnboardingModal } from 'store/common/commonSlice';
import { ISharedFoldersTreeItem } from 'interfaces/sharedTemplates.interface';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';

import styles from './static-templates-header.module.css';

interface StaticTemplatesHeaderProps {
  isLoading?: boolean,
  name?: ReactNode,
  categories?: ISharedFoldersTreeItem[],
  selectedCategory?: string|null,
  onSelectCategory: (id: string|null) => void,
  onChangeSearch: (value: string) => void,
  onCreateTemplate: IFoldersEditingProps['onCreate'],
  showFilters?: boolean
}

const StaticTemplatesHeader: FC<StaticTemplatesHeaderProps> = ({
  isLoading, name, categories, selectedCategory, onSelectCategory, onChangeSearch, showFilters, onCreateTemplate
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewport } = useContext(ThemeContext);

  const tags = categories && categories?.map(({ id, name }) => ({
    name,
    onClick: () => onSelectCategory(id),
    className: classNames(styles.tag, selectedCategory === id && styles.selected)
  }));

  return (
    <div className={styles.root}>
      {!['phone', 'tabletS'].includes(viewport) && (
        <ButtonsContainer className={styles.headButtons}>
          {onCreateTemplate && <CreateTemplateModal
            defaultMode="create"
            onCreate={onCreateTemplate}
            control={
              <Button
                viewStyle="textPrimary"
                className={styles.headButton}
                icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
              >
                {t('create_in_editor')}
              </Button>
            }
          />}
          {onCreateTemplate && <CreateTemplateModal
            defaultMode="upload"
            onCreate={onCreateTemplate}
            control={
              <Button
                viewStyle="textPrimary"
                className={styles.headButton}
                icon={<ReactSVG src="/icons/upload-file.svg" wrapper="span" />}
              >
                {t('upload_doc')}
              </Button>
            }
          />}
          <Button
            viewStyle="textPrimary"
            className={classNames(styles.headButton, styles.faqButton)}
            onClick={() => dispatch(setOnboardingModal('special_templates_modal'))}
          >
            {t('howto_use_forma_templates')}
          </Button>
        </ButtonsContainer>
      )}
      <SearchInput
        onSearch={onChangeSearch}
        placeholder={t('as_example_employment_contract')}
      />
      {isLoading ? (
        <h2 className={classNames(styles.title, 'skeleton-loader')}>&nbsp;</h2>
      ) : (
        <h2 className={styles.title}>{name}</h2>
      )}
      {showFilters && (
        <div className={styles.filters}>
          <div className={styles.categories}>
            {isLoading ? (
              <Tags
                items={[...Array(3)].map(() => ({
                  name: '',
                  className: classNames(styles.tag, 'skeleton-loader')
                }))}
              />
            ) : (
              !!tags?.length && (
                <Tags
                  items={[
                    {
                      name: t('all'),
                      onClick: () => onSelectCategory(null),
                      className: classNames(styles.tag, !selectedCategory && styles.selected)
                    },
                    ...tags
                  ]}
                />
              )
            )}
          </div>
          <ViewSwitch />
        </div>
      )}
    </div>
  );
};

export default StaticTemplatesHeader;
