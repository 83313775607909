import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface CounteragentsState {
  saveTable: {
    header: {
      tattrsNumber: string,
      name: string,
      type: string
    }[],
    data: string[][],
    type: string,
    lengthContragents: number
  }
}

const initialState: CounteragentsState = {
  saveTable: {
    header: [],
    data: [],
    type: '',
    lengthContragents: 0
  }
};

export const counteragentsSlice = createSlice({
  initialState,
  name: 'counteragents',
  reducers: {
    setSaveTable: (state, action) => {
      state.saveTable = action.payload;
    },
    delColumnTable: (state, action) => {
      state.saveTable.header.splice(action.payload, 1);
      state.saveTable.data = state.saveTable.data.map((row) => {
        row.splice(action.payload, 1);
        return row;
      });
    },
    saveTableHeader: (state, action) => {
      if (action.payload.index || action.payload.index === 0) {
        state.saveTable.header[action.payload.index].tattrsNumber = action.payload.tattrsNumber;
        state.saveTable.header[action.payload.index].name = action.payload.name;
        state.saveTable.header[action.payload.index].type = action.payload.type;
      }
    }
  }
});

export default counteragentsSlice.reducer;

export const { setSaveTable, delColumnTable, saveTableHeader } = counteragentsSlice.actions;

export const selectSaveTable = (state: RootState) => state.counteragents.saveTable;
