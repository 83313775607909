import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ContragentEdit from 'views/contragents/ContragentEdit';

import { addNotification } from 'store/notifications/notificationsSlice';
import { selectUserPermissions } from 'store/user/userSlice';
import {
  useGetCounteragentByIdQuery,
  useUpdateCounteragentMutation
} from 'store/counteragents/counteragentsApi';
import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery,
  useGetUserVariablesQuery
} from 'store/schemas/schemasApi';

import { contragent_types } from 'data/mock';

const Contragent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);

  const { data: counteragent, isLoading: isContragentLoading } = useGetCounteragentByIdQuery(id ?? '', { skip: !id });
  const { data: variablesSections, isLoading: isSectionsLoading } = useGetTemplateSectionsGroupsQuery();
  const { data: availableVariables, isLoading: isVarsLoading } = useGetTemplateSectionsVariablesQuery();
  const { data: userVariables, isLoading: isUserVarsLoading } = useGetUserVariablesQuery();

  const [ updateCounteragent, { isLoading: isSaveLoading } ] = useUpdateCounteragentMutation();

  const handleSave = async (values: { [key: string]: string }) => {
    if (!id || !counteragent) return null;

    const result = await updateCounteragent({
      ...counteragent,
      // selfowned: true,
      attrValues: values
    });
    if ('data' in result && result.data) {
      navigate(`/contragents?type=${counteragent.type}`);
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  let title = '';
  if (id && counteragent) title = t(`${counteragent.type}_person`);

  return (
    <AdminLayout
      title={title}
      breadcrumbs={{ items: [ { name: t('directory_contragents'), to: '/contragents', as: Link }, { name: title } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + title}</PageTitle>
      <ContragentEdit
        isLoading={isContragentLoading || isSectionsLoading || isVarsLoading || isUserVarsLoading}
        type={counteragent && contragent_types[counteragent.type]}
        counteragent={counteragent}
        variablesGroups={variablesSections}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onSave={handleSave}
        isSaveLoading={isSaveLoading}
        canDeleteVariables={userPermissions?.includes('users')}
      />
    </AdminLayout>
  );
};

export default Contragent;