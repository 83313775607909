import { ITemplatesListSides, ITemplatesPack } from 'interfaces/templates.interface';

export interface SortSideItem {
  templateId: string,
  sideIndex: number
}

export interface SortSide {
  id: string,
  name: string,
  sideIndices: SortSideItem[]
}

export const parsePackSides = (templatesSides: ITemplatesListSides, pack: ITemplatesPack): SortSide[] => {
  const sides: SortSide[] = pack.sides.map(({ id, name }) => ({ id, name, sideIndices: [] }));
  pack.templates.forEach(({ id, sideIndices }) => {
    // сторона в документе - сторона в пакете
    sideIndices.forEach((packSideNumber, sideIndex) => {
      if (templatesSides && templatesSides[id] && templatesSides[id].sides[sideIndex]) {
        sides[packSideNumber-1].sideIndices.push({ templateId: id, sideIndex });
      }
    });
  });
  return sides;
};

export const parseTemplatesSides = (templatesSides: ITemplatesListSides, pack?: ITemplatesPack): SortSideItem[] => {
  if (!Object.keys(templatesSides).length) return [];
  return Object.values(templatesSides).reduce((acc: SortSideItem[], { id, sides }) => ([
    ...acc,
    ...sides.reduce((acc: SortSideItem[], current, index) => (
      pack?.templates.findIndex(template => (template.id === id && template.sideIndices.includes(index))) === -1 ?
        acc : [ ...acc, { templateId: id, sideIndex: index } ]
    ), [])
  ]), []);
};

export const parseTemplateSidesEditTemplates = (templatesSides: ITemplatesListSides, pack?: ITemplatesPack): SortSideItem[] => {
  if (!Object.keys(templatesSides).length) return [];
  return Object.values(templatesSides).reduce((acc: SortSideItem[], current) => {
    const templateExist = pack?.templates.some((elemPack) => elemPack.id === current.id);
    if (!templateExist) {
      return [
        ...acc,
        ...current.sides.map((_, indexSide) => ({ templateId: current.id, sideIndex: indexSide }))
      ];
    }
    return acc;
  }, []);
};
