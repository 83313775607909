interface ICookieOptions {
  expires?: Date|number|string,
  path?: string,
  domain?: string,
  secure?: boolean
}

export const getCookie = (name: string) => {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
};

export const setCookie = (name: string, value: string|number, options: ICookieOptions = {}) => {
  options = { path: '/', ...options };

  if (options.expires instanceof Date) options.expires = options.expires.toUTCString();

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  updatedCookie += Object.entries(options).reduce((acc, [ key, value ]) => key === 'secure' ? `${acc}; ${key}` : `${acc}; ${key}=${value}`, '');

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', { expires: new Date('2000-01-01T00:00:00.000Z') });
};
