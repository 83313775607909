import { FC, useContext, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ContextMenu, RemoveModal, ThemeContext } from '@forma/forma-ui-kit';
import ExternalFillModal from 'pages/MyDocuments/ExternalFillModal';
import SendExternalFillModal from 'views/ExternalFill/SendExternalFillModal';
import RenameModal from 'components/RenameModal';
import webView from 'helpers/webview';
import copyToClipboard from 'helpers/copyToClipboard';
import DocumentsItem from './DocumentsItem';

import { useAppDispatch } from 'store/hooks';
import { ITemplateRenderData, useRenderTemplateMutation } from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { IDocumentExternal } from 'interfaces/documents.interface';

import styles from './documents-item.module.css';

interface DocumentsItemExternalProps {
  data: IDocumentExternal,
  openedId?: string|null,
  sendingId?: string|null,
  onCopyLink: (key: string, type: 'filling') => void,
  onRemove: (id: string) => void,
  onSend: (id: string, data: { login: string }) => void,
  onRename: (id: string, name: string) => void,
  onDuplicate: (id: string) => void,
}

// TODO: объединить контролы в один компонент
const landingUrl = process.env.REACT_APP_LANDING_URL;

const DocumentsItemExternal: FC<DocumentsItemExternalProps> = ({
  data, onCopyLink, onDuplicate, onRemove, onSend, onRename, openedId, sendingId
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { lang } = useContext(ThemeContext);
  const { id, name, createdAt, filledAt, isReady, urlKey, templateId, sideValues } = data;
  const [ isShowEditModal, setShowEditModal ] = useState<boolean>(openedId === id);
  const [ isShowRename, setShowRename ] = useState<boolean>(false);
  const [ isShowRemove, setShowRemove ] = useState<boolean>(false);
  const [ isShowSend, setShowSend ] = useState<boolean>(sendingId === id);

  const [ renderTemplate ] = useRenderTemplateMutation();

  const handleRenderTemplate = (data: { id: string, name: string, sideValues: ITemplateRenderData['sideValues'] }) => {
    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(data.id, `${name}.pdf`, { name: data.name, sideValues: data.sideValues });
    } else {
      renderTemplate(data).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(e => {});
    }
  };

  const handleCopyLink = () => {
    const res = copyToClipboard(`${landingUrl}/${lang}/filling/${urlKey}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
    window?.ReactNativeWebView?.postMessage(`openShare ${landingUrl}/${lang}/filling/${urlKey}`);
  };


  return (
    <DocumentsItem
      id={id}
      name={name}
      icon={`/icons/documents/${filledAt ? 'filled' : 'to-fill'}.svg`}
      createdAt={createdAt}
      contextMenu={<>
        <ContextMenu
          offsetY={8}
          control={open => (
            <button className={classNames(styles.optionsButton, open && styles.active)}>
              <ReactSVG src="/icons/more.svg" />
            </button>
          )}
          items={[
            {
              text: t('download'),
              disabled: !isReady,
              onClick: () => handleRenderTemplate({ id: templateId, name, sideValues })
            }, {
              text: t('copy_link'),
              onClick: () => onCopyLink(urlKey, 'filling'),
              disabled: !urlKey || !!isReady
            }, {
              text: t('send_to_email'),
              onClick: () => setShowSend(true),
              disabled: !urlKey || !!isReady
            }, {
              text: t('duplicate'),
              onClick: () => onDuplicate(id),
              disabled: !!isReady
            }, {
              text: t('rename'),
              disabled: true,
              onClick: () => setShowRename(true)
            }, {
              text: t('delete'),
              onClick: () => setShowRemove(true)
            }
          ]}
        />
        <RemoveModal
          open={isShowRemove}
          onClose={() => setShowRemove(false)}
          onRemove={() => onRemove(id)}
          itemName={name}
          title={t('deleting_document')}
        />
        <RenameModal
          open={isShowRename}
          onClose={() => setShowRename(false)}
          onSubmit={(name) => onRename(id, name)}
          name={name}
          title={t('rename_external_fill')}
          placeholder={t('enter_name')}
        />
        <SendExternalFillModal
          open={isShowSend}
          onClose={() => setShowSend(false)}
          onSubmit={data => onSend(id, data)}
          onClickCopyLink={handleCopyLink}
        />
        <ExternalFillModal
          open={isShowEditModal}
          onClose={() => setShowEditModal(false)}
          id={data.id}
          name={data.name}
        />
      </>}
      onClick={() => setShowEditModal(true)}
    />
  );
};

export default DocumentsItemExternal;
