import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TemplatesPackEdit from 'views/templatesPacks/TemplatesPackEdit';

import {
  useGetFolderContentQuery,
  useGetFavouritesQuery,
  useSearchFoldersQuery,
  useGetFoldersTreeQuery
} from 'store/folders/foldersApi';
import { useGetPackQuery } from 'store/packs/packsApi';
import { selectLayout } from 'store/common/commonSlice';
import { ITemplatesPackTemplate } from 'interfaces/templates.interface';
import { IFolderListChidlren, IFolderPathItem } from 'interfaces/folders.interface';

const PackTemplates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const layout = useSelector(selectLayout);

  const [ templateName, setTemplateName ] = useState('');

  const [ openedFolder, setOpenedFolder ] = useState<string|null>(null);
  const [ subFolder, setSubFolder ] = useState<string|null>(null);
  const [ searchQuery, setSeachQuery ] = useState<string>('');
  const [ templates, setTemplates ] = useState<ITemplatesPackTemplate[]>([]);
  const [ isFavouritesOpen, setFavouritesOpen ] = useState<boolean>(false);

  const { data: pack, isLoading: isPackLoading, error: pageError } = useGetPackQuery(id ?? '', { skip: !id });
  const { data: folders, isFetching: isFoldersLoading } = useGetFolderContentQuery(openedFolder, { skip: layout === 'list' });
  const { data: foldersTree, isLoading: isFoldersTreeLoading } = useGetFoldersTreeQuery(undefined, { skip: layout !== 'list' });
  const { data: favourites, isFetching: isFavouritesLoading } = useGetFavouritesQuery(undefined, { skip: !isFavouritesOpen });
  const { data: subfolders, isFetching: isLoadingSubfolder } = useGetFolderContentQuery(subFolder ?? '', { skip: !subFolder });
  const { data: searchResults, isFetching: isLoadingSearch } = useSearchFoldersQuery({ name: searchQuery, limit: 10 }, { skip: !searchQuery || searchQuery === '' });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (pack) {
      if (pack.templates) setTemplates(pack.templates);
      if (pack.name) setTemplateName(pack.name);
    }
    // eslint-disable-next-line
  }, [pack]);

  const handleShowContent = (folder: IFolderListChidlren) => {
    setSubFolder(folder.id !== subFolder ? folder.id : null);
  };

  const handleBackFolder = (folder?: IFolderPathItem) => {
    if (folder) setOpenedFolder(folder.id !== openedFolder ? folder.id : null);
    else setOpenedFolder(null);

    setFavouritesOpen(false);
  };

  const handleOpenFolder = (folder?: IFolderListChidlren) => {
    if (folder) setOpenedFolder(folder.id !== openedFolder ? folder.id : null);
    else setOpenedFolder(null);

    setFavouritesOpen(false);
  };

  const handleToggleTemplate = (template: IFolderListChidlren, parent?: { id: string, name: string, translatedName: string }|null) => {
    if (templates.findIndex(item => item.id === template.id) !== -1) handleRemoveTemplate(template.id);
    else handleAddTemplate({
      id: template.id,
      sideIndices: [],
      name: template.name,
      path: parent ? [{ id: parent.id, name: parent.name, translatedName: parent.translatedName }] : undefined
    });
  };

  const handleAddTemplate = (template: ITemplatesPackTemplate) => {
    setTemplates(prev => [ ...prev, template ]);
  };
  const handleRemoveTemplate = (id: string) => {
    setTemplates(prev => {
      const index = prev.findIndex(item => item.id === id);

      if (index !== -1) {
        const next = [ ...prev ];
        next.splice(index, 1);
        return next;
      }

      return prev;
    });
  };

  const handleSavePack = async () => {
    if (id) navigate(`/templates-pack-sides/${id}`, { state: { templateName, templates } });
    else navigate('/templates-pack-sides', { state: { templateName, templates } });
  };

  const handleClickFavourites = () => {
    setFavouritesOpen(!isFavouritesOpen);
  };

  // eslint-disable-next-line
  const breadcrumbs = useMemo(() => ([{ name: t('templates_packs'), to: '/templates-packs', as: Link }]), []);
  const onBreadcrumbsChange = useCallback((value: string) => setTemplateName(value), []);

  return (
    <AdminLayout
      title={id ? t('edit_templates_pack') : t('new_templates_packs')}
      breadcrumbs={{
        items: breadcrumbs,
        value: templateName,
        placeholder: isPackLoading ? '' : t('set_templates_pack_name'),
        onChange: onBreadcrumbsChange
      }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('templates_packs')}</PageTitle>

      <TemplatesPackEdit
        isPackLoading={isPackLoading}
        pack={pack}
        packItems={templates}
        onAddTemplate={handleAddTemplate}
        onRemoveTemplate={handleRemoveTemplate}
        onSave={handleSavePack}

        foldersTree={isFavouritesOpen ? favourites?.items : foldersTree}
        folder={isFavouritesOpen ? favourites : folders}
        items={isFavouritesOpen ? favourites?.items : folders?.items}
        active={subFolder}
        selected={templates.map(({ id }) => id)}
        isLoading={isFoldersLoading || isFoldersTreeLoading || isFavouritesLoading}

        subitems={subfolders?.items}
        subitemsIsLoading={isLoadingSubfolder}

        search={{
          results: (searchQuery && searchQuery !== '') ? searchResults : undefined,
          isLoading: isLoadingSearch,
          onChange: setSeachQuery,
        }}

        favourites={{
          onClick: handleClickFavourites,
          isOpen: isFavouritesOpen
        }}

        navigation={{
          onClickBack: handleBackFolder,
          onShowFolderContent: handleShowContent,
          onOpenTemplate: handleToggleTemplate,
          onOpenFolder: handleOpenFolder
        }}
      />
    </AdminLayout>
  );
};

export default PackTemplates;