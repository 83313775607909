import { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useConfirmRegisterMutation } from 'store/auth/authApi';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ searchParams ] = useSearchParams();
  const key = searchParams.get('key');
  const [ confirmUser, { isLoading, isSuccess, isError } ] = useConfirmRegisterMutation();

  useEffect(() => {
    if (!id || !key) navigate('/404');
    else confirmUser({ id, key });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, key]);

  useEffect(() => {
    if (isSuccess) navigate('/approved');
    if (isError) navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <div />;
};

export default ConfirmEmail;
