export const analytics = {
  createAccount: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: process.env.REACT_APP_ID_VK, goal: 'Start_a_free_periopd' });
  },
  goToAccountRegister: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: process.env.REACT_APP_ID_VK, goal: 'Go_to_the_account' });
  },
  checkVideoGide: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: process.env.REACT_APP_ID_VK, goal: 'whatch_video_guide' });
  },
  useDiscount: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: process.env.REACT_APP_ID_VK, goal: 'use_buttom_discount' });
  },
  dowloadDocument: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: process.env.REACT_APP_ID_VK, goal: 'download_document' });
  }
};