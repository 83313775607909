import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Checkbox, FillFolder, phonemasks } from '@forma/forma-ui-kit';
import { selectUserData } from 'store/user/userSlice';
import { useGetBillingInfoQuery } from 'store/billing/billingApi';
import formatPhoneNumber from 'helpers/formatPhoneNumber';
import { IPaymentSubscription } from 'interfaces/billing.interface';
import { IPhoneMask } from 'interfaces/common.interface';
import PaymentFields from './PaymentFields';

import { paymentMethods as items } from 'data/mock';

import styles from './payment-methods.module.css';

interface PaymentMethodsProps {
  selected: string,
  onSelect: (id: string) => void,
  onFill: (data: { [key: string]: string }|null) => void,
  subscription?: IPaymentSubscription
}

const ORG_OGRN_LENGTH = 13;

const PaymentMethods: FC<PaymentMethodsProps> = ({ selected, onSelect, onFill, subscription }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUserData);
  const { data: savedData } = useGetBillingInfoQuery(null);

  const defaultMaskCode = (i18n.resolvedLanguage && i18n.resolvedLanguage !== 'en') ? i18n.resolvedLanguage.toUpperCase() : 'US';
  const phoneMask = phonemasks.find(({ iso }: IPhoneMask) => iso === defaultMaskCode);

  const [ paymentData, setPaymentData ] = useState<{ [key: string]: string }>({});

  const { register, control, setValue, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
  });

  const handleSubmitFilling = (data: { [key: string]: string }|null) => {
    if (data && data.ogrn?.length !== ORG_OGRN_LENGTH) delete(data.kpp);
    onFill(data);
  };

  useEffect(() => {
    const subscription = watch(values => {
      setPaymentData(prev => ({ ...prev, ...values }));
    });
    return () => subscription.unsubscribe();
    //eslint-disable-next-line
  }, [ watch ]);


  useEffect(() => {
    if (isValid && paymentData.ogrn) handleSubmitFilling(paymentData);
    else handleSubmitFilling(null);
    //eslint-disable-next-line
  }, [paymentData.companyname, paymentData.ogrn, isValid]);

  useEffect(() => {
    if (user?.phone && phoneMask) setValue('phone', formatPhoneNumber(String(user.phone), phoneMask.code+phoneMask.mask), { shouldValidate: true });
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (savedData) Object.entries(savedData).forEach(([ key, value ]) => {
      if (key === 'phone' && phoneMask) setValue('phone', formatPhoneNumber(value, phoneMask.code+phoneMask.mask), { shouldValidate: true });
      else setValue(key, value, { shouldValidate: true });
    });
    // eslint-disable-next-line
  }, [savedData]);

  return (
    <div className={styles.root}>
      {items.map(({ id, icons, tattrsBase, tattrsFull }) => (
        <div className={styles.item} key={id}>
          <Checkbox
            id={id}
            name={id}
            containerClass={classNames(styles.checkboxContainer, selected === id && styles.selected)}
            labelClass={styles.label}
            iconClass={styles.checkbox}
            onChange={() => onSelect(id)}
            checked={selected === id}
            disabled={id === 'transfer' && !!subscription?.id}
            label={
              <>
                <div className={styles.text}>
                  <div className={styles.name}>{t(`payments.${id}.title`)}</div>
                  <div className={styles.description}>{t(`payments.${id}.description`, { email: user?.login })}</div>
                </div>
                {icons?.length ? (
                  <div className={styles.icons}>
                    {icons.map(icon => (
                      <img className={styles.icon} src={icon} width="33px" height="32px" alt="" key={icon} />
                    ))}
                  </div>
                ) : null}
              </>
            }
          />
          {(selected === id && tattrsBase && tattrsFull) && (
            <div className={styles.fields}>
              <div className={styles.fieldsTitle}>
                {t('subscription.input_data_for_billing')}
              </div>

              <FillFolder
                tattrs={[ ...tattrsBase, ...tattrsFull.map(item => ({ ...item, hidden: true, required: !(item.id === 'kpp' && paymentData.ogrn?.length === 15) })) ]}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
              />

              <PaymentFields
                data={paymentData}
                tattrs={tattrsFull}
                onSubmit={data => {
                  Object.keys(data).forEach(key => setValue(key, data[key]));
                  handleSubmitFilling(data);
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PaymentMethods;
