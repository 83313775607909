import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TemplatesPacks from 'views/templatesPacks/TemplatesPacks';

import {
  useGetPacksListQuery,
  useGetPackQuery,
  useRemovePackMutation,
  useUpdatePackMutation
} from 'store/packs/packsApi';
import { ITemplatesPack } from 'interfaces/templates.interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectOnboarding } from 'store/user/userSlice';
import { setOnboardingModal } from 'store/common/commonSlice';

const Packs = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectOnboarding);
  const navigate = useNavigate();
  const [ searchQuery, setSeachQuery ] = useState('');
  const [ openedPack, setOpenedPack ] = useState<string|null>(null);

  const { data: packs, isLoading: isPacksLoading } = useGetPacksListQuery();

  const { data: activePack, isFetching: isLoadingTemplates } = useGetPackQuery(openedPack ?? '', { skip: !openedPack });
  const { data: searchResults, isFetching: isLoadingSearch } =
    useGetPacksListQuery({ name: searchQuery, limit: 10 }, { skip: !searchQuery });
  const [ removePack ] = useRemovePackMutation();
  const [ updatePack ] = useUpdatePackMutation();

  useEffect(() => {
    if (onboarding && !onboarding.pack_list_tooltip && packs) dispatch(setOnboardingModal('pack_list_tooltip'));
    // eslint-disable-next-line
  }, [onboarding, packs]);

  const handleShowContent = (id: string) => {
    setOpenedPack(id !== openedPack ? id : null);
  };

  const handleOpenPack = (id: string) => {
    navigate(`/fill-templates-pack/${id}`);
  };

  const handleRemovePack = async (id: string) => {
    await removePack(id);
  };

  const handleRenamePack = async (data: ITemplatesPack) => {
    if (!data) return null;
    await updatePack(data);
  };

  return (
    <AdminLayout
      title={t('templates_packs')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('templates_packs')}</PageTitle>
      <TemplatesPacks
        items={packs}
        active={openedPack}
        isLoading={isPacksLoading}
        search={{
          isLoading: isLoadingSearch,
          results: !!searchQuery ? searchResults : undefined,
          onChange: setSeachQuery
        }}
        navigation={{
          onShowContent: handleShowContent,
          onOpenPack: handleOpenPack
        }}
        actions={{
          onRemove: handleRemovePack,
          onRename: handleRenamePack
        }}
        templates={activePack ? activePack.templates : undefined}
        templatesIsLoading={isLoadingTemplates}
      />
    </AdminLayout>
  );
};

export default Packs;