import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  MouseSensor
} from '@dnd-kit/core';
import { useAppSelector } from 'store/hooks';
import { Modal } from '@forma/forma-ui-kit';
import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersList } from 'interfaces/folders.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import {
  IFoldersActionsProps,
  IFoldersDropParams,
  IFoldersEditingProps,
  IFoldersFavouritesProps,
  IFoldersNavigationProps,
  IFoldersOptionsStateParams,
  IFoldersSearchProps
} from 'interfaces/fileExplorer.interface';
import { selectLayout } from 'store/common/commonSlice';

import ExplorerHead from './ExplorerHead';
import ExplorerGrid from './ExplorerGrid';
import ExplorerList from './ExplorerList';

import styles from './file-explorer.module.css';

const optionsInitialState = {
  itemId: null,
  copyToItemId: null,
  moveToItemId: null
};

interface FileExplorerProps {
  foldersTree?: IFolderListTreeChidlren[],
  folder?: IFoldersList,
  items: IFolderListChidlren[],
  active?: string|null,
  selected?: string[],
  updated?: string|null,
  isLoading: boolean,
  subitems?: IFolderListChidlren[],
  subitemsIsLoading: boolean,
  search: IFoldersSearchProps,
  navigation: IFoldersNavigationProps,
  editing?: IFoldersEditingProps,
  actions?: IFoldersActionsProps,
  favourites: IFoldersFavouritesProps,
  userGroups?: IUserGroupsItem[],
  hideOptions?: boolean
}

const FileExplorer: FC<FileExplorerProps> = ({
  foldersTree, folder, items, active, updated, selected, isLoading, search,
  subitems, subitemsIsLoading, navigation, editing, actions, favourites, userGroups, hideOptions
}) => {
  const { t } = useTranslation();
  const [ moveParams, setMoveParams ] = useState<IFoldersDropParams|null>(null);
  const [ optionsState, setOptionsState ] = useState<IFoldersOptionsStateParams>(optionsInitialState);
  const [ isOpenModal, setOpenModal ] = useState<boolean>(false);
  const layout = useAppSelector(selectLayout);

  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: { distance: 3 }
  }));

  const handleToggleMenu = (id: string|null) => {
    setOptionsState(prev => ({ ...prev, itemId: id }));
  };

  const handleToggleModal = (open: boolean) => {
    setOpenModal(open);
  };

  const handleHoverSubmenuItem = (id: string|null) => {
    setOptionsState(prev => ({ ...prev, moveToItemId: id }));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!editing?.onCopy || !editing?.onMove) return;
    if (!active?.data.current || !over?.data.current || over.data.current.type !== 'folder') return;
    if (active.data.current.id === over.data.current.id || active.data.current.folderId === over.data.current.id) return;

    setMoveParams({
      source: active.data.current.id as string,
      target: over.data.current.id as string,
      name: active.data.current?.name,
      type: active.data.current?.type
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <ExplorerHead
          selected={selected}
          items={items}
          search={search}
          favourites={favourites}
          editing={editing}
          navigation={navigation}
          actions={actions}
          userGroups={userGroups}
          onToggleMenu={handleToggleMenu}
          onToggleModal={handleToggleModal}
          onHoverSubmenuItem={handleHoverSubmenuItem}
          hideOptions={hideOptions}
        />
      </div>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        {layout === 'list' ? (
          <ExplorerList
            favourites={favourites}
            foldersTree={foldersTree}
            updated={updated}
            selected={selected}
            isLoading={isLoading}
            navigation={{
              ...navigation,
              onOpenFolder: () => {}
            }}
            editing={editing}
            actions={actions}
            userGroups={userGroups}
            onToggleMenu={handleToggleMenu}
            onToggleModal={handleToggleModal}
            isModalOpen={isOpenModal}
            onHoverSubmenuItem={handleHoverSubmenuItem}
            hideOptions={hideOptions}
            optionsState={optionsState}
            showEmptyText
          />
        ) : (
          <ExplorerGrid
            favourites={favourites}
            folder={folder}
            items={items}
            active={active}
            updated={updated}
            selected={selected}
            isLoading={isLoading}
            subitems={subitems}
            subitemsIsLoading={subitemsIsLoading}
            navigation={navigation}
            editing={editing}
            actions={actions}
            userGroups={userGroups}
            onToggleMenu={handleToggleMenu}
            onToggleModal={handleToggleModal}
            isModalOpen={isOpenModal}
            onHoverSubmenuItem={handleHoverSubmenuItem}
            hideOptions={hideOptions}
            optionsState={optionsState}
          />
        )}
      </DndContext>

      {(editing?.onMove || editing?.onCopy) && (
        <Modal
          size="small"
          title={t('select_action')}
          open={!!moveParams}
          onClose={() => setMoveParams(null)}
          buttons={[
            {
              children: t('copy'),
              onClick: () => (moveParams && editing.onCopy) && editing.onCopy({
                id: moveParams.source,
                targetId: moveParams.target,
                name: moveParams.name,
                type: moveParams.type
              }),
              viewStyle: 'tertiary',
            },
            {
              children: t('move'),
              onClick: () => (moveParams && editing.onMove) && editing.onMove({
                id: moveParams.source,
                targetId: moveParams.target,
                name: moveParams.name,
                type: moveParams.type
              }),
              viewStyle: 'primary',
            }
          ]}
        >
          <div className={styles.modalMove} />
        </Modal>
      )}
    </div>
  );
};

export default FileExplorer;
