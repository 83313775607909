import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import { IPromocode, ITariff, ITariffItem } from 'interfaces/tariffs.interface';
import TariffItemSkelet from './TariffItemSkelet';
import TariffsListItem from './TariffsListItem';

import styles from './tariffs-list.module.css';

interface TariffsListProps {
  mode: 'view'|'change'|'await',
  items?: ITariffItem[],
  current?: ITariff,
  selectedTariff: string|null,
  onSelectTariff: (id: string) => void,
  selectedDuration: number,
  selectedMembers: number,
  onChangeMembers: (value: number) => void,
  hasPromoTariff: boolean,
  isShowPromo: boolean,
  onTogglePromo: (show: boolean) => void,
  promocode?: IPromocode|null
}

const TariffsList: FC<TariffsListProps> = ({
  mode, items, current, selectedTariff, onSelectTariff, selectedDuration, selectedMembers, onChangeMembers,
  hasPromoTariff, isShowPromo, onTogglePromo, promocode
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {items ? (
        items.map(item => (
          <TariffsListItem
            {...item}
            isCurrent={current && item.id === current.tariff.id}
            isSelected={item.id === selectedTariff}
            disabled={['view', 'await'].includes(mode) && item.id !== current?.tariff.id}
            limitDisabled={['view', 'await'].includes(mode)}
            onSelectTariff={onSelectTariff}
            selectedDuration={selectedDuration}
            selectedMembers={selectedMembers}
            onChangeMembers={onChangeMembers}
            promocode={promocode}
            key={item.id}
          />
        ))
      ) : (
        [...Array(3)].map((item, index) => (
          <TariffItemSkelet showAdditional={index === 2} key={index} />
        ))
      )}
      {hasPromoTariff && (
        <div>
          {isShowPromo ? (
            <Button viewStyle="textLight" onClick={() => onTogglePromo(false)}>{t('show_all')}</Button>
          ) : (
            <Button viewStyle="textLight" onClick={() => onTogglePromo(true)}>{t('subscription.show_promo_tariff')}</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TariffsList;