import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';

import styles from './documents-no-items.module.css';

const DocumentsNoItems = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('created_docs')}</div>
      <div className={styles.subtitle}>{t('search_docs_not_created')}</div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          as={Link}
          viewStyle="primary"
          to="/templates"
        >
          {t('go_to_my_templates')}
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default DocumentsNoItems;
