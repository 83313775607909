import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';

import styles from './users-menu.module.css';

const links = [
  {
    lang: 'users',
    href: '/users'
  },
  {
    lang: 'users_groups',
    href: '/user-groups'
  },
  {
    lang: 'invite_user',
    href: '/invite'
  }
];

const UsersMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      {links.map(({ lang, href }) => (
        <Button
          as={Link}
          viewStyle={pathname.startsWith(href) ? 'primary' : 'secondary'}
          size="small"
          shadow={!pathname.startsWith(href)}
          to={href}
          key={href}
        >
          {t(lang)}
        </Button>
      ))}
    </div>
  );
};

export default UsersMenu;
