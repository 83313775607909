import classNames from 'classnames';

import styles from './user-invite.module.css';

const UserInviteSkelet = () => {
  return (
    <div>
      <div className={classNames(styles.usersText, 'skeleton-loader')} />
      <div className={classNames(styles.usersText, 'skeleton-loader')} />
      <div className={styles.form}>
        <div className={styles.fieldsRow}>
          <div className={styles.inputContainer}>
            <div className={classNames(styles.input, 'skeleton-loader')} />
          </div>
          <div className={styles.inputContainer}>
            <div className={classNames(styles.input, 'skeleton-loader')} />
          </div>
          <div className={styles.inputContainer}>
            <div className={classNames(styles.select, 'skeleton-loader')} />
          </div>
          <div className={styles.inviteRemoveIcon} />
        </div>
      </div>
    </div>
  );
};

export default UserInviteSkelet;