import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useDroppable,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { ButtonsContainer, Button } from '@forma/forma-ui-kit';
import { Grid, GridItem } from 'components/ItemsGrid';
import ExplorerHead from 'views/FileExplorer/ExplorerHead';
import ExplorerList from 'views/FileExplorer/ExplorerList';
import ExplorerGrid from 'views/FileExplorer/ExplorerGrid';
import { TemplateItem, Placeholder } from './TemplateItem';

import { useAppSelector } from 'store/hooks';
import { selectLayout } from 'store/common/commonSlice';

import { IFoldersFavouritesProps, IFoldersNavigationProps, IFoldersOptionsStateParams, IFoldersSearchProps } from 'interfaces/fileExplorer.interface';
import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersList } from 'interfaces/folders.interface';
import { ITemplatesPack, ITemplatesPackTemplate } from 'interfaces/templates.interface';

import styles from './templates-pack-edit.module.css';

interface ContainerProps {
  items: any[], // fixme
  isLoading: boolean,
  onRemoveTemplate: (id: string) => void
}

interface PackTemplatesProps {
  pack?: ITemplatesPack,
  isPackLoading: boolean,
  packItems: ITemplatesPackTemplate[],
  onSave: () => void,
  onAddTemplate: (template: ITemplatesPackTemplate, parent?: IFoldersList|IFolderListChidlren) => void,
  onRemoveTemplate: (id: string) => void,

  foldersTree?: IFolderListTreeChidlren[],
  folder?: IFoldersList,
  items?: IFolderListChidlren[],
  active?: string|null,
  selected?: string[],
  isLoading: boolean,
  subitems?: IFolderListChidlren[],
  subitemsIsLoading: boolean,
  search: IFoldersSearchProps,
  navigation: IFoldersNavigationProps,
  favourites: IFoldersFavouritesProps,
}

const optionsInitialState = {
  itemId: null,
  copyToItemId: null,
  moveToItemId: null
};

const Container: FC<ContainerProps> = ({ items, isLoading, onRemoveTemplate }) => {
  const { t } = useTranslation();

  const { setNodeRef: dropRef, isOver } = useDroppable({
    id: 'root_pack',
    data: { id: '' },
  });

  return (
    <div className={styles.root} style={{ boxShadow: isOver ? '0 0 5px #000' : undefined }} ref={dropRef}>
      {!isLoading && !items.length && (
        <div className={styles.noItems}>
          {t('templates_packs_drop_placeholder')}
        </div>
      )}
      <Grid columns={5}>
        {isLoading ? (
          [...Array(5).keys()].map(key => (
            <GridItem className={styles.item} key={key}>
              <Placeholder />
            </GridItem>
          ))
        ) : (
          !!items?.length && (
            items.map(({ id, name, path }) => (
              <GridItem className={styles.item} key={id}>
                <TemplateItem
                  id={id}
                  name={name}
                  folderName={(path && path.length) ? path[path.length-1].name : null}
                  onRemove={onRemoveTemplate}
                />
              </GridItem>
            ))
          )
        )}
      </Grid>
    </div>
  );
};

const PackTemplates: FC<PackTemplatesProps> = ({
  pack, isPackLoading, packItems, onSave, onAddTemplate, onRemoveTemplate,
  foldersTree, folder, items, active, selected, isLoading, subitems, subitemsIsLoading, search, favourites, navigation
}) => {
  const { t } = useTranslation();
  const [ optionsState, setOptionsState ] = useState<IFoldersOptionsStateParams>(optionsInitialState);
  const [ isOpenModal, setOpenModal ] = useState<boolean>(false);
  const layout = useAppSelector(selectLayout);

  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: { distance: 3 }
  }));

  const handleToggleMenu = (id: string|null) => {
    setOptionsState(prev => ({ ...prev, itemId: id }));
  };

  const handleToggleModal = (open: boolean) => {
    setOpenModal(open);
  };

  const handleHoverSubmenuItem = (id: string|null) => {
    setOptionsState(prev => ({ ...prev, moveToItemId: id }));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const current = active.data.current as { id: string, name: string, type: 'template'|'folder', folderId: 'string' };

    if (!over?.data.current || current.type === 'folder') return;
    if (current.id === over.data.current.id || packItems.findIndex(({ id }) => id === current.id) !== -1) return;

    const parent = items?.find(({ id }) => id === active?.data.current?.folderId)
      ?? subitems?.find(({ id }) => id === active?.data.current?.folderId)
      ?? folder;

    onAddTemplate({
      id: current.id,
      name: current.name,
      sideIndices: [],
      path: (parent?.id && parent.name && parent.translatedName)
        ? [{ id: parent.id, name: parent.name, translatedName: parent.translatedName }]
        : undefined
    });
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <Container
        items={packItems}
        isLoading={isPackLoading}
        onRemoveTemplate={onRemoveTemplate}
      />

      <ButtonsContainer className={styles.buttons}>
        <Button
          id="create_template_pack_button"
          viewStyle="primary"
          disabled={!packItems?.length}
          onClick={onSave}
        >
          {(pack || isPackLoading) ? t('continue') : t('create_templates_pack')}
        </Button>
      </ButtonsContainer>

      <div className={styles.nav}>
        <ExplorerHead
          selected={selected}
          items={items}
          search={search}
          favourites={favourites}
          navigation={navigation}
          onToggleMenu={handleToggleMenu}
          onToggleModal={handleToggleModal}
          onHoverSubmenuItem={handleHoverSubmenuItem}
        />
      </div>
      {layout === 'list' ? (
        <ExplorerList
          favourites={favourites}
          foldersTree={foldersTree}
          selected={selected}
          isLoading={isLoading}
          navigation={navigation}
          onToggleMenu={handleToggleMenu}
          onToggleModal={handleToggleModal}
          isModalOpen={isOpenModal}
          onHoverSubmenuItem={handleHoverSubmenuItem}
          optionsState={optionsState}
          showEmptyText
        />
      ) : (
        <ExplorerGrid
          favourites={favourites}
          folder={folder}
          items={items}
          active={active}
          selected={selected}
          isLoading={isLoading}
          subitems={subitems}
          subitemsIsLoading={subitemsIsLoading}
          navigation={navigation}
          onToggleMenu={handleToggleMenu}
          onToggleModal={handleToggleModal}
          isModalOpen={isOpenModal}
          onHoverSubmenuItem={handleHoverSubmenuItem}
          optionsState={optionsState}
        />
      )}
    </DndContext>
  );
};

export default PackTemplates;
