import { FC, useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation, Trans } from 'react-i18next';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { isWeekend, addBusinessDays, max, add } from 'date-fns';
import ru from 'date-fns/locale/ru';
import classNames from 'classnames';
import moment from 'moment';
import { ButtonsContainer, Button, ThemeContext, Modal, LoadingIndicator } from '@forma/forma-ui-kit';
import { getCookie } from 'helpers/cookies';
import TimezoneSelect from './TimezoneSelect';
import TimeItems from './TimeItems';

import { ITimeslotsItem } from 'interfaces/requests.inerface';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { useGetTimeslotsQuery } from 'store/tcrequests/tcrequestsApi';
import { useSetTimeslotMutation } from 'store/amo/amoApi';

import styles from './request-demo-modal.module.css';
import './datepicker.css';

interface UTCProps {
  utc: string,
  city: string
}

interface DateTimeModalProps {
  open: boolean,
  onClose: () => void
}

interface IDateItem {
  date: string,
  dateObj: Date,
  available: boolean,
  slots: ITimeslotsItem[]
}

const Title = ({ step, onChangeStep }: { step: number, onChangeStep: (step: number) => void}) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);

  if (viewport === 'phone') return (
    <div className={styles.modalTitle}>
      {step === 0 && t('date')}
      {step === 1 && (
        <><ReactSVG src="/icons/arrow-left.svg" className={styles.modalTitleIcon} wrapper="span" onClick={() => onChangeStep(0)} /> {t('time')}</>
      )}
    </div>
  );
  return (
    <div className={styles.modalTitle}>
      {t('select_date_and_time')} {t('onboarding_request_descsription')}
    </div>
  );
};

const RequestDemoModal: FC<DateTimeModalProps> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const user = useAppSelector(selectUserData);
  const { data: timeslots } = useGetTimeslotsQuery(undefined, { skip: !open });
  const [ setTimeslot, { data: saveResult, isSuccess } ] = useSetTimeslotMutation();

  const [ slot, setSlot ] = useState<string|null>(null);
  const [ value, setValue ] = useState<string>(moment(addBusinessDays(new Date(), 1)).format('YYYY-MM-DDTHH:mm:ssZ'));
  const [ utc, setUtc ] = useState<UTCProps>({ utc: moment().format('Z'), city: '' });
  const [ step, setStep ] = useState<number>(0);

  if (i18n.language === 'ru') registerLocale('ru', ru);

  const handleChangeDate = (date: Date) => {
    setValue(date.toISOString().replace('Z', utc.utc));
    setSlot(null);
  };

  const handleChangeTime = (id: string, date: string) => {
    setValue(date);
    setSlot(id);
  };

  const handleChangeUTC = (value: UTCProps) => {
    setUtc(value);
  };

  const handleClickSave = () => {
    if (!slot || !user) return;
    const roistatVisit = getCookie('roistat_visit');

    setTimeslot({
      timeslotId: slot,
      gmt: utc.utc,
      roistatVisit
    });
  };

  const handleClickNext = () => {
    setStep(prev => prev+1);
  };

  const dates = useMemo(() => timeslots?.reduce((acc: { [key: string]: IDateItem }, current: ITimeslotsItem) => {
    const date = current.date.slice(0, 10);
    const slot = { id: current.id, date: current.date, available: current.available };
    const next = {
      date: date,
      dateObj: new Date(date),
      available: current.available || acc[date]?.available || false,
      slots: acc[date] ? [ ...acc[date].slots, slot ] : [ slot ]
    };
    return ({ ...acc, [date]: next });
  }, {}), [timeslots]);

  const disabledDates = useMemo(() => dates && Object.values(dates)
    .filter(({ available, dateObj }) => !available || isWeekend(dateObj))
    .map(({ dateObj }) => dateObj), [dates]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="730px"
      buttons={(isSuccess && saveResult) ? [{
        viewStyle: 'primary',
        className: styles.modalButton,
        children: t('next')
      }] : undefined}
    >
      {(isSuccess && saveResult) ? (
        <div className={styles.modalSuccess}>
          <div className={styles.modalTitle}>
            <Trans
              i18nKey="you_signed_up_for_demonstration"
              components={{ accent: <span className="accent-text" /> }}
            />
            <div className={styles.modalDescr}>
              <div className="accent-text">
                {moment(saveResult.date).utcOffset(saveResult.gmt).format('DD.MM.yyyy')} {t('year_short')} {t('at')} {moment(saveResult.date).utcOffset(saveResult.gmt).format('HH:mm')}
              </div>
            </div>
          </div>

          <div className={styles.result}>
            <div className={styles.resultSubtitle} id="request_demo_success_time">{t('demonstration_time_notice')}</div>
            <div className={styles.resultSubtitle}>{t('demonstration_description')}</div>
          </div>
        </div>
      ) : (
        (!timeslots) ? (
          <div className={classNames(styles.modal, 'request-modal')}>
            <Title step={step} onChangeStep={setStep} />
            <div className={styles.loader}>
              <LoadingIndicator color={'var(--primary-color)'} />
            </div>
          </div>
        ) : (
          <div className={classNames(styles.modal, 'request-modal')}>
            <Title step={step} onChangeStep={setStep} />
            <div className={styles.modalContent}>
              {(viewport !== 'phone' || step === 0) && (
                <div className={styles.calendar}>
                  <ReactDatePicker
                    selected={new Date(value)}
                    onChange={handleChangeDate}
                    excludeDates={disabledDates}
                    minDate={addBusinessDays(new Date(), 1)}
                    maxDate={dates ? max(Object.values(dates).map(({ dateObj }) => dateObj)) : add(new Date(), { months: 1 })}
                    previousMonthButtonLabel={
                      <img src="/icons/arrow-left.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                    }
                    nextMonthButtonLabel={
                      <img src="/icons/arrow-right.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                    }
                    locale={i18n.language}
                    inline
                  />
                </div>
              )}

              {(viewport !== 'phone' || step === 1) && (
                <div className={styles.time}>
                  <div className={styles.timeHead}>
                    {viewport !== 'phone' && t('what_is_the_best_time')}
                    <TimezoneSelect value={utc} onChange={handleChangeUTC} />
                  </div>
                  <div className={styles.timeItems}>
                    <TimeItems
                      utc={utc}
                      slots={(dates && dates[moment(value).format('yyyy-MM-DD')]) && dates[moment(value).format('yyyy-MM-DD')].slots}
                      value={slot}
                      onChange={handleChangeTime}
                    />
                  </div>
                </div>
              )}
            </div>

            <ButtonsContainer className={styles.buttons}>
              <Button
                className={styles.modalButton}
                viewStyle="primary"
                onClick={(viewport === 'phone' && step !== 1) ? handleClickNext : handleClickSave}
                disabled={(viewport !== 'phone' || step === 1) && !slot}
              >
                {(viewport === 'phone' && step !== 1) ? t('next') : t('sign_up_demo')}
              </Button>
            </ButtonsContainer>
          </div>
        )
      )}
    </Modal>
  );
};

export default RequestDemoModal;
