import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import update from 'immutability-helper';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Input, RemoveModal } from '@forma/forma-ui-kit';
import VariablesItem from './VariablesItem';

import { ITemplateSideGroup } from 'interfaces/templates.interface';
import { IUserVariableItem, IVariableItem } from 'interfaces/variables.interface';

import styles from './variables-group.module.css';

interface VariablesGroupProps {
  id: string,
  index: number,
  name: string,
  opacity?: number,
  showInline?: boolean,
  isDraggable?: boolean,
  isOver?: boolean,
  isDragging?: boolean,
  draggingVariableIndex?: number|null,
  sideId: string,
  sideName: string,
  tattrs?: (IVariableItem|IUserVariableItem)[],
  onRemove?: (id: string) => void,
  onChange?: (data: ITemplateSideGroup) => void,
  onAddVariable: (sideId: string, sideName: string, tattrId: string, tattrName: string, tattrType: string) => void,
  onClickAddVariable: (folderId: string) => void,
}

const VariablesGroup: FC<VariablesGroupProps> = ({
  id, index, name, showInline, isOver, opacity, isDraggable, isDragging, draggingVariableIndex, tattrs,
  onRemove, onChange, onAddVariable, onClickAddVariable, ...props
}) => {
  const { t } = useTranslation();
  const [ isEdit, setIsEdit ] = useState<boolean>(!name.length);
  const [ editName, setEditName ] = useState<string>(name);

  const {
    attributes,
    listeners,
    setNodeRef: sortRef,
    transform,
    transition,
  } = useSortable({ id, data: { index, type: 'GROUP' } });

  if (!tattrs) return null;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity
  };

  const handleRename = () => {
    if (onChange) onChange({ id, name: editName, tattrs });
    setIsEdit(false);
  };

  const handleRemoveVariable = (varId: string) => {
    const index = tattrs.findIndex(tattr => varId === tattr.id);
    if (onChange) onChange({
      id,
      name,
      tattrs: update(tattrs, {
        $splice: [[ index, 1 ]]
      })
    });
  };

  return (
    <div className={classNames(
      styles.group,
      isDraggable && styles.draggable,
      isDragging && styles.dragging,
      isOver && styles.over
    )} style={style} ref={sortRef}>
      <div className={styles.groupHead}>
        <div className={styles.groupTitle}>
          {isEdit ? (
            <Input
              name={`group_name_${id}`}
              placeholder={t('input_group_name')}
              className={styles.renameInput}
              value={editName}
              onChange={e => setEditName(e.target.value)}
              showClearButton
            />
          ) : `${name}:`}
        </div>
        {(onChange && onRemove) && (
          <div className={styles.groupButtons}>
            {isEdit ? (
              <Button
                className={styles.renameSubmit}
                iconClassName={styles.renameSubmitIcon}
                onClick={handleRename}
                viewStyle="primary"
                icon={<ReactSVG src="/icons/tick.svg" wrapper="span"></ReactSVG>}
                title={t('apply')}
                disabled={editName.length < 3}
              />
            ) : (
              <Button
                className={styles.edit}
                onClick={() => setIsEdit(true)}
                viewStyle="textLight"
                icon={<ReactSVG src="/icons/edit.svg" wrapper="span"></ReactSVG>}
                title={t('rename')}
              >
                {showInline && t('rename')}
              </Button>
            )}
            <RemoveModal
              title={t('deleting_group')}
              control={
                <Button
                  className={styles.remove}
                  viewStyle="textLight"
                  icon={<ReactSVG src="/icons/trash.svg" wrapper="span"></ReactSVG>}
                  title={t('delete')}
                >
                  {showInline && t('delete')}
                </Button>
              }
              onRemove={() => onRemove && setTimeout(() => onRemove(id), 100)}
              itemName={name}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.items, showInline && styles.inline)}>
        <SortableContext items={tattrs} strategy={verticalListSortingStrategy}>
          {tattrs?.length ? (
            tattrs.map((item, index) => (
              <VariablesItem
                {...props}
                {...item}
                onClick={onAddVariable}
                onRemove={handleRemoveVariable}
                opacity={draggingVariableIndex === index ? 0 : 1}
                folderId={id}
                index={index}
                key={item.id}
              />
            ))
          ) : (
            <div className={styles.dropPlaceholder}>
              {t('move_need_vars')}
            </div>
          )}
        </SortableContext>
      </div>
      {isDraggable && (
        <div className={styles.buttons}>
          <Button
            className={styles.variableAdd}
            viewStyle="textLight"
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            onClick={() => onClickAddVariable(id)}
          >
            {t('add_variable')}
          </Button>
        </div>
      )}
      {isDraggable && (
        <div className={styles.handler} {...attributes} {...listeners}>
          <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
        </div>
      )}
    </div>
  );
};

export default VariablesGroup;
