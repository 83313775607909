import { FC, ReactNode, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ScrollToTopButton, ThemeContext } from '@forma/forma-ui-kit';
import type { BreadcrumbsProps } from '@forma/forma-ui-kit';
import SideMenu from 'components/SideMenu';
import BottomMenu from 'components/BottomMenu';
import TelegramButton from 'components/TelegramButton';
import RequestDemoModal from 'views/Guide/RequestDemoModal';
import CreateTemplateModal from 'views/FileExplorer/CreateTemplateModal';

import { selectMenuOpen, setOnboardingModal } from 'store/common/commonSlice';
import { selectUserData } from 'store/user/userSlice';
import { useAppDispatch } from 'store/hooks';

import styles from './admin-layout.module.css';

interface AdminLayoutProps {
  children: ReactNode,
  className?: string,
  title?: ReactNode,
  breadcrumbs?: BreadcrumbsProps,
  showFaqButton?: boolean
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

// const findLastBcLink = (items: BreadcrumbsItem[]): string|undefined => {
//   const item = items.slice().reverse().find(({ to, href }) => !!(to || href));
//   return item?.to || item?.href;
// };

const TrialNotification = ({ trialDays }: { trialDays?: number }) => {
  const { t } = useTranslation();

  if (trialDays || trialDays === 0) return (
    <div className={classNames(styles.notification, trialDays < 4 ? 'error' : (trialDays < 7 ? 'attention' : 'success'))}>
      <span className={styles.notificationText}>
        <ReactSVG className={styles.notificationIcon} src="/icons/clock.svg" wrapper="span" />
        {trialDays ? (
          <span>{t('count_days', { count: trialDays })} {t('until_trial_end')}</span>
        ) : (
          <span style={{ fontWeight: 600 }}>{t('trial_expired')}</span>
        )}
      </span>

      <Link
        className={styles.notificationLink}
        to="/select-tariff"
      >
        {trialDays ? t('subscription.subscribe_now') : t('subscription.pay')}
      </Link>
    </div>
  );

  return null;
};

const AdminLayout: FC<AdminLayoutProps> = ({ children, className, title, breadcrumbs, showFaqButton }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMenuOpen = useSelector(selectMenuOpen);
  const user = useSelector(selectUserData);
  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';
  const [ isRequestOpen, setRequestOpen ] = useState<boolean>(false);

  // const lastBcLink = breadcrumbs?.items && findLastBcLink(breadcrumbs.items);

  const trialDays = user?.alertMessage ? Number(user.alertMessage) : undefined;

  return (
    <>
      {!isPhone && (
        <div className={styles.menu}>
          <SideMenu />
        </div>
      )}

      <header className={styles.header} id="header">
        {/* {isPhone && lastBcLink && (
          <div className={styles.menu}>
            <Button
              as={Link}
              className={styles.backButton}
              iconClassName={styles.backButtonIcon}
              icon={<ReactSVG src="/icons/arrow-left-bold.svg" wrapper="span" />}
              viewStyle="text"
              to={lastBcLink}
            />
          </div>
        )} */}
        {title && (
          <div className={styles.title}>
            {title}

            {!isPhone && showFaqButton && (
              <Button
                viewStyle="textPrimary"
                className={styles.link}
                onClick={() => dispatch(setOnboardingModal('edit_template_modal_static'))}
              >
                {t('guide.howto_create_template')}
              </Button>
            )}
          </div>
        )}
        {/* {breadcrumbs &&
          <div className={styles.breadcrumbs}>
            <Breadcrumbs {...breadcrumbs} />
            {showFaqButton && (
              <Button
                viewStyle="textPrimary"
                className={styles.link}
                onClick={() => dispatch(setOnboardingModal('edit_template_modal_static'))}
              >
                {t('guide.howto_create_template')}
              </Button>
            )}
          </div>
        } */}
        <div className={styles.buttons}>
          <RequestDemoModal
            open={isRequestOpen}
            onClose={() => setRequestOpen(false)}
          />
          <CreateTemplateModal
            control={
              <Button
                className={styles.buttonOutline}
                size="extraSmall"
              >
                {t('help_setting_templates')}
              </Button>
            }
            defaultMode="select"
          />
          <Button
            className={styles.buttonOutline}
            size="extraSmall"
            onClick={() => setRequestOpen(true)}
          >
            {t('go_to_demo')}
          </Button>
        </div>
        <div className={styles.buttons}>
          <Button
            as="a"
            viewStyle="text"
            className={styles.button}
            iconClassName={styles.buttonIcon}
            icon={<ReactSVG src="/icons/menu/question.svg" wrapper="span" />}
            title={t('knowledge')}
            href={`${landingUrl}/knowlege`}
            target="_blank"
          />
          <Button
            as={Link}
            viewStyle="text"
            className={styles.button}
            iconClassName={styles.buttonIcon}
            icon={<ReactSVG src="/icons/menu/settings.svg" wrapper="span" />}
            title={t('settings')}
            to="/profile"
          />
        </div>
      </header>

      <TrialNotification trialDays={trialDays} />

      <main className={classNames(styles.content, 'container', className, (!isPhone && isMenuOpen) && styles.menuOpen)}>
        {children}
      </main>

      {isPhone && <BottomMenu />}

      <div className={styles.question}>
        <TelegramButton />
      </div>

      <div className={styles.scrollToTop}>
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default AdminLayout;
