import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import StaticTemplates from 'views/StaticTemplatess';

import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import {
  useCopySharedTemplateMutation,
  useGetSharedFoldersTreeQuery,
  useGetSharedTemplatesQuery
} from 'store/sharedTemplates/sharedTemplatesApi';
import { useCreateTemplateMutation } from 'store/templates/templatesApi';
import { useGetActivitiesListQuery } from 'store/user/userApi';

const TemplatesByCategory = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: sharedFolders, isLoading: isFoldersLoading } = useGetSharedFoldersTreeQuery();
  const { data: sharedTemplates, isFetching: isTemplatesLoading } = useGetSharedTemplatesQuery(
    { categories: id }, { skip: !id }
  );
  const { data: categories } = useGetActivitiesListQuery();
  const [ copySharedTemplate ] = useCopySharedTemplateMutation();
  const [ createTemplate ] = useCreateTemplateMutation();

  const handleClickCopyTemplate = async (id: string) => {
    const result = await copySharedTemplate({ id });
    if ('data' in result && result.data) return result.data.translatedName;
    return null;
  };

  const handleCreateTemplate: IFoldersEditingProps['onCreate'] = async ({ name }) => {
    const result = await createTemplate({ name });
    if (!('data' in result)) return null;
    return { id: result.data.id, name: result.data.name, translatedName: result.data.translatedName };
  };

  const category = categories?.find(category => category.id === id);

  return (
    <AdminLayout
      title={t('my_templates')}
      breadcrumbs={{ items: [ { as: Link, name: t('my_templates'), to: '/templates' }, { name: t('forma_templates') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <StaticTemplates
        isLoading={isFoldersLoading || isTemplatesLoading}
        mainFolder={{ id, name: category?.name ?? '' }}
        templates={sharedTemplates?.templates}
        tree={sharedFolders}
        categories={categories}
        onClickAddTemplate={handleClickCopyTemplate}
        onCreateTemplate={handleCreateTemplate}
        showFilters
      />
    </AdminLayout>
  );
};

export default TemplatesByCategory;
