import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, RemoveModal, TableCell, TableRow } from '@forma/forma-ui-kit';
import { IProductsItem } from 'interfaces/products.interface';

import styles from './products-services-item.module.css';

interface ProductsServicesItemProps extends IProductsItem {
  onRemove?: (id: string) => void
}

const ProductsServicesItem: FC<ProductsServicesItemProps> = ({
  id, vendorCode, name, category, unit, price, onRemove
}) => {
  const { t } = useTranslation();

  return (
    <TableRow className={styles.row}>
      <TableCell className={styles.cell} title={t('sku')}>
        <Link to={`/products/${id}`}>
          {vendorCode}
        </Link>
      </TableCell>
      <TableCell className={styles.cell} title={t('name')}>
        <Link to={`/products/${id}`} className={styles.name}>
          {name}
        </Link>
      </TableCell>
      <TableCell className={styles.cell} title={t('category')}>
        <Link to={`/products/${id}`}>
          {category.name}
        </Link>
      </TableCell>
      <TableCell className={styles.cell} title={t('unit')}>
        <Link to={`/products/${id}`}>
          {unit}
        </Link>
      </TableCell>
      <TableCell className={styles.cell} title={t('price')}>
        <Link to={`/products/${id}`}>
          {price}
        </Link>
      </TableCell>
      {onRemove && (
        <TableCell className={styles.button}>
          <RemoveModal
            control={
              <Button
                title={t('delete')}
                className={styles.remove}
                viewStyle="textLight"
                icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
              />
            }
            title={t('deleting_product_service')}
            onRemove={() => onRemove(id)}
            itemName={name}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProductsServicesItem;
