import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';

import { setToken } from 'store/auth/authSlice';

const Auth = () => {
  const dispatch = useAppDispatch();
  const [ searchParams ] = useSearchParams();
  const token = searchParams.get('token');

  if (token) dispatch(setToken(token));

  return <Navigate to="/" replace />;
};

export default Auth;
