import { FC, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ContextMenu, RemoveModal } from '@forma/forma-ui-kit';
import RenameModal from 'components/RenameModal';
import ModalSignedDocument from 'views/MyDocuments/ModalSignedDocument';
import ModalChangeDocument from 'pages/MyDocuments/ModalChangeDocument';
import DocumentsItem from './DocumentsItem';

import { IDocumentRender } from 'interfaces/documents.interface';

import styles from './documents-item.module.css';

interface DocumentsItemRenderProps {
  data: IDocumentRender,
  defaultOpen?: boolean,
  onSign: (id: string) => void,
  onRename: (id: string, name: string) => void,
  onRemove: (id: string) => void,
}

// TODO: объединить контролы в один компонент

const DocumentsItemRender: FC<DocumentsItemRenderProps> = ({
  data, defaultOpen, onSign, onRename, onRemove
}) => {
  const { t } = useTranslation();
  const { id, name, at, confirmed, confirmedAt } = data;
  const [ isShowEditModal, setShowEditModal ] = useState<boolean>(!!defaultOpen);
  const [ isShowSignModal, setShowSignModal ] = useState<boolean>(false);
  const [ isShowRename, setShowRename ] = useState<boolean>(false);
  const [ isShowRemove, setShowRemove ] = useState<boolean>(false);

  return (
    <DocumentsItem
      id={id}
      name={name}
      icon="/icons/documents/simple.svg"
      createdAt={at}
      confirmed={confirmed}
      confirmedAt={confirmedAt}
      contextMenu={<>
        <ContextMenu
          offsetY={8}
          control={open => (
            <button className={classNames(styles.optionsButton, open && styles.active)}>
              <ReactSVG src="/icons/more.svg" />
            </button>
          )}
          items={[
            {
              text: t('rename'),
              disabled: true,
              onClick: () => setShowRename(true)
            }, {
              text: !confirmed ? t('sign') : t('remove_sign'),
              onClick: () => setShowSignModal(true)
            }, {
              text: t('delete'),
              onClick: () => setShowRemove(true)
            },
          ]}
        />
        <RenameModal
          open={isShowRename}
          onClose={() => setShowRename(false)}
          onSubmit={(name) => onRename(id, name)}
          name={name}
          title={t('rename_document')}
          placeholder={t('enter_name')}
        />
        <RemoveModal
          open={isShowRemove}
          onClose={() => setShowRemove(false)}
          onRemove={() => onRemove(id)}
          itemName={name}
          title={t('deleting_document')}
        />
        <ModalSignedDocument
          open={isShowSignModal}
          onClose={() => setShowSignModal(false)}
          item={data}
          onSigned={onSign}
        />
        <ModalChangeDocument
          open={isShowEditModal}
          onClose={() => setShowEditModal(false)}
          id={data.id}
          name={data.name}
        />
      </>}
      onClick={() => setShowEditModal(true)}
    />
  );
};

export default DocumentsItemRender;
