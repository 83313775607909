import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, Button, SearchInput, useDebounce, PaginationWrapper, getFromTo, Table, TableRow, TableCell } from '@forma/forma-ui-kit';
import ContragentCreateModal from 'pages/Contragents/ContragentCreateModal';
import { contragentTypes, parseOrgData, revertOrgData } from '../utils';

import { ICounteragentsItem, ICounteragentType } from 'interfaces/counteragents.interface';
import { useCreateCounteragentMutation, useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useAppDispatch } from 'store/hooks';

import styles from './conteragents-search.module.css';

interface ContragentsSearchProps {
  selected?: string|null,
  onClickItem: (id: ICounteragentsItem) => void,
}

const ConteragentsSearch: FC<ContragentsSearchProps> = ({
  selected, onClickItem
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ isOpenCreate, setOpenCreate ] = useState<boolean>(false);
  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ page, setPage ] = useState<number>(1);
  const [ params, setParams ] =
    useState<{ from: number, to: number, type: ICounteragentType, filter?: string }>({ ...getFromTo(1), type: contragentTypes[0] });

  const { data: counteragents, isFetching: isLoading } = useGetCounteragentsQuery(params, { skip: !params.type });
  const [ createCounteragent, { isLoading: isCreateLoading } ] = useCreateCounteragentMutation();

  const changeSearchDebounce = useDebounce((value: string) => {
    setParams(prev => ({ ...prev, filter: value }));
  }, 500);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    changeSearchDebounce(value);
  };

  // const handleChangeFilter = (values: { type: ICounteragentType }) => {
  //   setParams(prev => ({ ...prev, ...values }));
  // };

  const handleChangePage = (page: number) => {
    setPage(page);
    setParams(prev => ({ ...prev, ...getFromTo(page) }));
  };

  const handleChangeType = (type: ICounteragentType) => {
    setSearchValue('');
    setParams({ type, ...getFromTo(1) });
  };

  const handleAddCounteragent = async (type: ICounteragentType, attrValues: { [key: string]: string }) => {
    const result = await createCounteragent({
      type,
      selfowned: true,
      favorite: false,
      attrValues
    });
    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('contragent_added'), type: 'SUCCESS' }));
      onClickItem(revertOrgData(result.data));
      setOpenCreate(false);
    }
  };

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.head}>
        {contragentTypes.map(item => (
          <Button
            onClick={() => handleChangeType(item)}
            viewStyle={params.type === item ? 'primary' : 'secondary'}
            size="small"
            shadow
            key={item}
          >
            {t(`types_${item}`)}
          </Button>
        ))}
      </ButtonsContainer>

      <div className={classNames(styles.container, searchValue && styles.active)}>
        <SearchInput
          onSearch={handleChangeSearch}
          className={styles.search}
          placeholder={params.type === 'entity' ? t('enter_inn_or_organization_name') : t('name_surname_lastname')}
          key={params.type}
        />
        <div className={styles.items}>
          <PaginationWrapper
            isLoading={isLoading || isCreateLoading}
            page={page}
            onChange={handleChangePage}
            count={counteragents?.count || counteragents?.outside.length || 0}
          >
            <Table className={styles.table}>
              {!!(counteragents?.items.length) && (
                counteragents?.items.map(item => (
                  <TableRow
                    className={classNames(styles.item, (selected && item.id === selected) && styles.selected)}
                    isSelected={selected ? item.id === selected : undefined}
                    key={item.id}
                  >
                    <TableCell onClick={() => onClickItem(item)}>
                      {item.fullName || item.name || item.legalName}
                    </TableCell>
                    <TableCell onClick={() => onClickItem(item)}>
                      {item.inn}
                    </TableCell>
                  </TableRow>
                ))
              )}
              {!!(counteragents?.outside?.length) && (
                <TableRow className={styles.subtitle}>
                  <TableCell colSpan={2}>{t('global_search_from_egrul')}:</TableCell>
                </TableRow>
              )}
              {counteragents?.outside?.map(({ value, unrestricted_value, data }) => (
                <TableRow
                  className={styles.item}
                  key={data.inn ?? value}
                >
                  <TableCell
                    onClick={() => handleAddCounteragent(data.inn.length === 12 ? 'individual' : 'entity', parseOrgData(data))}
                  >
                    {data.name?.full_with_opf || data.name?.full || value || (data.fio && `${data.fio.surname} ${data.fio.name} ${data.fio.patronymic}`)}
                  </TableCell>
                  <TableCell
                    onClick={() => handleAddCounteragent(data.inn.length === 12 ? 'individual' : 'entity', parseOrgData(data))}
                  >
                    {data.inn}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </PaginationWrapper>
          {!isLoading && counteragents && counteragents.count < 5 && (
            <div className={styles.buttons}>
              <Button
                className={styles.noItemsButton}
                icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                size="small"
                onClick={() => setOpenCreate(true)}
              >
                {t('add_contragent')}
              </Button>
            </div>
          )}
        </div>
      </div>

      <ContragentCreateModal
        open={isOpenCreate}
        onClose={setOpenCreate}
        onAdd={values => handleAddCounteragent(params.type as ICounteragentType, values)}
        isAddLoading={isCreateLoading}
        type={params.type as ICounteragentType}
      />
    </div>
  );
};

export default ConteragentsSearch;
