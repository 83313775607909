import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import UserGroupsEdit from 'views/profile/UserGroupsEdit';
import UsersMenu from 'views/profile/UsersMenu';

import {
  useGetGroupsListQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} from 'store/groups/groupsApi';
import {
  useGetPermissionsQuery
} from 'store/schemas/schemasApi';
import { selectUserPermissions } from 'store/user/userSlice';

const UserGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userPermissions = useSelector(selectUserPermissions);

  const { data: permissions } = useGetPermissionsQuery();
  const { data: groups, isLoading: isGroupLoading } = useGetGroupsListQuery();

  const [ addGroup, { isLoading: isAddGroupLoading } ] = useAddGroupMutation();
  const [ updateGroup ] = useUpdateGroupMutation();
  const [ removeGroup ] = useDeleteGroupMutation();

  useEffect(() => {
    if (userPermissions) {
      const canChangeUsers = userPermissions.includes('users');
      if (!canChangeUsers) navigate('/profile');
    };

    // eslint-disable-next-line
  }, [userPermissions]);

  const handleChangeGroup = (id: string, name: string, checked: boolean) => {
    const group = groups?.find(group => group.id === id);
    if (!group) return;

    let permissions = [ ...group.permissions ];
    if (checked) permissions.push(name);
    else permissions = permissions.filter(pName => pName !== name);

    updateGroup({
      id,
      name: group.name,
      permissions: permissions
    });
  };

  const handleCreateGroup = (name: string) => {
    addGroup({ name, permissions: [] });
  };

  const handleRemoveGroup = (id: string) => {
    removeGroup(id);
  };

  return (
    <AdminLayout
      title={t('users_groups')}
      breadcrumbs={{ items: [ { name: t('settings'), to: '/profile', as: Link }, { name: t('users_groups') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('users_groups')}</PageTitle>

      <UsersMenu />
      <div>
        <UserGroupsEdit
          permissions={permissions}
          groups={groups}
          onChangeGroup={handleChangeGroup}
          isAddGroupLoading={isAddGroupLoading}
          isGroupLoading={isGroupLoading}
          onAddGroup={handleCreateGroup}
          onRemoveGroup={handleRemoveGroup}
        />
        {/* <ButtonsContainer>
          <LoadingButton
            viewStyle={butStyles.primary}
            className={styles.saveGroups}
            isLoading={isSaveLoading}
            disabled={!Object.keys(updatedGroups).length}
            onClick={handleClickSave}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer> */}
      </div>
    </AdminLayout>
  );
};

export default UserGroups;
