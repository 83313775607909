import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { useAppSelector } from 'store/hooks';
import { Button, SearchInput, LoadingIndicator } from '@forma/forma-ui-kit';
import { Grid, GridItem } from 'components/ItemsGrid';
import { IFolderListChidlren } from 'interfaces/folders.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import {
  IFoldersActionsProps,
  IFoldersEditingProps,
  IFoldersFavouritesProps,
  IFoldersNavigationProps,
  IFoldersSearchProps
} from 'interfaces/fileExplorer.interface';
import { selectUserPermissions } from 'store/user/userSlice';
import { selectLayout } from 'store/common/commonSlice';

import CreateFolderModal from '../CreateFolderModal';
import CreateTemplateModal from '../CreateTemplateModal';
import ExplorerList from '../ExplorerList';
import { ExplorerGridItem } from '../ExplorerGrid/ExplorerGridItem';
import ViewSwitch from './ViewSwitch';

import styles from './explorer-head.module.css';

interface ExplorerHeadProps {
  parentid?: string|null,
  items?: IFolderListChidlren[],
  selected?: string[],
  search: IFoldersSearchProps
  favourites: IFoldersFavouritesProps,
  editing?: IFoldersEditingProps,
  userGroups?: IUserGroupsItem[],
  navigation: IFoldersNavigationProps,
  actions?: IFoldersActionsProps,
  onToggleMenu: (id: string|null) => void,
  onToggleModal: (open: boolean) => void,
  isModalOpen?: boolean,
  onHoverSubmenuItem: (id: string|null) => void,
  hideOptions?: boolean
}

const ExplorerHead: FC<ExplorerHeadProps> = ({
  items, parentid, search, favourites, editing, navigation, userGroups,
  hideOptions, onToggleMenu, onToggleModal, isModalOpen, onHoverSubmenuItem, actions, selected
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [ isSearchFocus, setSearchFocus ] = useState<boolean>(false);
  const userPermissions = useAppSelector(selectUserPermissions);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const layout = useAppSelector(selectLayout);

  return (
    <div className={styles.root} key={pathname}>
      <div className={styles.container}>
        <SearchInput
          onSearch={search.onChange}
          className={classNames(styles.search, isSearchFocus && styles.focused, search.results && styles.active)}
          placeholder={t('search')}
          onFocus={() => setSearchFocus(true)}
          onBlur={() => setSearchFocus(false)}
        />
        <div className={classNames(styles.viewSwitch, (isSearchFocus || search.results) && styles.hidden)}>
          <ViewSwitch />
        </div>
        {(canChangeFolders && editing?.onCreate) && (
          <>
            <CreateTemplateModal
              onCreate={editing.onCreate}
              parentid={parentid}
              control={
                <Button
                  id="create_template" // id for onboarding
                  viewStyle="secondary"
                  icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
                  disabled={favourites.isOpen}
                  data-testid={`create_template`}
                  shadow
                >
                  {t('create_template')}
                </Button>
              }
            />
            <CreateFolderModal
              onCreate={editing.onCreate}
              parentid={parentid}
              control={
                <Button
                  id="create_folder" // id for onboarding
                  viewStyle="secondary"
                  icon={<ReactSVG src="/icons/folder-thin.svg" wrapper="span" />}
                  disabled={favourites.isOpen}
                  shadow
                >
                  {t('create_folder')}
                </Button>
              }
            />
          </>
        )}
        {favourites.href && (
          <Button
            id="favourites" // id for onboarding
            as={Link}
            to={favourites.href}
            onClick={favourites.onClick}
            viewStyle={favourites.isOpen ? 'primary' : 'secondary'}
            icon={<ReactSVG src="/icons/favorite.svg" wrapper="span" className={classNames(styles.icon, favourites.isOpen && styles.active)} />}
            shadow
          >
            {t('favourites')}
          </Button>
        )}
      </div>
      {search.results &&
        <div className={styles.searchResults}>
          {search.isLoading ? (
            <div className={styles.loader}>
              <LoadingIndicator color="var(--color-white-primary)" />
            </div>
          ) : (
            search.results.length ? (
              layout === 'list' ? (
                <ExplorerList
                  foldersTree={search.results}
                  selected={selected}
                  userGroups={userGroups}
                  editing={editing}
                  hideOptions={hideOptions}
                  onToggleMenu={onToggleMenu}
                  onToggleModal={onToggleModal}
                  isModalOpen={isModalOpen}
                  onHoverSubmenuItem={onHoverSubmenuItem}
                  actions={actions}
                  navigation={{
                    ...navigation, onShowFolderContent: () => {}
                  }}
                />
              ) : (
                <Grid columns={5}>
                  {search.results.map(item => (
                    <GridItem className={styles.item} key={`search_${item.id ?? item.name}`}>
                      <ExplorerGridItem
                        item={item}
                        items={items}
                        isSelected={selected?.includes(item.id)}
                        userGroups={userGroups}
                        editing={{ ...editing, onCopy: undefined, onMove: undefined }}
                        hideOptions={hideOptions}
                        onToggleMenu={onToggleMenu}
                        onToggleModal={onToggleModal}
                        onHoverSubmenuItem={onHoverSubmenuItem}
                        actions={actions}
                        navigation={{
                          ...navigation, onShowFolderContent: () => {}
                        }}
                      />
                    </GridItem>
                  ))}
                </Grid>
              )
            ) : (
              <div className={styles.searchNoItems}>
                {t('search_nothing_found')}
              </div>
            )
          )}
        </div>
      }
    </div>
  );
};

export default ExplorerHead;
