import { FC } from 'react';

import styles from './password-banner.module.css';

interface PasswordBannerProps {
  visible: boolean
}

const PasswordBanner: FC<PasswordBannerProps> = ({ visible }) => {
  return (
    <div className={styles.root}>
      <img src={visible ? '/images/password-visible.svg' : '/images/password-hidden.svg'} className={styles.image} alt="" />
    </div>
  );
};

export default PasswordBanner;
