import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import GuideView from 'views/Guide';

import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { useGetUsersListQuery, useInviteUserMutation } from 'store/user/userApi';
import { useCreateTemplateMutation } from 'store/templates/templatesApi';
import { selectOnboarding } from 'store/user/userSlice';

const Guide = () => {
  const { t } = useTranslation();
  const onboarding = useSelector(selectOnboarding);
  // const userPermissions = useSelector(selectUserPermissions);

  const { data: currentTariff } = useGetCurrentTariffQuery();
  const { data: groups } = useGetGroupsListQuery();
  const { data: users } = useGetUsersListQuery({});
  const [ inviteUser, { isLoading: isInviteLoading, isSuccess: isInviteSuccess } ] = useInviteUserMutation();
  const [ createTemplate ] = useCreateTemplateMutation();

  const handleInviteUsers = async (data: { users: { name: string, login: string, groupids: string }[] }) => {
    if (!data.users) return null;

    Promise.all(data.users.map(async (item) => {
      const result = await inviteUser({ ...item, groupids: item.groupids.split(','), method: 'mail' });
      if ('data' in result && result.data) return ({ id: result.data.id, name: item.name });
      return null;
    }));
  };

  const handleCreateTemplate: IFoldersEditingProps['onCreate'] = async ({ name }) => {
    const result = await createTemplate({ name });
    if (!('data' in result)) return null;
    return { id: result.data.id, name: result.data.name, translatedName: result.data.translatedName };
  };

  return (
    <AdminLayout
      title={t('service_guide')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('service_guide')}</PageTitle>
      <GuideView
        hasTemplates={onboarding?.edit_template_modal || onboarding?.edit_template_tooltip || onboarding?.edit_template_uploaded_tooltip}
        hasUsers={users && users.items.length > 1}
        currentTariff={currentTariff}
        groups={groups}
        onInvite={handleInviteUsers}
        isInviteLoading={isInviteLoading}
        isInviteSuccess={isInviteSuccess}
        onCreateTemplate={handleCreateTemplate}
      />
    </AdminLayout>
  );
};

export default Guide;
