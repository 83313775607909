import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { PaginationWrapper, PaginationProps, Button } from '@forma/forma-ui-kit';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import ContragentsHeader from './ContragentsHeader';
import { ContragentsListPhysics, ContragentsListJuristic } from './ContragentsList';

import { ICounteragentsItem, ICounteragentType, IDadataItem } from 'interfaces/counteragents.interface';

import styles from './contragents.module.css';

interface ContragentsProps {
  items?: ICounteragentsItem[],
  outside?: IDadataItem[],
  type: ICounteragentType,
  onChangeSearch: (value: string) => void,
  pagination: PaginationProps,
  onClickImport: () => void,
  onRemove: (id: string) => void,
  onClickAdd: () => void,
  onAdd: (attrValues: { [key: string]: string }) => void,
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const Contragents: FC<ContragentsProps> = ({
  items, outside, type, onChangeSearch, pagination, onClickImport, onRemove, onClickAdd, onAdd
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ContragentsHeader type={type} onChangeSearch={onChangeSearch} onClickImport={onClickImport} />
      </div>
      <div className={styles.content}>
        {(items && pagination.count === 0 && !outside?.length && !pagination.isLoading) ? (
          <div>
            <NoItemsVideo
              title={t('you_not_nave_contragents')}
              button={
                <Button
                  icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                  onClick={onClickAdd}
                >
                  {t('add_contragent')}
                </Button>
              }
              src={`${staticUrl}/videos/onboarding/counteragents_no_items.mp4`}
            />
          </div>
        ) : (
          <PaginationWrapper {...pagination} count={pagination?.count || outside?.length || 0}>
            {type === 'person' ? (
              <ContragentsListPhysics
                items={items}
                outside={outside}
                onRemove={onRemove}
                onAdd={onAdd}
              />
            ) : (
              <ContragentsListJuristic
                items={items}
                outside={outside}
                onRemove={onRemove}
                onAdd={onAdd}
              />
            )}
            {pagination.count < 5 && (
              <div className={styles.buttons}>
                <Button
                  className={styles.noItemsButton}
                  icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                  onClick={onClickAdd}
                >
                  {t('add_contragent')}
                </Button>
              </div>
            )}
          </PaginationWrapper>
        )}
      </div>
    </div>
  );
};

export default Contragents;
