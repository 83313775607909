import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import { IPromocode } from 'interfaces/tariffs.interface';

import styles from './promo-input.module.css';

interface IPromoInputProps {
  info: IPromocode|null,
  isError: boolean,
  onSubmit: (code: string) => void,
  onCancel: () => void,
  showButtons: boolean
}

const PromoInput: FC<IPromoInputProps> = ({ info, isError, onSubmit, onCancel, showButtons }) => {
  const { t } = useTranslation();
  const [ value, setValue ] = useState('');
  const [ isShowPromoInput, setShowPromoInput ] = useState(false);

  useEffect(() => {
    const code = localStorage.getItem('promocode');
    if (!code) return;
    setShowPromoInput(true);
    setValue(code);
    onSubmit(code);
    localStorage.removeItem('promocode');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (info?.id && !info?.used) return;
    setShowPromoInput(false);
    setValue('');
    // eslint-disable-next-line
  }, [info]);

  const handleClickSubmit = () => {
    onSubmit(value);
  };

  const handleClickCancel = () => {
    setShowPromoInput(false);
    setValue('');
    onCancel();
  };

  const haveUnusedPromo = (info?.id && (info.reusable || !info.used));

  if (haveUnusedPromo) return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        <input
          className={classNames(styles.input, styles.success)}
          spellCheck={false}
          value={info.id === 'true40' ? '••••••••' : info.id}
          readOnly
        />
        <div className={classNames(styles.notice, styles.success)}>
          {info.description}
        </div>
      </div>
    </div>
  );

  if (!showButtons) return null;

  return (
    <div className={styles.root}>
      {isShowPromoInput ? (
        <>
          <div className={styles.inputWrapper}>
            <input
              className={classNames(styles.input, info && styles.success, isError && styles.error)}
              placeholder={t('subscription.enter_promocode')}
              onChange={e => setValue(e.target.value)}
              spellCheck={false}
              value={value}
            />
            {isError &&
              <div className={classNames(styles.notice, isError && styles.error)}>
                {t('subscription.promocode_not_valid')}
              </div>
            }
          </div>
          {isError ? (
            <Button
              viewStyle="text"
              className={styles.inputButton}
              icon={<ReactSVG src="/icons/close.svg" className={styles.inputButtonIcon} wrapper="span" />}
              onClick={handleClickCancel}
            />
          ) : (
            <Button
              viewStyle="primary"
              className={styles.inputButton}
              icon={<ReactSVG src="/icons/tick.svg" className={styles.inputButtonIcon} wrapper="span" />}
              onClick={handleClickSubmit}
              disabled={value.length < 3}
            />
          )}
        </>
      ) : (
        <button type="button" className={styles.button} onClick={() => setShowPromoInput(true)}>
          {t('subscription.i_have_promocode')}
        </button>
      )}
    </div>
  );
};

export default PromoInput;
