import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, Checkbox, RemoveModal } from '@forma/forma-ui-kit';

import styles from './signature-add.module.css';

interface SignatureItemProps {
  id: string,
  name?: string,
  filePath: string,
  isSelected: boolean,
  onSelect: (id: string) => void,
  onRemove: (id: string) => void
}

const SignatureItem: FC<SignatureItemProps> = ({ id, name, filePath, isSelected, onSelect, onRemove }) => {
  const { t } = useTranslation();
  const [ isShowRemove, setShowRemove ] = useState(false);

  return (
    <div className={styles.signature}>
      <div className={classNames(styles.signatureImageWrap, isSelected && styles.selected)}>
        <Checkbox
          containerClass={styles.signatureCheck}
          name={`select_signature_${id}`}
          checked={isSelected}
        />
        <img
          className={styles.signatureImage}
          src={filePath}
          alt={name}
          onClick={() => onSelect(id)}
        />
        <Button
          className={styles.signatureRemove}
          viewStyle="text"
          icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
          onClick={() => setShowRemove(true)}
        />
      </div>
      <div className={styles.signatureName}>{name}</div>
      <RemoveModal
        open={isShowRemove}
        onClose={() => setShowRemove(false)}
        onRemove={() => onRemove(id)}
        itemName={name ? name : t('file')}
      />
    </div>
  );
};

export default SignatureItem;
