import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActivitiesGrid, Modal } from '@forma/forma-ui-kit';

import { addNotification } from 'store/notifications/notificationsSlice';
import { useGetActivitiesListQuery, useSetWorkspaceDataMutation } from 'store/user/userApi';

import styles from './select-activity-modal.module.css';

interface SelectActivityModalProps {
  selected?: string,
  open: boolean,
  onClose: () => void
}

const SelectActivityModal: FC<SelectActivityModalProps> = ({ open, onClose, selected: defaultSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ selected, setSelected ] = useState<string|undefined>(defaultSelected);

  const [ saveWorkspace, { isLoading: isSaveLoading } ] = useSetWorkspaceDataMutation();
  const { data: activities } = useGetActivitiesListQuery();

  useEffect(() => {
    if (defaultSelected) setSelected(defaultSelected);
  }, [defaultSelected]);

  const handleClickSave = async () => {
    if (!selected) return;

    const result = await saveWorkspace({ areasOfActivityId: selected });
    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  const handleSelectItem = (id: string, checked: boolean) => {
    if (!checked) return;
    setSelected(id);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('account.company_scopes')}
      size="semiLarge"
      buttons={[
        {
          className: styles.buttonSave,
          viewStyle: 'primary',
          children: t('save'),
          onClick: handleClickSave,
          isLoading: isSaveLoading
        }
      ]}
    >
      <ActivitiesGrid
        items={activities}
        selected={selected ? [ selected ] : []}
        onSelect={handleSelectItem}
      />
    </Modal>
  );
};

export default SelectActivityModal;
