import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import ProductsServicesEdit from 'views/products/ProductsServicesEdit';

import { useAppDispatch } from 'store/hooks';
import {
  useAddProductMutation,
  useGetProductByIdQuery,
  useGetProductsCategoriesQuery,
  useSaveProductMutation
} from 'store/products/productsApi';
import { addNotification } from 'store/notifications/notificationsSlice';

import { productTattrs } from 'data/mock';

const ProductsEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: product, isLoading } = useGetProductByIdQuery(id ?? '', { skip: !id });
  const { data: categories } = useGetProductsCategoriesQuery();
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();
  const [ saveProduct, { isLoading: isSaveLoading } ] = useSaveProductMutation();

  const handleSave = async (data: { [key: string]: string }) => {
    const res = (id && product) ? await saveProduct({ ...data, id }) : await addProduct(data);
    if ('data' in res) {
      navigate('/products');
      if (id && product)
        dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
      else
        dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
    }
  };

  let title = '';
  if (!id) title = t('add_product_service');
  else if (product) title = product.name;

  const productData = product && Object.entries(product).reduce((acc, [ key, value ]) => ({
    ...acc,
    [key]: key === 'category' ? value.name : value
  }), {});

  const categoriesValues = categories?.map(({ name }) => name) ?? [];

  const tattrs = productTattrs.map(item => (
    item.id === 'category' && item.values) ?
    { ...item, values: [ ...categoriesValues, ...item.values ].filter((item, pos, arr) => arr.indexOf(item) === pos) } : item
  );

  return (
    <AdminLayout
      title={title}
      breadcrumbs={{ items: [ { name: t('catalog_products_and_services'), to: '/products', as: Link }, { name: title } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + title}</PageTitle>
      <ProductsServicesEdit
        data={productData}
        tattrs={tattrs}
        isLoading={isLoading}
        onSave={handleSave}
        isSaveLoading={isAddLoading || isSaveLoading}
      />
    </AdminLayout>
  );
};

export default ProductsEdit;
