import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelect, SearchInput } from '@forma/forma-ui-kit';

import styles from './documents-header.module.css';

interface DocumentsHeaderProps {
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { fromCreatedAt?: string, toCreatedAt?: string }) => void,
}

const DocumentsHeader: FC<DocumentsHeaderProps> = ({
  onChangeSearch, onChangeFilter
}) => {
  const { t } = useTranslation();

  const handleChangePeriod = (fromCreatedAt: string, toCreatedAt: string) => {
    onChangeFilter({ fromCreatedAt: fromCreatedAt === 'allTime' ? '' : fromCreatedAt, toCreatedAt: toCreatedAt === 'allTime' ? '' : toCreatedAt });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('find_needed_document')}
      />
      <div className={styles.select}>
        <DateSelect onChangeDate={handleChangePeriod} />
      </div>
    </div>
  );
};

export default DocumentsHeader;
