import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';

import { IActivityItem } from 'interfaces/users.interface';
import { ISharedFoldersTreeItem } from 'interfaces/sharedTemplates.interface';

import styles from './static-templates-categories.module.css';

interface StaticTemplatesCategoriesProps {
  isLoading?: boolean,
  items?: ISharedFoldersTreeItem[],
  selected?: string|null,
  categories?: IActivityItem[]
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const StaticTemplatesCategories: FC<StaticTemplatesCategoriesProps> = ({ items, selected, isLoading, categories }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('documents_types')}</div>
      <ul className={styles.list}>
        <li className={classNames(styles.item, styles.withDivider)}>
          <Button
            as={Link}
            viewStyle="text"
            className={classNames(styles.link, !selected && styles.active)}
            iconClassName={styles.linkIcon}
            icon={<ReactSVG src={`${staticUrl}/icons/sharedfolders/all.svg`} wrapper="span" />}
            to="/static-templates"
          >
            {t('all_documents')}
          </Button>
        </li>
        {isLoading ? (
          [...Array(10)].map((_, index) => (
            <li className={classNames(styles.item, 'skeleton-loader')} key={index} />
          ))
        ) : (
          items?.map(({ id, name }) => (
            <li className={styles.item} key={id}>
              <Button
                as={Link}
                viewStyle="text"
                className={classNames(styles.link, selected === id && styles.active)}
                iconClassName={styles.linkIcon}
                icon={<ReactSVG src={`${staticUrl}/icons/sharedfolders/${id}.svg`} wrapper="span" />}
                to={`/static-templates/${id}`}
              >
                {name}
              </Button>
            </li>
          ))
        )}
      </ul>
      <div className={styles.title}>{t('areas')}</div>
      <ul className={styles.list}>
        {isLoading ? (
          [...Array(10)].map((_, index) => (
            <li className={classNames(styles.item, 'skeleton-loader')} key={index} />
          ))
        ) : (
          categories?.map(({ id, name }) => (
            <li className={styles.item} key={id}>
              <Button
                as={Link}
                viewStyle="text"
                className={classNames(styles.link, selected === id && styles.active)}
                iconClassName={styles.linkIcon}
                icon={<ReactSVG src={`${staticUrl}/icons/sharedcategories/${id}.svg`} wrapper="span" />}
                to={`/static-templates/categories/${id}`}
              >
                {name}
              </Button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default StaticTemplatesCategories;
