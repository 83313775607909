import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, FileDropzone } from '@forma/forma-ui-kit';
import DocumentEdit from 'views/SafeDocumentsList/DocumentEdit';

import { useAppDispatch } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import {
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useAddAttachmentMutation,
} from 'store/attachments/attachmentsApi';

// import styles from './document-modal.module.css';

interface DocumentCreateModalProps {
  open: boolean,
  onClose: (open: boolean) => void,
  parentId?: string|null
}

const DocumentCreateModal: FC<DocumentCreateModalProps> = ({
  open, onClose, parentId
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ mode, setMode ] = useState<'upload'|'edit'>('upload');

  const userPermissions = useSelector(selectUserPermissions);

  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [ addAttachment, { isLoading: isAddLoading } ] = useAddAttachmentMutation();
  const [ createCategory, { isLoading: isCreateCategoryLoading } ] = useCreateAttachmentCategoryMutation();

  const [ file, setFile ] = useState<File|null>(null);

  useEffect(() => {
    if (userPermissions && !userPermissions.includes('attachments_add')) {
      dispatch(addNotification({ content: t('attachments.permissions_edit_error'), type: 'ERROR' }));
      navigate('/safe');
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  useEffect(() => {
    if (open) {
      setFile(null);
      setMode('upload');
    }
  }, [open]);

  const handleCreateAttachment = async (params: { [key: string]: string|string[] }) => {
    if (!file) return null;

    const formData = new FormData();

    formData.append('file', file);
    for (const key of Object.keys(params)) {
      const value = params[key];
      if (value) {
        if (typeof value === 'object' && Array.isArray(value)) {
          value.forEach((item, index) => formData.append(`${key}[${index}]`, item));
        } else {
          formData.append(key, value);
        }
      }
    }
    if (parentId) formData.append('parentid', parentId);

    const result = await addAttachment(formData);
    if (result && ('data' in result)) {
      onClose(false);
      navigate(`/safe/${result.data.id}`);
    }
  };

  const handleAddFiles = (files: File[]) => {
    setFile(files[0]);
    setMode('edit');
  };

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    return createCategory(data).then(result => {
      return ('data' in result) ? result.data : null;
    }).catch(e => null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      viewStyle="right"
      width="100%"
      maxWidth="570px"
    >
      {mode === 'upload' && (
        <FileDropzone
          title={t('adding_new_document')}
          onChange={handleAddFiles}
          maxFiles={1}
        />
      )}
      {mode === 'edit' && (
        <DocumentEdit
          name={file?.name.split('.')[0]}
          onSave={handleCreateAttachment}
          onCancel={() => setMode('upload')}
          isSaveLoading={isAddLoading}
          categories={{
            items: categories,
            onCreate: handleCreateCategory,
            isCreateLoading: isCreateCategoryLoading
          }}
        />
      )}
    </Modal>
  );
};

export default DocumentCreateModal;
