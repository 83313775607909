import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './logo.module.css';

type TLogoProps = {
  showText?: boolean,
  className?: string,
  textClassName?: string,
  link: string
};

const Logo = ({ showText, className, textClassName, link }: TLogoProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Link to={link} className={classNames(styles.link, className)}>
      <div className={styles.imageWrap}>
        <img
          className={styles.image}
          src="/images/small_logo.svg"
          alt={t('main_company_name') ?? ''}
        />
        <ReactSVG
          className={styles.imageHover}
          src="/images/small_logo_animated.svg"
        >
          {t('main_company_name') ?? ''}
        </ReactSVG>
      </div>
      {showText &&
        <ReactSVG
          className={classNames(styles.imageText, textClassName)}
          src={`/images/title_${i18n.resolvedLanguage ? i18n.resolvedLanguage : i18n.language}.svg`}
          wrapper="span"
        />
      }
    </Link>
  );
};

export default Logo;