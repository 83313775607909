import { FC } from 'react';
import StaticTemplatesListItem, { StaticTemplatesListItemSkelet } from './StaticTemplatesListItem';

import { ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';

import styles from './static-templates-list.module.css';

interface StaticTemplatesListProps {
  isLoading?: boolean,
  searchValue?: string,
  items?: ISharedTemplatesItem[],
  onClickAdd: (id: string) => Promise<string|null>,
  addedTemplates: { id: string, translatedName: string }[],
}

const StaticTemplatesList: FC<StaticTemplatesListProps> = ({
  items, searchValue, isLoading, onClickAdd, addedTemplates
}) => {
  return (
    <div className={styles.list}>
      {isLoading ? (
        [...Array(12)].map((_, index) => (
          <StaticTemplatesListItemSkelet key={index} />
        ))
      ) : (
        items?.map(item => (
          <StaticTemplatesListItem
            {...item}
            searchValue={searchValue}
            onClickAdd={onClickAdd}
            addedName={addedTemplates.find(({ id }) => id === item.id)?.translatedName}
            key={item.id}
          />
        ))
      )}
    </div>
  );
};

export default StaticTemplatesList;
