import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';
import { ITariffItem } from 'interfaces/tariffs.interface';


import styles from './tariff-total.module.css';

interface TariffTotalProps {
  tariff?: ITariffItem,
  members: number,
  selectedDuration: number
}

const coeff = 8;
const getTotal = (price: number, users: number, userPrice: number) => {
  return (users && userPrice) ? price + userPrice*users : price;
};

const TariffTotal: FC<TariffTotalProps> = ({ tariff, members, selectedDuration }) => {
  const { t } = useTranslation();
  const currency = currencies.ru;

  const duration = tariff?.patterns.find(({ duration }) => duration === selectedDuration);
  const durationMonths = duration ? duration.duration / 30 : 0;
  const durationBasePrice = duration ? duration.basePrice / durationMonths : 0;
  const durationPrice = duration ? duration.price / durationMonths : 0;
  const durationUserPrice = duration?.userPrice ? duration.userPrice / durationMonths : 0;
  const maxUserPrice = tariff ?
    Math.max(...tariff?.patterns.map(({ duration, userPrice }) => userPrice ? userPrice / (duration / 30) : 0)) : 0;

  const [ total, setTotal ] = useState(getTotal(duration?.price ?? 0, members, duration?.userPrice ?? 0));

  useEffect(() => {
    const newTotal = getTotal(duration?.price ?? 0, members, duration?.userPrice ?? 0);
    if (newTotal === total) return;
    const diff = newTotal-total;

    const interval = setInterval(() => {
      setTotal(prev => {
        let step = 0;
        if (diff === 0) clearInterval(interval);
        else if (diff > 0) step = prev+diff/coeff > newTotal ? newTotal-prev : diff/coeff;
        else step = prev+diff/coeff < newTotal ? newTotal-prev : diff/coeff;

        return prev + step;
      });
    }, 50);
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [duration, members]);

  return (
    <div className={styles.total}>
      <div className={styles.totalTitle}>{t('subscription.total_price')}</div>
      {!!(duration && (selectedDuration > 30 || (tariff?.canAddUsers && duration?.userPrice && members))) && (
        <div className={styles.totalDescr}>
          {formatPrice(durationPrice, currency)} / {t('subscription.per_month')} * {durationMonths} {!!(tariff?.canAddUsers && duration?.userPrice && members) && '+'}

          {!!(tariff?.canAddUsers && duration?.userPrice && members) && (
            <>
              <br />
              {formatPrice(durationUserPrice, currency)} / {t('subscription.per_month')} * {durationMonths} * {members}
              <br />
            </>
          )}

           = <span className={duration.discount && styles.strike}>
            {formatPrice(durationBasePrice*durationMonths + (maxUserPrice ?? 0)*members*durationMonths, currency)}
          </span>
        </div>
      )}
      <div className={styles.totalValue}>
        {formatPrice(total, currency)}
      </div>
    </div>
  );
};

export default TariffTotal;
