import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Quantity } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { IPromocode, ITariffItem } from 'interfaces/tariffs.interface';

import { currencies } from 'data/mock';

import styles from './tariffs-list.module.css';

interface TariffsListItemProps extends ITariffItem {
  disabled?: boolean,
  limitDisabled?: boolean,
  isCurrent?: boolean,
  isSelected?: boolean,
  selectedDuration: number,
  onSelectTariff: (id: string) => void,
  selectedMembers: number,
  onChangeMembers: (value: number) => void,
  promocode?: IPromocode|null
}

const TariffsListItem: FC<TariffsListItemProps> = ({
  id, name, canAddUsers, patterns, usersLimit, isPromo, isCurrent, disabled, limitDisabled, advantages,
  isSelected, onSelectTariff, selectedDuration, selectedMembers, onChangeMembers, promocode
}) => {
  const { t } = useTranslation();
  const currency = currencies.ru;

  const duration = patterns.find(({ duration }) => duration === selectedDuration);
  const durationDiscount = promocode?.discountPercent || duration?.discount || 0;
  const durationMonths = duration ? duration.duration / 30 : 0;
  const durationBasePrice = duration ? duration.basePrice / durationMonths : 0;
  const durationPrice = duration ? (durationBasePrice/100*(100-durationDiscount)) : 0;
  // const durationPrice = duration ? duration.price / durationMonths : 0;
  const durationUserPrice = (duration?.userPrice && canAddUsers) ? duration.userPrice / durationMonths : 0;
  const maxUserPrice = Math.max(...patterns.map(({ duration, userPrice }) => userPrice ? userPrice / (duration / 30) : 0));

  const handleSelectTariff = () => {
    onSelectTariff(id);
  };

  return (
    <div className={classNames(styles.item, isSelected && styles.selected, disabled && styles.disabled)}>
      {durationUserPrice ? (
        <div className={styles.additional}>
          <div className={styles.additionalMain}>
            <div className={styles.additionalTitle}>
              {t('subscription.total_count_licences_for_payment')}: <span className={styles.additionalValue}>{usersLimit + selectedMembers}</span>
            </div>
            <div>
              {t('subscription.one_licence_one_account')}
            </div>
          </div>
          <div className={styles.additionalCount}>
            <Quantity value={selectedMembers} onChange={onChangeMembers} disabled={limitDisabled} />
          </div>
          <div className={styles.additionalDescr}>
            {t('subscription.additional_accounts_over_tariff')} + {formatPrice(durationUserPrice, currency)} / {t('month')}
          </div>
        </div>
      ) : null}
      <div
        className={classNames(styles.content, isPromo && styles.promo)}
        onClick={handleSelectTariff}
        tabIndex={0}
      >
        {isCurrent && (
          <div className={styles.label}>{t('subscription.your_tariff')}</div>
        )}
        {!!durationDiscount && (
          <div className={styles.discount}>
            <span className={styles.discountValue}>{durationDiscount}%</span>
          </div>
        )}
        {isPromo &&
          <div className={styles.promoLabel}>
            <span className={styles.promoLabelValue}>{t('subscription.secret_tariff')}</span>
          </div>
        }
        <div className={styles.info}>
          <div className={styles.name}>{name}</div>
          {!!durationPrice && (
            durationDiscount ? (
              <>
                <div className={styles.oldPrice}>{formatPrice(durationBasePrice, currency)}</div>
                {isPromo ? (
                  <>
                    <div className={styles.price}>
                      {formatPrice(durationBasePrice, currency)}
                      <ReactSVG src="/icons/fire.svg" className={styles.priceIcon} wrapper="span" />
                    </div>
                    <div className={styles.price}>
                      {t('subscription.for_count_months', { count: durationMonths })}
                    </div>
                  </>
                ) : (
                  <div className={styles.price}>
                    {formatPrice(durationPrice, currency)} / {t('subscription.per_month')}
                  </div>
                )}
                {!isPromo && (
                  <div className={styles.priceDescr}>
                    {t('subscription.economy_price_for_payment', {
                      price: formatPrice(
                        durationBasePrice*durationMonths - durationPrice*durationMonths
                        + selectedMembers*maxUserPrice*durationMonths - selectedMembers*durationUserPrice*durationMonths,
                        currency
                      )
                    })} {t('count_months', {
                      count: durationMonths
                    })}
                  </div>
                )}
              </>
            ) : (
              <div className={styles.price}>
                {formatPrice(durationPrice, currency)} / {t('subscription.per_month')}
              </div>
            )
          )}
        </div>
        <div className={styles.features}>
          {advantages?.map((item, index) => (
            <div className={styles.feature} key={index}>
              <ReactSVG src="/icons/tick.svg" className={styles.featureIcon} wrapper="span" />
              <span className={styles.featureText}>{item}</span>
            </div>
          ))}
        </div>
        <ReactSVG src="/icons/checked.svg" className={styles.checkbox} />
      </div>
    </div>
  );
};

export default TariffsListItem;
