import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { AdminLayout } from 'components/Layouts';
import { LoadingButton } from '@forma/forma-ui-kit';
import getDateFromString from 'helpers/getDateFromString';
import SaveContragentsTable from './SaveContragentsTable';

import { selectUserData } from 'store/user/userSlice';
import { selectSaveTable } from 'store/counteragents/counteragentsSlice';
import { useAddCounteragentsMutation } from 'store/counteragents/counteragentsApi';

import styles from './SaveContragents.module.css';
import { ICounteragent, ICounteragentType } from 'interfaces/counteragents.interface';

const SaveContragents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { header, type, lengthContragents, data } = useSelector(selectSaveTable);
  const profile = useSelector(selectUserData);
  const [ isCheckHeader, setCheckHeader ] = useState(false);
  const [ addCounteragents, { isLoading } ] = useAddCounteragentsMutation();

  useEffect(() => {
    if (!lengthContragents || !profile?.id) navigate(`/contragents`);
    // eslint-disable-next-line
  }, [lengthContragents]);

  useEffect(() => {
    const checkHeader = header && header.find((head) => !head.tattrsNumber);
    setCheckHeader(!!checkHeader);
  }, [header]);

  const onSendContragents = async () => {
    if (!profile) return null;

    const result = data.map((row) => {
      const entity: Omit<ICounteragent, 'id'> = {
        type: type as ICounteragentType,
        selfowned: true,
        favorite: false,
        attrValues: {}
      };

      header.forEach((attribute, indexHeader) => {
        const { tattrsNumber } = attribute;
        const value = row[indexHeader];
        if (attribute.type === 'date') {
          const date = getDateFromString(value);
          if (date) entity.attrValues[tattrsNumber] = format(date, 'yyyy-MM-dd');
        } else if (value) {
          entity.attrValues[tattrsNumber] = value;
        }
      });

      return entity;
    });

    const res = await addCounteragents(result);
    if ('data' in res) navigate(`/contragents?type=${type}`);
  };

  return (
    <div className="styled-scrollbar">
      <AdminLayout
        title={t('will_add_from_file')}
        breadcrumbs={{ items: [{ name: t('contragents'), to: '/contragents', as: Link }, { name: t('will_add_from_file') } ] }}
      >
        <h2 className={styles.header}>{t(`contragents_types.${type}`)}</h2>
        {header && <SaveContragentsTable />}
      </AdminLayout>
      <div className={styles.wrapperAddButton}>
        <LoadingButton
          viewStyle="primary"
          onClick={onSendContragents}
          size="large"
          isLoading={isLoading}
          disabled={isCheckHeader}
        >
          {`${t('add')} ${lengthContragents}  ${lengthContragents > 1? t('contragentof').toLowerCase():t('contragent').toLowerCase()}`}
        </LoadingButton>
      </div>
    </div>
  );
};

export default SaveContragents;
