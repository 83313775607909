import { IPaymentData } from 'interfaces/billing.interface';

interface IPaymentService {
  widget: any,
  init: () => void,
  pay: (payData: IPaymentData, onInit: () => void, onSuccess: () => void, onError: () => void) => void,
  destroy: () => void
}

const paymentService: IPaymentService = {
  widget: null,
  init: function() {
    if (document.getElementById('payment_s')) return;

    const script = document.createElement('script');
    script.id = 'payment_s';
    script.src = 'https://widget.cloudpayments.ru/bundles/paymentblocks.js';
    document.head.appendChild(script);
  },
  pay: function(payData, onInit, onSuccess, onError) {
    this.widget = new window.cp.PaymentBlocks(
      payData,
      {
        appearance: {
          colors: {
            primaryButtonColor: '#79be70',
            primaryButtonTextColor: '#FFFFFF',
            primaryHoverButtonColor: '#6cb56c',
            primaryButtonHoverTextColor: '#FFFFFF',
            activeInputColor: '#161616',
            inputBackground: '#FFFFFF',
            inputColor: '#969c9e',
            inputBorderColor: '#E2E8EF',
            errorColor: '#EB5757'
          },
          borders: {
            radius: '16px'
          }
        },
        components: {
          paymentButton: {
            text: 'Оплатить',
            fontSize: '16px'
          },
          paymentForm: {
            labelFontSize: '16px',
            activeLabelFontSize: '12px',
            fontSize: '16px'
          }
        }
      }
    );

    this.widget.mount(document.getElementById('modal_pay_content'));
    this.widget.on('init', onInit);
    this.widget.on('success', onSuccess);
    this.widget.on('fail', onError);
    this.widget.on('destroy', () => {
      this.widget = null;
    });
  },
  destroy: function() {
    this.widget?.unmount();
  }
};

export default paymentService;
