import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';
import { MainLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';

import styles from './Account.module.css';

const ApproveSuccess = () => {
  const { t } = useTranslation();

  return (
    <MainLayout title={t('account.approve_success_title')}>
      <PageTitle>{t('site_name') + ' – ' + t('register')}</PageTitle>
      <div className={styles.success}>
        <div className={styles.successDescription}>
          {t('account.approve_success_subtitle')}
        </div>
      </div>
      <ButtonsContainer className={styles.buttonsContainer}>
        <Button as={Link} to="/login" fullWidth shadow>{t('continue')}</Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default ApproveSuccess;
