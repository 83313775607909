import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import { checkIsActive } from '../checkIsActive';
import { TMenuItemParent } from '../SideMenu';

import styles from '../side-menu.module.css';

type TMenuItemProps = {
  currentPathname: string,
  onClick: (event: React.MouseEvent<Element>) => void,
  className?: string,
} & TMenuItemParent;

const MenuItem = ({
  id, href, target, lang, icon, items, disabled, currentPathname, routes, onClick, className
}: TMenuItemProps) => {
  const { t } = useTranslation();

  const isActive = checkIsActive(currentPathname, href, routes);
  const isSubmenuActive = items ? items.findIndex(({ href, routes }) => checkIsActive(currentPathname, href, routes)) !== -1 : false;

  return (
    <li className={classNames(styles.item, className)}>
      <Link
        id={`side_menu_button_${id}`}
        className={classNames(
          styles.link,
          (isActive || isSubmenuActive) && styles.active,
          (disabled && !items) && styles.disabled
        )}
        onClick={onClick}
        target={target}
        to={href}
      >
        <ReactSVG src={icon} className={styles.icon} wrapper="span" />
        <span>{t(lang)}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
