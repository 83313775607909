import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Modal } from '@forma/forma-ui-kit';
import DocumentInfo, { DocumentInfoSkelet } from 'views/SafeDocumentsList/DocumentInfo';
import DocumentEdit, { DocumentEditSkelet } from 'views/SafeDocumentsList/DocumentEdit';
import copyToClipboard from 'helpers/copyToClipboard';
import webView from 'helpers/webview';

import { useAppDispatch } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import {
  useGetAttachmentCategoriesQuery,
  useGetAttachmentByIdQuery,
  useUpdateAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery,
  useRemoveAttachmentMutation,
  useCreateAttachmentCategoryMutation,
} from 'store/attachments/attachmentsApi';

import styles from './document-modal.module.css';

interface DocumentModalProps {
  id?: string,
  open: boolean,
  onClose: (open: boolean) => void,
  onClickAdd: (id: string) => void,
}

const DocumentModal: FC<DocumentModalProps> = ({
  id, open, onClose, onClickAdd
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ mode, setMode ] = useState<'edit'|'view'>('view');
  const [ isShowModalRelevant, setShowModalRelevant ] = useState(false);

  const userPermissions = useSelector(selectUserPermissions);

  const { data: attachment, isFetching: isLoading } = useGetAttachmentByIdQuery(id, { skip: !id });
  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [ removeAttachment ] = useRemoveAttachmentMutation();
  const [ updateAttachment, { isLoading: isUpdateLoading } ] = useUpdateAttachmentMutation();
  const [ createCategory, { isLoading: isCreateCategoryLoading } ] = useCreateAttachmentCategoryMutation();

  const [ downloadAttachment, { isFetching: isDownloadLoading } ] = useLazyDownloadAttachmentQuery();
  const [ downloadAttachmentsArchive ] = useLazyDownloadAttachmentsArchiveQuery();

  useEffect(() => {
    if (id) setMode('view');
  }, [id]);

  useEffect(() => {
    if (userPermissions && !userPermissions.includes('attachments_view')) {
      dispatch(addNotification({ content: t('attachments.permissions_view_error'), type: 'ERROR' }));
      navigate('/templates');
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  const handleCopyLink = (id: string) => {
    const origin = window.location.origin;
    const res = copyToClipboard(`${origin}/safe/${id}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };

  const handleRemove = async (id: string) => {
    if (!userPermissions?.includes('attachments_delete')) {
      dispatch(addNotification({ content: t('attachments.permissions_delete_error'), type: 'ERROR' }));
      return;
    }
    const result = await removeAttachment(id);
    if (result && 'data' in result) {
      dispatch(addNotification({ content: t('file_deleted_successfully'), type: 'ERROR' }));
      navigate('/safe');
    }
  };

  const handleDowloadFiles = (ids: string[]) => {
    if (!userPermissions?.includes('attachments_download')) {
      dispatch(addNotification({ content: t('attachments.permissions_download_error'), type: 'ERROR' }));
      return;
    }
    if (ids.length === 1) {
      const fileName = attachment.name;

      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownload(ids[0], fileName ?? 'Document');
      } else {
        downloadAttachment(ids[0]).unwrap().then((url: string) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName ?? 'Document';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {});
      }
    } else {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownloadArchive(ids, 'Documents.zip');
      } else {
        downloadAttachmentsArchive({ ids }).unwrap().then((url: string) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {});
      }
    }
  };

  const handleSaveAttachment = (data: { [ key: string ]: string|string[] }) => {
    if (!userPermissions?.includes('attachments_add')) {
      dispatch(addNotification({ content: t('attachments.permissions_edit_error'), type: 'ERROR' }));
      return;
    }

    updateAttachment({ id: attachment.id, ...data }).then(() => {
      setMode('view');
    });
  };

  const handleOutOfDate = () => {
    if (!userPermissions?.includes('attachments_add')) {
      dispatch(addNotification({ content: t('attachments.permissions_edit_error'), type: 'ERROR' }));
      return;
    }

    updateAttachment({ id, inactive: format(new Date(), 'yyyy-MM-dd') });
  };

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    if (!userPermissions?.includes('attachments_add')) {
      dispatch(addNotification({ content: t('attachments.permissions_edit_error'), type: 'ERROR' }));
      return null;
    }

    return createCategory(data).then(result => {
      return ('data' in result) ? result.data : null;
    }).catch(e => null);
  };

  return (
    <Modal
      open={open}
      onClose={open => {
        onClose(open);
        setMode('view');
      }}
      viewStyle="right"
      width="100%"
      maxWidth="570px"
      buttonsClassName={styles.buttons}
      buttons={(mode === 'view' && !isLoading && !attachment?.inactive) ? [{
        viewStyle: 'tertiary',
        children: t('make_invalidity_doc'),
        size: 'small',
        className: styles.modalButton,
        onClick: () => setShowModalRelevant(true),
        closing: false
      }] : undefined}
    >
      {mode === 'view' && (
        (isLoading || !attachment) ? (
          <DocumentInfoSkelet />
        ) : (
          <DocumentInfo
            data={attachment}
            onClickEdit={() => setMode('edit')}
            onClickAddSubitem={onClickAdd}
            onClickCopyLink={handleCopyLink}
            onClickDownload={handleDowloadFiles}
            isDownloadLoading={isDownloadLoading}
            onRemove={handleRemove}
          />
        )
      )}
      {mode === 'edit' && (
        (isLoading || !attachment) ? (
          <DocumentEditSkelet />
        ) : (
          <DocumentEdit
            data={attachment}
            onSave={handleSaveAttachment}
            onCancel={() => setMode('view')}
            isSaveLoading={isUpdateLoading}
            categories={{
              items: categories,
              onCreate: handleCreateCategory,
              isCreateLoading: isCreateCategoryLoading
            }}
          />
        )
      )}

      <Modal
        size="small"
        open={isShowModalRelevant}
        onClose={() => setShowModalRelevant(false)}
        buttons={[
          {
            children: t('yes'),
            viewStyle: 'tertiary',
            onClick: () => handleOutOfDate()
          },
          {
            children: t('no'),
            viewStyle: 'tertiary'
          }
        ]}
        title={t('out_of_date_document_question')}
      >
        <div className={styles.confirmModal}>
          {t('do_yo_want_out_of_date_file')}
          <br />
          <br />
          {t('cancellation_notification')}
        </div>
      </Modal>
    </Modal>
  );
};

export default DocumentModal;
