import { useEffect, useState, Fragment, FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, LoadingIndicator, ThemeContext } from '@forma/forma-ui-kit';
import { Grid, GridItem } from 'components/ItemsGrid';
import { TemplateItem } from 'views/templatesPacks/TemplatesPackEdit/TemplateItem';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import PacksHeader from './PacksHeader';
import { PackItem, PackItemAdd, Placeholder } from './PackItem';
import NoItems from './NoItems';

import { ITemplatesPack, ITemplatesPackTemplate } from 'interfaces/templates.interface';

import styles from './templates-packs.module.css';

const preloadImages = (images: string[] = []) => {
  if (!images.length) return null;
  images.map(src => {
    const image = new Image();
    image.src = src;
    return image;
  });
};

const sizes = {
  desktop: 5,
  desktopM: 5,
  desktopS: 5,
  tablet: 4,
  tabletS: 3,
  phone: 2,
};

interface TemplatesPacksProps {
  items?: ITemplatesPack[],
  active: string|null,
  isLoading: boolean,
  templates?: ITemplatesPackTemplate[],
  templatesIsLoading: boolean,
  search: {
    isLoading: boolean,
    results?: ITemplatesPack[],
    onChange: (value: string) => void,
  },
  navigation: {
    onShowContent: (id: string) => void,
    onOpenPack: (id: string) => void,
    onToggleMenu?: (id: string|null) => void
  },
  actions: {
    onRemove: (id: string) => void,
    onRename: (data: ITemplatesPack) => void,
  }
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const TemplatesPacks: FC<TemplatesPacksProps> = ({
  items, active, isLoading, templates, templatesIsLoading, search, navigation, actions
}) => {
  let toShowGroup = false;
  const { t } = useTranslation();
  const [ columns, setColumns ] = useState<number>(0);
  const { viewport } = useContext(ThemeContext);

  useEffect(() => {
    preloadImages([ '/icons/explorer/templates_pack_hover.svg' ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setColumns(sizes[viewport]);
    // eslint-disable-next-line
  }, [viewport]);

  return (
    <div className={styles.root}>
      <PacksHeader
        search={search}
        navigation={navigation}
        actions={actions}
      />
      {!isLoading && !items?.length && (
        <NoItemsVideo
          title={t('you_not_created_any_templates_packs')}
          src={`${staticUrl}/videos/onboarding/packs_no_items.mp4`}
        />
      )}
      <Grid columns={5} id="packs_grid_list" className={styles.grid}>
        {isLoading ? (
          [...Array(10).keys()].map(key => (
            <GridItem className={styles.item} key={key}>
              <Placeholder />
            </GridItem>
          ))
        ) : (
          !!items?.length && (
            items.map(({ id, name, ...rest }, index) => {
              const isLastOnRow = ((index + 1) % columns === 0) && index !== 0;
              if (active === id) toShowGroup = true;
              const showGroup = ((active === id || toShowGroup) && (isLastOnRow || index + 1 === items.length));
              if (showGroup) toShowGroup = false;

              return (
                <Fragment key={id}>
                  <GridItem className={styles.item}>
                    <PackItem
                      id={id}
                      name={name}
                      {...rest}
                      actions={actions}
                      navigation={navigation}
                    />
                  </GridItem>
                  {showGroup &&
                    <GridItem className={styles.group}>
                      {!templatesIsLoading && !templates?.length && (
                        <NoItems />
                      )}
                      {templatesIsLoading ? (
                        <div className={styles.groupLoader}>
                          <LoadingIndicator />
                        </div>
                      ) : (
                        !!templates?.length && (
                          <>
                            <Grid columns={5} className={styles.groupItems}>
                              {templates.map(({ id, name, path }) => (
                                <GridItem className={styles.item} key={id}>
                                  <TemplateItem
                                    id={id}
                                    name={name}
                                    folderName={(path && path.length) ? path[path.length-1].name : undefined}
                                  />
                                </GridItem>
                              ))}
                              {active && (
                                <GridItem className={styles.item}>
                                  <PackItemAdd id={active} />
                                </GridItem>
                              )}
                            </Grid>
                            <ButtonsContainer className={styles.groupButtons}>
                              <Button
                                as={Link}
                                viewStyle="secondary"
                                shadow
                                to={`/fill-templates-pack/${active}`}
                              >
                                {t('create_documents')}
                              </Button>
                              <Button
                                as={Link}
                                viewStyle="secondary"
                                shadow
                                to={`/templates-packs/${active}`}
                              >
                                {t('edit_templates_pack')}
                              </Button>
                            </ButtonsContainer>
                          </>
                        )
                      )}
                    </GridItem>
                  }
                </Fragment>
              );
            })
          )
        )}
      </Grid>
    </div>
  );
};

export default TemplatesPacks;
