import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LoadingButton, Input, Tags, FieldContainer, ButtonsContainer, Button } from '@forma/forma-ui-kit';

import { categories_colors } from 'data/mock';
import { IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './categories-select.module.css';
import { ReactSVG } from 'react-svg';

interface SelectCategoriesProps {
  items?: IAttachmentCategoriesItem[],
  selected: string[],
  onSubmit: (items: string[]) => void,

  isCreate: boolean,
  onClickCreate: () => void,
  onCreate: (data: { name: string, color: string }) => Promise<IAttachmentCategoriesItem|null>,
  isCreateLoading: boolean
}

const SelectCategories: FC<SelectCategoriesProps> = ({
  items, selected: defaultSelected = [], onSubmit,
  isCreate, onClickCreate, onCreate, isCreateLoading,
}) => {
  const { t } = useTranslation();
  const [ newName, setName ] = useState<string>('');
  const [ newColor, setColor ] = useState<string>('');
  const [ selected, setSelected ] = useState<string[]>(defaultSelected);

  const handleClickCategory = (id: string) => {
    if (selected.includes(id)) {
      setSelected(prev => {
        const next = [ ...prev ];
        next.splice(next.findIndex(itemId => itemId === id));
        return next;
      });
    } else {
      setSelected(prev => [ ...prev, id ]);
    }
  };

  const handleCreateCategory = async () => {
    const result = await onCreate(({ name: newName, color: newColor }));
    if (result) {
      setSelected(prev => [ ...prev, result.id ]);
      setName('');
      setColor('');
    }
  };

  if (isCreate) return (
    <div className={styles.fields}>
      <Input
        id="category_name"
        name="category_name"
        viewStyle="secondary"
        label={t('creating_new_category')}
        placeholder={t('enter_category_name')}
        value={newName}
        onChange={e => setName(e.target.value)}
      />
      <FieldContainer
        id="category_color"
        label={t('select_color_for_category')}
      >
        <div className={styles.colors}>
          {Object.keys(categories_colors).map(name => (
            <div
              className={classNames(styles.color, newColor === name && styles.selected)}
              style={{ borderColor: categories_colors[name].background, background: categories_colors[name].color }}
              onClick={() => setColor(name)}
              key={categories_colors[name].background+categories_colors[name].color}
            />
          ))}
        </div>
      </FieldContainer>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          viewStyle="primary"
          className={classNames(styles.button, styles.saveButton)}
          size="small"
          disabled={newName.length < 3 || !newColor}
          onClick={handleCreateCategory}
          isLoading={isCreateLoading}
        >
          {t('save')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );

  return (
    <div className={styles.fields}>
      <FieldContainer
        id="categories"
        label={t('select_categories_which_you_want_add')}
      >
        <Tags
          items={items?.map(({ id, name, color }) => (
            {
              name: name,
              color: categories_colors[color]?.color,
              backgroundColor: categories_colors[color]?.background,
              active: selected.includes(id),
              onClick: () => handleClickCategory(id)
            }
          ))}
        />
      </FieldContainer>
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="tertiary"
          className={styles.button}
          size="small"
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          onClick={() => onClickCreate()}
        >
          {t('create_new_category')}
        </Button>
      </ButtonsContainer>
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="primary"
          className={classNames(styles.button, styles.saveButton)}
          size="small"
          onClick={() => onSubmit(selected)}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default SelectCategories;
