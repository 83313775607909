import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Button, LoadingButton, Modal, Input, RemoveModal } from '@forma/forma-ui-kit';

import { IProductsCategoriesItem } from 'interfaces/products.interface';

import {
  useAddProductsCategoryMutation,
  useGetProductsCategoriesQuery,
  useRemoveProductsCategoryMutation,
  useUpdateProductsCategoryMutation
} from 'store/products/productsApi';

import styles from './products-categories-edit-modal.module.css';

interface ProductsCategoriesEditModalProps {
  open: boolean,
  onClose: () => void
}

interface ProductsCategoriesEditProps extends IProductsCategoriesItem {
  onChange: (data: { id: string, name: string }) => void,
  onRemove: (id: string) => void
}

const ProductsCategoryEdit: FC<ProductsCategoriesEditProps> = ({ id, name: defaultName, onChange, onRemove }) => {
  const { t } = useTranslation();
  const [ isEdit, setEdit ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>(defaultName);

  const handleSubmit = () => {
    onChange({ id, name });
    setEdit(false);
  };

  if (isEdit) return (
    <div className={styles.editItem}>
      <Input
        className={styles.editInput}
        name="new_category"
        placeholder={t('new_category')}
        onChange={e => setName(e.target.value)}
        value={name}
      />
      <Button
        viewStyle="primary"
        iconClassName={styles.submitButtonIcon}
        icon={<ReactSVG src="/icons/tick.svg" wrapper="span" />}
        className={styles.submitButton}
        onClick={handleSubmit}
        disabled={name.length < 3}
      />
    </div>
  );

  return (
    <div className={styles.editItem}>
      <div className={styles.editName}>{name}</div>
      <RemoveModal
        control={
          <Button
            viewStyle="textLight"
            title={t('delete')}
            className={styles.deleteButton}
            icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
          />
        }
        title={t('delete_category')}
        onRemove={() => onRemove(id)}
        itemName={name}
      />
      <Button
        viewStyle="textLight"
        className={styles.editButton}
        icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
        onClick={() => setEdit(true)}
      />
    </div>
  );
};

const ProductsCategoriesEditModal: FC<ProductsCategoriesEditModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState<string>('');
  const { data: categories } = useGetProductsCategoriesQuery();
  const [ addCategory, { isLoading: isAddLoading } ] = useAddProductsCategoryMutation();
  const [ updateCategory ] = useUpdateProductsCategoryMutation();
  const [ removeCategory ] = useRemoveProductsCategoryMutation();

  const handleAddCategory = () => {
    addCategory({ name });
    setName('');
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={onClose}
      title={t('add_new_category')}
    >
      <div className={styles.root}>
        <div className={styles.add}>
          <Input
            className={styles.input}
            name="new_category"
            placeholder={t('new_category')}
            onChange={e => setName(e.target.value)}
            value={name}
          />
          <LoadingButton
            viewStyle="primary"
            onClick={handleAddCategory}
            isLoading={isAddLoading}
            disabled={name.length < 3}
          >
            {t('add_category')}
          </LoadingButton>
        </div>
        <div className={styles.title}>{t('editing_categories')}</div>
        <div className={styles.edit}>
          {categories?.map(item => (
            <ProductsCategoryEdit {...item} onChange={updateCategory} onRemove={removeCategory} key={item.id} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ProductsCategoriesEditModal;
