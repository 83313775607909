import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import update from 'immutability-helper';
import { Button } from '@forma/forma-ui-kit';
import { DndContext, DragEndEvent, useSensors, useSensor, MouseSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ITemplateVariation } from 'interfaces/templates.interface';

import styles from './variations-list.module.css';

interface VariationsListProps {
  items: ITemplateVariation[],
  onClickItem: (item: ITemplateVariation) => void,
  onChange: (items: ITemplateVariation[]) => void,
}

interface VariationsListItemProps extends ITemplateVariation {
  index: number,
  onClick: () => void,
}

const VariationsListItem: FC<VariationsListItemProps> = ({ id, title, onClick, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef: sortRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id, data: { index } });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0
  };

  return (
    <div className={styles.item} {...attributes} {...listeners} style={style} ref={sortRef}>
      <Button
        className={styles.button}
        viewStyle="secondary"
        onClick={onClick}
        size="small"
        fullWidth
        shadow
      >
        {title}
        <span className={styles.handler}>
          <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
        </span>
      </Button>
    </div>
  );
};

const VariationsList: FC<VariationsListProps> = ({ items, onClickItem, onChange }) => {
  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: {
      distance: 3,
    },
  }));

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = active.data.current?.index;
      const newIndex = over.data.current?.index;

      onChange(
        update(items, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, items[oldIndex]]
          ]
        })
      );
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className={styles.items}>
          {items.map((item, index) => (
            <VariationsListItem
              {...item}
              index={index}
              onClick={() => onClickItem(item)}
              key={item.id}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default VariationsList;
