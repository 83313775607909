import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import ContragentEdit from 'views/contragents/ContragentEdit';
import { contragent_types } from 'data/mock';

import { useAppSelector } from 'store/hooks';
import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery,
  useGetUserVariablesQuery
} from 'store/schemas/schemasApi';
import { ICounteragentType } from 'interfaces/counteragents.interface';
import { selectUserPermissions } from 'store/user/userSlice';

import styles from './ContragentCreateModal.module.css';

interface ContragentCreateModalProps {
  open: boolean,
  onClose: (isOpen: boolean) => void,
  type: ICounteragentType,
  onAdd: (attrValues: { [key: string]: string }) => void,
  isAddLoading?: boolean
}

const ContragentCreateModal: FC<ContragentCreateModalProps> = ({ open, onClose, type, onAdd, isAddLoading = false }) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const { data: variablesSections, isLoading: isSectionsLoading } = useGetTemplateSectionsGroupsQuery();
  const { data: availableVariables, isLoading: isVarsLoading } = useGetTemplateSectionsVariablesQuery();
  const { data: userVariables, isLoading: isUserVarsLoading } = useGetUserVariablesQuery();

  const [ isFormValid, setFormValid ] = useState<boolean>(false);

  const handleSave = async (values: { [key: string]: string }) => {
    onAdd(values);
  };

  return (
    <Modal
      width="95%"
      maxWidth="1220px"
      open={open}
      onClose={onClose}
      title={t(`${type}_person`)}
      buttons={[
        {
          type: 'submit',
          form: 'contragent_edit_form',
          children: t('add_contragent'),
          viewStyle: 'primary',
          className: styles.buttonModal,
          disabled: !isFormValid,
          size: 'large',
          closing: false
        }
      ]}
    >
      <ContragentEdit
        isLoading={isSectionsLoading || isVarsLoading || isUserVarsLoading}
        type={contragent_types[type]}
        variablesGroups={variablesSections}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onSave={handleSave}
        isSaveLoading={isAddLoading}
        canDeleteVariables={userPermissions?.includes('users')}
        onChangeValid={setFormValid}
        showSubmitButton={false}
      />
    </Modal>
  );
};

export default ContragentCreateModal;