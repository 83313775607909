import { FC, ReactElement } from 'react';
import { useDroppable } from '@dnd-kit/core';

interface DropItemProps {
  id: string|null,
  dropId: string,
  name: string,
  type: 'folder'|'template',
  className: string,
  children?: ReactElement
}

const DropItem: FC<DropItemProps> = ({ id, dropId, name, type, className, children }) => {
  const { setNodeRef: dropRef } = useDroppable({
    id: dropId,
    data: { id, name, type },
    disabled: type !== 'folder' || id === 'main' || id === 'packs'
  });

  return (
    <div className={className} ref={dropRef}>
      {children}
    </div>
  );
};

export default DropItem;
