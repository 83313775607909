import { FC } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import styles from './time-items.module.css';
import { ITimeslotsItem } from 'interfaces/requests.inerface';

interface TimeItemsProps {
  utc: { utc: string },
  slots?: ITimeslotsItem[],
  value: string|null,
  onChange: (id: string, date: string) => void
}

const TimeItems: FC<TimeItemsProps> = ({ utc, slots, value, onChange }) => {
  return (
    <div className={styles.root}>
      {slots?.map(({ id, date, available }) => (
        <div
          className={classNames(styles.item, !available && styles.disabled, value === id && styles.selected)}
          onClick={() => onChange(id, date)}
          tabIndex={0}
          key={id}
        >
          {moment(date).utcOffset(utc.utc).format('HH:mm')}
        </div>
      ))}
    </div>
  );
};

export default TimeItems;
