import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TariffSelect from 'views/tariffs/TariffsSelect';
import paymentService from 'helpers/paymentService';

import {
  useGetCurrentTariffQuery,
  useGetTariffsQuery,
  useSetPromocodeMutation,
  useLazyCheckPromocodeQuery,
  useGetCurrentPromocodeQuery
} from 'store/tariffs/tariffsApi';
import { IPromocode } from 'interfaces/tariffs.interface';
import { useGetAcquiringSubscriptionQuery } from 'store/billing/billingApi';

// const TRIAL_ID = 'tariff_trial';

const SelectTariff = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [ promoData, setPromoData ] = useState<IPromocode|null>(null);
  const [ isPromoError, setPromoError ] = useState<boolean>(false);

  const { data: tariffs } = useGetTariffsQuery();
  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ getPromocodeInfo ] = useLazyCheckPromocodeQuery();

  const [ setPromocode ] = useSetPromocodeMutation();
  const { data: subscription } = useGetAcquiringSubscriptionQuery();
  const { data: currentPromo } = useGetCurrentPromocodeQuery();

  useEffect(() => {
    if (currentPromo) setPromoData(currentPromo);
  }, [currentPromo]);

  useEffect(() => {
    paymentService.init();
  }, []);

  const handleSubmitPromo = async (code: string) => {
    const result = await getPromocodeInfo(code);
    if ('data' in result && result.data) {
      setPromoData(result.data);
      await setPromocode(code);

      // if (currentTariff && currentTariff.tariff.id !== TRIAL_ID)
      //   await setTariff({
      //     tariffId: currentTariff.tariff.id,
      //     patternId: currentTariff.patternId,
      //     addUsersCount: currentTariff.addUsersAmount
      //   });
    }
    else setPromoError(true);
  };

  const handleCancelPromo = () => {
    setPromoData(null);
    setPromoError(false);
  };

  return (
    <AdminLayout
      title={t('subscription.selecting_tariff')}
      breadcrumbs={{ items: [ { name: t('settings'), to: '/profile', as: Link }, { name: t('subscription.selecting_tariff') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('subscription.selecting_tariff')}</PageTitle>
      <TariffSelect
        current={currentTariff}
        tariffs={tariffs}
        subscription={subscription}
        paymentOpen={!!state?.paymentOpen}
        defaultMethod={state?.paymentMethod}
        promo={{
          info: promoData,
          isError: isPromoError,
          onSubmit: handleSubmitPromo,
          onCancel: handleCancelPromo,
        }}
      />
    </AdminLayout>
  );
};

export default SelectTariff;
