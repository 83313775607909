import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { mobileMenu } from 'data/menu';
import { checkIsActive } from './checkIsActive';

import styles from './bottom-menu.module.css';

const BottomMenu = () => {
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();

  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {mobileMenu.map(({ id, href, lang, routes, icon }) => {
          const isActive = checkIsActive(pathname+hash, href, routes);
          return (
            <li className={styles.item} key={id}>
              <Link
                className={classNames(styles.link, isActive && styles.active)}
                to={href}
              >
                <ReactSVG src={icon} className={styles.icon} wrapper="span" />
                <span>{t(lang)}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BottomMenu;
