import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import { IFoldersEditingProps, IFoldersFavouritesProps } from 'interfaces/fileExplorer.interface';

import CreateFolderModal from '../CreateFolderModal';
import CreateTemplateModal from '../CreateTemplateModal';

import styles from './no-items.module.css';

interface NoItemsProps {
  id?: string|null,
  onCreate?: IFoldersEditingProps['onCreate'],
  favourites?: IFoldersFavouritesProps
}

const NoItems: FC<NoItemsProps> = ({ id, onCreate, favourites }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {onCreate ? t('empty_folder_description_create') : t('empty_folder_description')}
      </div>
      {favourites?.isOpen ? (
        <div className={styles.buttons}>
          <Button
            as={favourites.href ? Link : 'button'}
            to={favourites.href}
            onClick={favourites.onClick}
            viewStyle="secondary"
            shadow
          >
            {t('return_to_templates')}
          </Button>
        </div>
      ) : (
        onCreate && (
          <div className={styles.buttons}>
            <CreateTemplateModal
              onCreate={onCreate}
              parentid={id}
              control={
                <Button
                  id="create_template"
                  viewStyle="secondary"
                  icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
                  data-testid={`create_template`}
                  shadow
                >
                  {t('create_template')}
                </Button>
              }
            />
            <CreateFolderModal
              onCreate={onCreate}
              parentid={id}
              control={
                <Button
                  id="create_folder"
                  viewStyle="secondary"
                  icon={<ReactSVG src="/icons/folder-thin.svg" wrapper="span" />}
                  shadow
                >
                  {t('create_folder')}
                </Button>
              }
            />
          </div>
        )
      )}
    </div>
  );
};

export default NoItems;
