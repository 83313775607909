import { useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import StaticTemplates from 'views/StaticTemplatess';

import {
  useCopySharedTemplateMutation,
  useGetSharedFoldersTreeQuery
} from 'store/sharedTemplates/sharedTemplatesApi';
import { useCreateTemplateMutation } from 'store/templates/templatesApi';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { useGetActivitiesListQuery } from 'store/user/userApi';
import { ISharedFoldersTreeItem } from 'interfaces/sharedTemplates.interface';

const searchInTree = (folders: ISharedFoldersTreeItem[], searchId: string): ISharedFoldersTreeItem|null => {
  for (const folder of folders) {
    if (folder.id === searchId) return folder;
    else if (folder.children) {
      const res = searchInTree(folder.children, searchId);
      if (res) return res;
    }
  }
  return null;
};

const Templates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [ searchParams ] = useSearchParams();
  const filterId = searchParams.get('filterId');

  const { data: sharedFolders, isLoading } = useGetSharedFoldersTreeQuery();
  const { data: categories } = useGetActivitiesListQuery();
  const [ copySharedTemplate ] = useCopySharedTemplateMutation();
  const [ createTemplate ] = useCreateTemplateMutation();

  const mainCategory = (id && sharedFolders) ?
    sharedFolders.find(folder => (folder.id === id) || (folder.children && searchInTree(folder.children, id)))
    : undefined;

  const mainCategoryFolders = mainCategory ? mainCategory?.children : sharedFolders;
  const mainCategoryName = mainCategory ? mainCategory?.name : t('all_documents');

  const filteredCategory = filterId ?
    mainCategoryFolders?.find(folder => (folder.id === filterId) || (folder.children && searchInTree(folder.children, filterId)))
    : undefined;

  const templates = filterId ? filteredCategory?.templates : mainCategory?.templates;
  const folders = filterId ? filteredCategory?.children : mainCategoryFolders;


  useEffect(() => {
    if (!sharedFolders) return;
    if (id && !mainCategory) navigate('/404');
    else if (filterId && !filteredCategory) navigate('/404');

    // eslint-disable-next-line
  }, [id, filterId, sharedFolders]);

  // if (!sharedFolders) return null;

  const handleClickCopyTemplate = async (id: string) => {
    const result = await copySharedTemplate({ id });
    if ('data' in result && result.data) return result.data.translatedName;
    return null;
  };

  const handleCreateTemplate: IFoldersEditingProps['onCreate'] = async ({ name }) => {
    const result = await createTemplate({ name });
    if (!('data' in result)) return null;
    return { id: result.data.id, name: result.data.name, translatedName: result.data.translatedName };
  };

  return (
    <AdminLayout
      title={t('my_templates')}
      breadcrumbs={{ items: [ { as: Link, name: t('my_templates'), to: '/templates' }, { name: t('forma_templates') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <StaticTemplates
        isLoading={isLoading}
        filterId={filteredCategory?.id}
        mainFolder={{ id: mainCategory?.id, name: mainCategoryName, folders: mainCategoryFolders }}
        categories={categories}
        folders={folders}
        templates={templates}
        tree={sharedFolders}
        onClickAddTemplate={handleClickCopyTemplate}
        onCreateTemplate={handleCreateTemplate}
        showFilters
      />
    </AdminLayout>
  );
};

export default Templates;
