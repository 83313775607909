import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import classNames from 'classnames';
import { IPromocode, ITariff, ITariffDuration } from 'interfaces/tariffs.interface';

import styles from './tariff-subscription.module.css';

interface TariffSubscriptionProps {
  mode: 'view'|'change'|'await',
  tariffName?: string,
  patterns?: ITariffDuration[],
  current?: ITariff,
  selectedDuration: number,
  onSelectDuration: (duration: number) => void,
  promocode?: IPromocode|null
}

const TariffSubscription: FC<TariffSubscriptionProps> = ({
  mode, tariffName, patterns, current, selectedDuration, onSelectDuration, promocode
}) => {
  const { t } = useTranslation();

  const tariffEnd = (mode === 'view' && current) ? new Date(current.dtTo) : new Date().setDate(new Date().getDate() + selectedDuration);

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{t('subscription.subscription')}</h3>
      <div className={styles.description}>
        {t('subscription.tariff')} {tariffName} {t('subscription.for')} {t('count_months', { count: selectedDuration/30 })} <br />
        ({t('subscription.until')} {format(new Date(tariffEnd), 'dd.MM.yyyy')})
      </div>
      {patterns && (
        <div className={styles.durations}>
          {patterns.map(({ duration, discount }) => (
            <button
              className={classNames(styles.duration, selectedDuration === duration && styles.active, (['view', 'await'].includes(mode) && selectedDuration !== duration) && styles.disabled)}
              onClick={() => onSelectDuration(duration)}
              key={duration}
            >
              {duration && (
                <span className={styles.durationText}>
                  {duration === 360 ? t('count_years', { count: 1 }) : t('count_months', { count: duration / 30 })}
                </span>
              )}
              {discount ? (
                <span className={styles.durationDiscount}>
                  {promocode?.discountPercent || discount}% {t('subscription.discount')}
                </span>
              ) : null}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TariffSubscription;
