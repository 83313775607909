import { IFacsimileItem } from 'interfaces/facsimiles.interface';
import { baseApi } from '../store';

export const facsimilesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getFacsimiles: builder.query<IFacsimileItem[], void>({
      query: () => ({
        url: '/facsimiles/',
        method: 'GET'
      }),
      providesTags: ['Facsimiles'],
    }),
    addFacsimile: builder.mutation<IFacsimileItem, FormData>({
      query: data => ({
        url: '/facsimiles/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Facsimiles'],
    }),
    removeFacsimile: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/facsimiles/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Facsimiles'],
    }),
  })
});

export const {
  useGetFacsimilesQuery,
  useAddFacsimileMutation,
  useRemoveFacsimileMutation,
} = facsimilesApi;
