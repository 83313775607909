import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';
import VariableSelectModal from '../VariableSelectModal';

import { ITemplateSide } from 'interfaces/templates.interface';
import { IUserVariableItem, IVariableItem, IVariablesHierarhyItem } from 'interfaces/variables.interface';

import styles from './variable-add.module.css';

interface VariableAddModalProps {
  open: boolean,
  onClose: () => void,
  sides: ITemplateSide[],
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  onChangeVar: (side: { id: string, name: string }, varData: IVariableItem, checked: boolean, group?: { id: string, name: string }) => void,
  onAddVar: (side: { id: string, name: string }, varData: IVariableItem, checked: boolean, group?: { id: string, name: string }) => void,
  onRemoveVar: (side: { id: string, name: string }, id: string) => void,
}

const VariableAddModal: FC<VariableAddModalProps> = ({
  open, onClose, sides, variablesGroups, availableVariables, userVariables, onChangeVar, onAddVar, onRemoveVar
}) => {
  const { t } = useTranslation();
  const [ side, setSide ] = useState<ITemplateSide|null>(null);

  useEffect(() => {
    if (!open) setSide(null);
  }, [open]);

  const handleToggleVar = (data: IVariableItem, checked: boolean, group?: { id: string, name: string }) => {
    if (!side) return;
    onAddVar({ id: side.id, name: side.name }, data, checked, group);
  };

  const handleChangeVar = (data: IVariableItem) => {
    if (!side) return;
    onChangeVar({ id: side.id, name: side.name }, data, true);
  };

  const handleRemoveVar = (id: string) => {
    if (!side) return;
    onRemoveVar({ id: side.id, name: side.name }, id);
  };

  return (
    <>
      <Modal
        size="small"
        open={open}
        title={<span>{t('add_variabe_to_template')}:<br />{t('select_side')}</span>}
        onClose={onClose}
      >
        <ButtonsContainer className={styles.buttons}>
          {sides.map(side => (
            <Button
              viewStyle="tertiary"
              onClick={() => setSide(side)}
              fullWidth
              key={side.id}
            >
              {side.name}
            </Button>
          ))}
        </ButtonsContainer>
      </Modal>
      <VariableSelectModal
        open={!!side}
        selectedVariables={side?.tattrFolders &&
          side.tattrFolders.reduce((acc: string[], current) => [ ...acc, ...Object.values(current.tattrs).map(({ id }) => id) ], [])
        }
        onClose={() => setSide(null)}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onToggleVar={handleToggleVar}

        onChange={handleChangeVar}
        onRemove={handleRemoveVar}
      />
    </>
  );
};

export default VariableAddModal;
