import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import { Button } from '@forma/forma-ui-kit';

import styles from './ErrorPage.module.css';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <MainLayout title={t('page_not_found')}>
      <PageTitle>{t('site_name') + ' – ' + t('page_not_found')}</PageTitle>
      <div className={styles.title}></div>
      <img src="/images/404.png" className={styles.image} alt={t('page_not_found')} />
      <div className={styles.description}>
        <div>
          {t('page_not_found_description')}
        </div>
        <div>
          {t('page_not_found_description_2')}
        </div>
      </div>
      <div className={styles.description}>
        <Button as={Link} to="/" size="medium" shadow={true}>{t('go_to_homepage')}</Button>
      </div>
    </MainLayout>
  );
};

export default NotFound;
