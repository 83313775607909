import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, SearchInput, LoadingIndicator } from '@forma/forma-ui-kit';
import { Grid, GridItem } from 'components/ItemsGrid';
import { PackItem } from '../PackItem';

import { ITemplatesPack } from 'interfaces/templates.interface';

import styles from './packs-header.module.css';

interface PacksHeaderProps {
  search: {
    isLoading: boolean,
    results?: ITemplatesPack[],
    onChange: (value: string) => void,
  },
  navigation: {
    onShowContent: (id: string) => void,
    onOpenPack: (id: string) => void,
    onToggleMenu?: (id: string|null) => void
  },
  actions: {
    onRemove: (id: string) => void,
    onRename: (data: ITemplatesPack) => void,
  }
}

const PacksHeader: FC<PacksHeaderProps> = ({ search, navigation, actions }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <SearchInput
          onSearch={search.onChange}
          className={classNames(styles.search, search.results && styles.active)}
          placeholder={t('find_templates_packs')}
        />
        <Button
          as={Link}
          id="create_new_pack_button" // id for onboarding
          className={styles.button}
          to="/templates-pack-create"
          viewStyle="secondary"
          shadow
          icon={<ReactSVG src="/icons/file-settings.svg" wrapper="span" />}
        >
          {t('create_new_pack')}
        </Button>
      </div>

      {search.results &&
        <div className={styles.searchResults}>
          {search.isLoading ? (
            <div className={styles.loader}>
              <LoadingIndicator color="var(--color-white-primary)" />
            </div>
          ) : (
            search.results.length ? (
              <Grid columns={5}>
                {search.results.map(({ id, ...rest }) => (
                  <GridItem className={styles.item} key={'search_' + id}>
                    <PackItem id={id} {...rest} navigation={{ ...navigation, onShowContent: navigation.onOpenPack }} actions={actions} />
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <div className={styles.searchNoItems}>
                {t('search_nothing_found')}
              </div>
            )
          )}
        </div>
      }
    </div>
  );
};

export default PacksHeader;
