import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import packageInfo from '../package.json';

export const defaultLocale = 'en';
export const locales = ['ru', 'en'];

const webAppDetector = {
  name: 'webAppDetector',

  lookup() {
    return window?.navigator?.userAgent === 'forma-mobile' ? 'ru' : undefined;
  }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(webAppDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLocale,
    detection: {
      order: ['webAppDetector', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    },
    // defaultLocale: defaultLocale,
    // locales: locales,
    supportedLngs: locales,
    ns: ['main', 'uikit'],
    defaultNS: 'main',
    returnNull: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + packageInfo?.version
    },
    react: {
      transWrapTextNodes: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b']
    }
  }, (err, t) => { /* resources are loaded */ });

export default i18n;
