import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Button, Input } from '@forma/forma-ui-kit';

import styles from './dropdown-input.module.css';

interface DropdownInputProps {
  index: number,
  onChange: (value: string) => void,
  value: string,
  onDelete: () => void
}

const DropdownInput: FC<DropdownInputProps> = ({ index, onChange, value, onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Input
        name={`variable_${index}`}
        className={styles.input}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('input_variant_text')}
        value={value}
      />
      {onDelete && (
        <Button
          viewStyle="text"
          className={styles.delete}
          iconClassName={styles.deleteIcon}
          title={t('delete')}
          icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export default DropdownInput;
