import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Button, Modal, Input, LoadingIndicator, Switcher, Textarea, RemoveModal } from '@forma/forma-ui-kit';
import { IUserVariableItem, IVariablesHierarhyItem, TVariableType } from 'interfaces/variables.interface';
import {
  useGetTemplateVariablesTypesQuery,
  useUpdateUserVariableMutation,
  useCreateUserVariableMutation,
  useRemoveUserVariableMutation
} from 'store/schemas/schemasApi';

import Dropdown from '../Dropdown';
import SelectSwitcher from '../SelectSwitcher';
import DropdownInput from '../DropdownInput';

import styles from './variable-create-modal.module.css';

interface VariableCreateModalProps {
  sectionId: number|null,
  data?: IUserVariableItem|null,
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  onChange?: (data: IUserVariableItem, id: boolean) => void,
  onAdd: (data: IUserVariableItem) => void,
  onRemove?: (id: string) => void,
  onClose: () => void
}

interface UserVariableState extends Omit<IUserVariableItem, 'type'> {
  type: IUserVariableItem['type']|''
}

const VariableCreateModal: FC<VariableCreateModalProps> = ({
  data, sectionId, variablesGroups: groups, onChange, onAdd, onRemove, onClose
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage ? i18n.resolvedLanguage : i18n.language;
  const [ variable, setVariable ] = useState<UserVariableState>(data || {
    id: '', name: '', type: '', lang: language, sections: sectionId ? [ sectionId ] : []
  });
  const [ isShowDescription, setShowDescription ] = useState<boolean>(!!data?.description);
  const [ showingSection, setShowingSection ] = useState<'folder'|'section'|'type'|null>(null);
  const [ isOpenRemove, setOpenRemove ] = useState<boolean>(false);

  const { data: variablesTypes } = useGetTemplateVariablesTypesQuery();
  const [ createUserVariable, { isLoading: isCreateLoading } ] = useCreateUserVariableMutation();
  const [ updateUserVariable, { isLoading: isUpdateLoading } ] = useUpdateUserVariableMutation();
  const [ removeUserVariable ] = useRemoveUserVariableMutation();

  const handleChangeName = (value: string) => {
    setVariable(prev => ({ ...prev, name: value }));
  };

  const handleChangeDescription = (value: string) => {
    setVariable(prev => ({ ...prev, description: value }));
  };

  const handleSelectSection = (sectionId: number) => {
    setVariable(prev => ({ ...prev, sections: [ sectionId ], folderid: undefined }));
    setShowingSection(null);
  };

  const handleSelectFolder = (groupId: string) => {
    setVariable(prev => ({ ...prev, folderid: groupId }));
    setShowingSection(null);
  };

  const handleSelectType = (type: TVariableType) => {
    setVariable(prev => {
      const data = { ...prev };
      data.type = type;
      if (type === 'list') data.values = [];
      else delete(data.values);
      return (data);
    });
    setShowingSection(null);
  };

  const handleChangeListItem = (index: number, value: string) => {
    setVariable(prev => {
      const values = [ ...prev.values ];
      values.splice(index, 1, value);
      return ({ ...prev, values: values });
    });
  };

  const handleAddListItem = () => {
    setVariable(prev => ({ ...prev, values: [ ...prev.values, '' ] }));
  };

  const handleDeleteListItem = (index: number) => {
    setVariable(prev => {
      const values = [ ...prev.values ];
      values.splice(index, 1);
      return ({ ...prev, values: values });
    });
  };

  const handleChangeMultiple = (checked: boolean) => {
    setVariable(prev => ({ ...prev, multiple: checked }));
  };

  const handleClickSubmit = async () => {
    if (data) {
      updateUserVariable(variable)
        .unwrap()
        .then(result => { if (onChange) onChange(result, true); onClose(); });
    } else {
      createUserVariable(variable)
        .unwrap()
        .then(result => { if (onAdd) onAdd(result); onClose(); });
    }
  };

  const handleRemove = () => {
    removeUserVariable(variable.id);
    if (onRemove) onRemove(variable.id);
    onClose();
  };

  if (!groups) return null;

  const selectedSection = Object.values(groups).find(({ id }) => id === variable.sections[0]);
  const selectedFolder = variable.folderid ? selectedSection?.groups.find(({ groupId }) => groupId === variable.folderid) : undefined;

  const isSaveDisabled = !(variable.name?.length > 2 && variable.sections && variable.type && variable.folderid);

  return (
    <Modal
      size="medium"
      open={true}
      title={data ? t('editing_variable') : t('creating_variable')}
      onClose={onClose}
      buttons={data ? [
        {
          children: t('delete'),
          viewStyle: 'danger',
          closing: false,
          onClick: () => setOpenRemove(true),
        },
        {
          children: t('save'),
          viewStyle: 'primary',
          disabled: isSaveDisabled,
          isLoading: isUpdateLoading,
          closing: false,
          onClick: handleClickSubmit
        }
      ] : [
        {
          children: t('save'),
          viewStyle: 'primary',
          disabled: isSaveDisabled,
          isLoading: isCreateLoading,
          closing: false,
          onClick: handleClickSubmit,
          'data-testid': 'submit_create_variable'
        }
      ]}
    >
      <div className={styles.modalSelect}>
        <Input
          type="text"
          id="variable_name"
          name="variable_name"
          placeholder={t('set_variable_name')}
          viewStyle="secondary"
          className={styles.modalInput}
          onChange={(e) => handleChangeName(e.target.value)}
          showClearButton
          value={variable.name}
          maxLength={42}
          data-testid="variable_name"
        />

        <Switcher
          id="variable_add_description"
          name="variable_add_description"
          label={t('add_description')}
          size="small"
          containerClass={styles.modalSwitcher}
          onChange={(e) => setShowDescription(e.target.checked)}
          checked={isShowDescription}
          data-testid="variable_add_description"
        />

        {isShowDescription && (
          <Textarea
            id="variable_description"
            name="variable_description"
            placeholder={t('variable_description')}
            viewStyle="secondary"
            onChange={(e) => handleChangeDescription(e.target.value)}
            value={variable.description}
            maxLength={300}
            data-testid="variable_description"
            showLength
          />
        )}

        <Dropdown
          id="variable_section" // id for tests
          open={showingSection === 'section'}
          onToggle={(open) => setShowingSection(open ? 'section' : null)}
          title={selectedSection ? `${t('variable_section')}: ${selectedSection.name}` : t('select_variable_section')}
          viewStyle={!selectedSection && 'empty'}
        >
          <div className={styles.types}>
            {Object.values(groups).map(({ id, scope, name }) => (
              <Button
                className={styles.typesButton}
                onClick={() => handleSelectSection(id)}
                viewStyle="secondary"
                size="small"
                shadow
                key={id}
                data-testid={`variable_section_${id}`}
              >
                {name}
              </Button>
            ))}
          </div>
        </Dropdown>

        <Dropdown
          id="variable_group" // id for tests
          open={showingSection === 'folder'}
          onToggle={(open) => setShowingSection(open ? 'folder' : null)}
          title={selectedFolder ? `${t('variable_group')}: ${selectedFolder.name}` : t('select_variable_group')}
          viewStyle={!selectedFolder && 'empty'}
        >
          <div className={styles.types}>
            {selectedSection?.groups?.map(({ groupId, name }) => (
              <Button
                className={styles.typesButton}
                onClick={() => handleSelectFolder(groupId)}
                viewStyle="secondary"
                size="small"
                shadow
                key={groupId}
                data-testid={`variable_group_${groupId}`}
              >
                {name}
              </Button>
            ))}
          </div>
        </Dropdown>

        <Dropdown
          id="variable_type" // id for tests
          open={showingSection === 'type'}
          onToggle={(open) => setShowingSection(open ? 'type' : null)}
          title={variable.type ? `${t('field_type')}: ${variablesTypes?.[variable.type].name || ''}` : t('select_field_type')}
          viewStyle={!variable.type && 'empty'}
        >
          <div className={styles.types}>
            {variablesTypes ? (
              (Object.keys(variablesTypes) as Array<TVariableType>).map(id => (
                <Button
                  className={styles.typesButton}
                  onClick={() => handleSelectType(id)}
                  viewStyle="secondary"
                  size="small"
                  disabled={!!data}
                  shadow
                  key={id}
                  data-testid={`variable_type_${id}`}
                >
                  {variablesTypes[id].name}
                </Button>
              ))
            ) : (
              <LoadingIndicator />
            )}
          </div>
        </Dropdown>

        {variable.type === 'list' && (
          <Dropdown title={`${t('list')}: ${t('list_values')}`}>
            <div className={styles.types}>
              <SelectSwitcher
                id="multiple"
                name="is_multiple"
                label={t('can_select_multiply_values')}
                checked={!!variable.multiple}
                onChange={() => handleChangeMultiple(!variable.multiple)}
              />

              <div style={{ marginTop: '10px' }} />

              {variable.values?.map((value: string, index: number) => (
                <DropdownInput
                  index={index}
                  onChange={(value) => handleChangeListItem(index, value)}
                  onDelete={() => handleDeleteListItem(index)}
                  value={value}
                  key={index}
                />
              ))}
              <Button
                className={styles.addOptionButton}
                viewStyle="secondary"
                size="small"
                icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                onClick={handleAddListItem}
                shadow
              >
              </Button>
            </div>
          </Dropdown>
        )}

        {/* <div className={styles.modalButtons}>
          <LoadingButton
            viewStyle="primary"
            size="small"
            onClick={handleClickSubmit}
            disabled={!(variable.name?.length > 2 && variable.sections && variable.type && variable.folderid)}
            isLoading={isCreateLoading || isUpdateLoading}
            fullWidth
          >
            {t('save')}
          </LoadingButton>
        </div> */}
      </div>
      <RemoveModal
        open={isOpenRemove}
        onClose={() => setOpenRemove(false)}
        title={t('deleting_variable')}
        onRemove={handleRemove}
        itemName={variable.name}
      />
    </Modal>
  );
};

export default VariableCreateModal;
