import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { selectUserPermissions } from 'store/user/userSlice';

import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './user-groups.module.css';

interface UserGroupsProps {
  groups?: IUserGroupsItem[],
  selected?: IUserGroupsItem[],
  onChange?: (data: { [key: string]: boolean }) => void
}

const UserGroups: FC<UserGroupsProps> = ({ groups, selected, onChange }) => {
  const [ selectedItems, setSelectedItems ] = useState<{ [key: string]: boolean }>({});
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeUsers = userPermissions?.includes('users');

  useEffect(() => {
    if (selected?.length) {
      const _items = selected.reduce((prev, { id }) => ({ ...prev, [id]: true }), {});
      setSelectedItems(_items);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (canChangeUsers && onChange) onChange(selectedItems);
    // eslint-disable-next-line
  }, [selectedItems]);

  const handleChangeSelected = (id: string, checked: boolean) => {
    if (canChangeUsers) setSelectedItems(prev => ({ ...prev, [id]: checked }));
  };

  if (!groups) return (
    <div className={styles.root} id="user_groups_change">
      {[...Array(3)].map((item, index) => <label className={classNames(styles.item, 'skeleton-loader')} key={index} />)}
    </div>
  );

  return (
    <div className={styles.root} id="user_groups_change">
      {groups.map(({ id, name }) => {
        if (!canChangeUsers && !selectedItems[id]) return null;

        return (
          <label
            className={classNames(styles.item, !!selectedItems[id] && styles.active, canChangeUsers && styles.changing)}
            tabIndex={0}
            key={id}
          >
            <span className={styles.text}>{name}</span>
            <ReactSVG className={styles.icon} src="/icons/checked.svg" />
            <input className={styles.checkbox} type="checkbox" checked={!!selectedItems[id]} onChange={e => handleChangeSelected(id, e.target.checked)} />
          </label>
        );
      })}
    </div>
  );
};

export default UserGroups;