import { FC, ReactNode } from 'react';
import Video from 'components/Video';

import styles from './no-items-video.module.css';

interface NoItemsVideoProps {
  title: ReactNode,
  button?: ReactNode,
  src: string
}

const NoItemsVideo: FC<NoItemsVideoProps> = ({ title, src, button }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      {button && <div className={styles.buttons}>{button}</div>}
      <Video className={styles.video} src={src} />
    </div>
  );
};

export default NoItemsVideo;
