import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';

import timezones from 'data/timezones.json';

import styles from './timezone-select.module.css';

interface UTCProps {
  utc: string,
  city: string
}

interface ModalContentProps {
  searchValue: string,
  onChangeSearch: (value: string) => void,
  onClickItem: (utc: UTCProps) => void
}

interface TimezoneSelectProps {
  value: UTCProps,
  onChange: (utc: UTCProps) => void
}

const ModalContent: FC<ModalContentProps> = ({ searchValue, onChangeSearch, onClickItem }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dropdown}>
      <input
        placeholder={t('search')}
        className={styles.search}
        onChange={e => onChangeSearch(e.target.value.replace(/[-/\\^$*+?.()|[\]{}]/g, ''))}
        value={searchValue}
      />
      <div className={classNames(styles.select, 'styled-scrollbar')}>
        {Object.values(timezones)
          .filter(({ utc, city }) => (
            !!`UTC ${utc} ${city}`.match(new RegExp(searchValue, 'i'))
          ))
          .map(({ utc, city }, index) => (
            <div className={styles.item} onClick={() => onClickItem({ utc, city })} tabIndex={0} key={index}>
              <span dangerouslySetInnerHTML={{
                __html: `UTC ${utc} ${city}`.replace(new RegExp(searchValue, 'i'), `<span class="accent-text">$&</span>`)
              }} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

const TimezoneSelect: FC<TimezoneSelectProps> = ({ value = {}, onChange }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ open, setOpen ] = useState(false);
  const [ searchValue, setSearchValue ] = useState('');

  const handleClickItem = (utc: UTCProps) => {
    onChange(utc);
    setOpen(false);
  };

  const current = Object.values(timezones).find(({ utc, city }) => value.city ? value.city === city : value.utc === utc) ?? timezones[0];

  if (viewport !== 'phone') return (
    <div className={styles.root}>
      <Popup
        trigger={<button type="button" className={styles.button}>UTC {current.utc} {current.city}</button>}
        className="time-dropdown"
        on={['click']}
        offsetY={0}
        offsetX={0}
        arrow={false}
        position="bottom left"
        closeOnDocumentClick
        nested
      >
        {close => (
          <ModalContent
            searchValue={searchValue}
            onChangeSearch={setSearchValue}
            onClickItem={value => { handleClickItem(value); close(); }}
          />
        )}
      </Popup>
    </div>
  );

  return (
    <div className={styles.root}>
      <button type="button" className={styles.button} onClick={() => setOpen(!open)}>
        UTC {current.utc} {current.city}
      </button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={t('timezone')}
        buttons={[{
          className: styles.modalButton,
          viewStyle: 'primary',
          children: t('next')
        }]}
      >
        <ModalContent
          searchValue={searchValue}
          onChangeSearch={setSearchValue}
          onClickItem={handleClickItem}
        />
      </Modal>
    </div>
  );
};

export default TimezoneSelect;
