import { ICounteragentsItem } from '@forma/forma-ui-kit';
import { ICounteragent, ICounteragentType } from 'interfaces/counteragents.interface';

export const contragentTypes: ICounteragentType[] = [ 'entity', 'individual', 'person' ];

export const innIndividualLength = 12;

export const parseOrgData = (data: { [key: string]: any }) => {
  return ({
    101: data.fio?.surname || data.forma_fio?.surname || data.management?.name?.split(' ')[0],
    102: data.fio?.name || data.forma_fio?.name || data.management?.name?.split(' ')[1],
    103: data.fio?.patronymic || data.forma_fio?.patronymic || data.management?.name?.split(' ')[2],
    302: data.inn.length !== innIndividualLength ? data.ogrn : undefined,
    304: data.name?.full_with_opf || data.name?.full || undefined,
    305: data.name?.short_with_opf || data.name?.short || undefined,
    306: data.address?.unrestricted_value || data.address?.value || undefined,
    307: data.address?.unrestricted_value || data.address?.value || undefined,
    308: data.address?.unrestricted_value || data.address?.value || undefined,
    318: data.fio?.surname || data.management?.name?.split(' ')[0],
    319: data.fio?.name || data.management?.name?.split(' ')[1],
    320: data.fio?.patronymic || data.management?.name?.split(' ')[2],
    321: data.inn.length === innIndividualLength ? data.ogrn : undefined,
    322: data.inn.length === innIndividualLength ? data.inn : undefined,
    316: data.okved || undefined,
    317: data.okpo || undefined,
    2006: data.inn || undefined,
    2009: data.kpp || undefined,
  });
};

export const revertOrgData = (data: ICounteragent): ICounteragentsItem => {
  return ({
    id: data.id,
    inn: (data.type === 'entity' || data.type === 'individual') ? data.attrValues['2006'] : data.attrValues['322'],
    name: (data.type === 'entity' || data.type === 'individual')
      ? data.attrValues['305']
      : [ data.attrValues['101'], data.attrValues['102'], data.attrValues['103'] ].join(' '),
    fullName: (data.type === 'entity' || data.type === 'individual') ? data.attrValues['304'] : '',
    legalName: data.attrValues['314'],
    type: data.type,
  });
};
