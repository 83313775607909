import { FC, ReactElement } from 'react';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';

import styles from './drag-item.module.css';

interface DragItemProps {
  id: string,
  type: 'template'|'folder',
  folderId: string|null,
  name: string,
  isUpdated?: boolean,
  isMenuOpen?: boolean,
  isModalOpen?: boolean,
  children: ReactElement
}

const DragItem: FC<DragItemProps> = ({
  id, folderId, type, name, isUpdated, isMenuOpen, isModalOpen, children
}) => {
  const { attributes, listeners, setNodeRef: dragRef, transform, isDragging } = useDraggable({
    id,
    data: { id, type, name, folderId },
    disabled: id === 'main' || id === 'packs' || isMenuOpen || isModalOpen
  });
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    opacity: isDragging ? 0 : 1
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.border}></div>
      <div
        className={classNames(
          styles.children,
          isUpdated && styles.updated
        )}
        style={style}
        {...listeners}
        {...attributes}
        ref={dragRef}
      >
        {!isDragging && children}
      </div>
    </div>
  );
};

export default DragItem;
