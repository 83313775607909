import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput, Select } from '@forma/forma-ui-kit';

import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './users-list-header.module.css';

interface UsersListHeaderProps {
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { [key: string]: string }) => void,
  groups?: IUserGroupsItem[]
}

const UsersListHeader: FC<UsersListHeaderProps> = ({ onChangeSearch, onChangeFilter, groups }) => {
  const { t } = useTranslation();
  const options = groups?.map(({ id, name }) => ({ label: name, value: id }));

  const handleChangeCategories = (e: { target: { name: string, value: string } }) => {
    onChangeFilter({ usergroups: e.target.value });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('enter_fio_or_email')}
      />
      <div className={styles.select}>
        <Select
          name="usergroups"
          multiple
          onChange={handleChangeCategories}
          options={options}
          placeholder={t('filter_by_usergroup')}
        />
      </div>
      {/* <Button as={Link} className={styles.button} to={`/invite`} viewStyle="secondary" shadow>{t('invite_user')}</Button> */}
    </div>
  );
};

export default UsersListHeader;
