import { IProductsItem, IProductsCategoriesItem } from 'interfaces/products.interface';
import { baseApi } from '../store';

export interface IAddProductsData extends Omit<Partial<IProductsItem>, 'id'|'category'> {
  category: string,
}

interface ISaveProductsData extends Omit<IProductsItem, 'category'> {
  category: string,
}

export interface IRenderProductsData extends ISaveProductsData {
  count: number,
}

export const productsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<{ count: number, products: IProductsItem[] }, { from: number, to: number }>({
      query: data => ({
        url: '/products',
        method: 'GET',
        params: data
      }),
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation<IProductsItem, { [key: string]: string }>({
      query: data => ({
        url: '/products',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    importProducts: builder.mutation<IProductsItem[], IAddProductsData[]>({
      query: data => ({
        url: '/products/batch',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    getProductById: builder.query<IProductsItem, string>({
      query: id => ({
        url: `/products/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Product', id: id }],
    }),
    saveProduct: builder.mutation<IProductsItem, { [key: string]: string }>({
      query: ({ id, ...data }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'Product', id: data.id }, 'Products', 'ProductsCategories'],
    }),
    removeProduct: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/products/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Products'],
    }),

    getProductsCategories: builder.query<IProductsCategoriesItem[], void>({
      query: () => ({
        url: '/products/categories',
        method: 'GET'
      }),
      providesTags: ['ProductsCategories'],
    }),
    addProductsCategory: builder.mutation<IProductsCategoriesItem, { name: string }>({
      query: data => ({
        url: `/products/categories`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    updateProductsCategory: builder.mutation<IProductsCategoriesItem, { id: string, name: string }>({
      query: ({ id, ...data }) => ({
        url: `/products/categories/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    removeProductsCategory: builder.mutation<IProductsCategoriesItem, string>({
      query: id => ({
        url: `/products/categories/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
  })
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useImportProductsMutation,
  useGetProductByIdQuery,
  useSaveProductMutation,
  useRemoveProductMutation,
  useGetProductsCategoriesQuery,
  useAddProductsCategoryMutation,
  useUpdateProductsCategoryMutation,
  useRemoveProductsCategoryMutation
} = productsApi;
