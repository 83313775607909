import { memo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import Logo from 'components/Logo';
import { setMenuOpen, selectMenuOpen } from 'store/common/commonSlice';
import { useGetUsersListQuery } from 'store/user/userApi';
import { menu } from 'data/menu';

import MenuButton from './MenuButton';
import MenuItem from './MenuItem';

import styles from './side-menu.module.css';

export type TMenuItem = {
  id: string,
  icon: string,
  lang: string,
  href: string,
  target?: string,
  routes?: string[],
  disabled?: boolean,
  permissions?: string
};

export type TMenuItemParent = {
  items?: TMenuItem[]
} & TMenuItem;

const SideMenu = memo(() => {
  const dispatch = useDispatch();
  const { data: users } = useGetUsersListQuery({});
  const { pathname, hash } = useLocation();

  const { viewport } = useContext(ThemeContext);
  const isMenuOpen = useSelector(selectMenuOpen);

  const handleClickMenuButton = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  const handleCloseMenu = () => {
    if (['phone', 'tablet', 'tabletS'].includes(viewport)) dispatch(setMenuOpen(false));
  };

  return (
    <>
      <div className={styles.menuButton}>
        <MenuButton isOpen={isMenuOpen} onClick={handleClickMenuButton} />
      </div>

      <div className={classNames(styles.root, isMenuOpen && styles.open)}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} link="/" />
        </div>

        <ul className={styles.list}>
          {menu.map((item: TMenuItemParent) => (
            <MenuItem
              {...item}
              currentPathname={pathname + hash}
              onClick={handleCloseMenu}
              className={styles[item.id]}
              key={item.id}
            />
          ))}
          {(users && users.items.length > 1) ? (
            <MenuItem
              id="users"
              icon="/icons/menu/users.svg"
              lang="users"
              href="/users"
              currentPathname={pathname + hash}
              onClick={handleCloseMenu}
            />
          ) : (
            <MenuItem
              id="invite_user"
              icon="/icons/menu/add-user.svg"
              lang="invite_user"
              href="/invite"
              currentPathname={pathname + hash}
              onClick={handleCloseMenu}
            />
          )}
        </ul>
      </div>
    </>
  );
});

export default SideMenu;
