import { FC, MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Modal, Input, RemoveModal } from '@forma/forma-ui-kit';
import ContextMenu from 'components/ContextMenu';

import { ITemplatesPack } from 'interfaces/templates.interface';

import styles from './pack-item.module.css';

interface PackItemProps extends ITemplatesPack {
  navigation: {
    onShowContent: (id: string) => void,
    onOpenPack: (id: string) => void,
    onToggleMenu?: (id: string|null) => void
  },
  actions: {
    onRemove: (id: string) => void,
    onRename: (data: ITemplatesPack) => void,
  }
}

const PackItem: FC<PackItemProps> = ({ id, name, sides, templates, navigation, actions }) => {
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timer|null>(null);
  const [ isHover, setIsHover ] = useState(false);
  const [ isOptionsOpen, setOptionsOpen ] = useState(false);
  const [ isShowDelete, setShowDelete ] = useState(false);
  const [ isShowRename, setShowRename ] = useState(false);
  const [ newName, setName ] = useState(name);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (timer.current) clearTimeout(timer.current);
    if (e.detail === 1) {
      e.preventDefault();
      timer.current = setTimeout(() => navigation.onShowContent(id), 200);
    } else if (e.detail === 2) {
      navigation.onOpenPack(id);
    }
  };

  const handleClickRemove = (id: string) => {
    actions.onRemove(id);
    setShowDelete(false);
  };

  const handleClickRename = (id: string) => {
    actions.onRename({
      id,
      name: newName,
      sides,
      templates
    });
    setShowRename(false);
  };

  const icon = '/icons/explorer/templates_pack.svg';
  const hoverIcon = '/icons/explorer/templates_pack_hover.svg';

  const options = [
    {
      text: t('edit'),
      link: `/templates-packs/${id}`
    },
    {
      text: t('rename'),
      onClick: () => setShowRename(true)
    },
    {
      text: t('delete_pack'),
      onClick: () => setShowDelete(true)
    }
  ];

  return (
    <div className={classNames(styles.root, 'template-pack-item')}>
      <div
        className={styles.container}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
        onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
      >
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src={(isHover && hoverIcon) ? hoverIcon : icon}
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
      </div>

      <ContextMenu
        open={isOptionsOpen}
        items={options}
        control={open => <button className={classNames(styles.optionsButton, open && styles.active)}><ReactSVG src="/icons/more.svg" /></button>}
        offsetY={12}
        offsetX={14}
        onOpen={() => { setOptionsOpen(true); navigation.onToggleMenu && navigation.onToggleMenu(id); }}
        onClose={() => { setOptionsOpen(false); navigation.onToggleMenu && navigation.onToggleMenu(null); }}
      />

      <Modal
        open={isShowRename}
        onClose={() => setShowRename(false)}
        size="small"
        title={t('rename_pack')}
        buttons={[
          {
            children: t('save'),
            onClick: () => handleClickRename(id)
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            name="pack_name"
            type="text"
            placeholder={t('enter_pack_name')}
            onChange={e => setName(e.target.value)}
            showClearButton
            value={newName}
          />
        </div>
      </Modal>

      <RemoveModal
        open={isShowDelete}
        onClose={() => setShowDelete(false)}
        title={t('deleting_pack')}
        onRemove={() => handleClickRemove(id)}
        itemName={name}
      />
    </div>
  );
};

export default PackItem;
