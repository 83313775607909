import { FC, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer, LoadingButton, Tags, Textarea } from '@forma/forma-ui-kit';

import { ISharedFoldersTreeItem } from 'interfaces/sharedTemplates.interface';
import { useSendTemplateRequestMutation } from 'store/sharedTemplates/sharedTemplatesApi';

import styles from './no-items.module.css';

interface NoItemsProps {
  categories?: ISharedFoldersTreeItem[],
}

const NoItems: FC<NoItemsProps> = ({ categories }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ message, setMessage ] = useState<string>('');
  const [ sendRequest, { isLoading, isSuccess } ] = useSendTemplateRequestMutation();

  const handleClickSend = () => {
    sendRequest({ request: message });
  };

  const tags = categories && categories?.map(({ id, name }) => ({
    name,
    color: 'var(--color-black-primary)',
    backgroundColor: 'var(--color-white-primary)',
    onClick: () => navigate(`/static-templates/${id}`)
  }));

  if (isSuccess) return (
    <div className={styles.root}>
      <div className={styles.message}>
        <Trans i18nKey="succes_send_shared_template_request" />
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.message}>
        <Trans i18nKey="empty_shared_templates_description" />
      </div>
      <Tags className={styles.tags} items={tags} />
      <div className={styles.message}>
        {t('describe_what_you_search')}
      </div>
      <Textarea
        name="not_found_content"
        placeholder={t('describe_your_request')}
        viewStyle="secondary"
        onChange={e => setMessage(e.target.value)}
      />
      <ButtonsContainer>
        <LoadingButton
          viewStyle="primary"
          className={styles.button}
          isLoading={isLoading}
          onClick={handleClickSend}
          disabled={message.length < 3}
        >
          {t('send')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );
};

export default NoItems;
