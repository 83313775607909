import { FC, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import Video from 'components/Video';
import Dropdown from './Dropdown';
import Discount from './Discount';
import UserInvite, { UserInviteSkelet } from 'views/profile/UserInvite';
import CreateTemplateModal from 'views/FileExplorer/CreateTemplateModal';
import { analytics } from 'helpers/analytics';

import { ITariff } from 'interfaces/tariffs.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';

import styles from './guide.module.css';

interface GuideProps {
  hasTemplates?: boolean,
  hasUsers?: boolean,
  currentTariff?: ITariff,
  groups?: IUserGroupsItem[],
  onInvite: (data: { users: { login: string, name: string, groupids: string }[] }) => void,
  isInviteLoading?: boolean,
  isInviteSuccess?: boolean,
  onCreateTemplate: NonNullable<IFoldersEditingProps['onCreate']>
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const Guide: FC<GuideProps> = ({ hasTemplates, hasUsers, currentTariff, groups, onInvite, isInviteLoading, onCreateTemplate }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const isPhone = ['phone', 'tabletS'].includes(viewport);

  const onPlay = () => {
    analytics.checkVideoGide();
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>{t('guide.welcome_to_forma')}</h1>
          <div className={styles.description}>
            {t('guide.check_list_description')}
          </div>
          <ul className={styles.list}>
            <li className={styles.listItem}>{t('account.fill_templates_faster')}</li>
            <li className={styles.listItem}>{t('account.find_needed_templates')}</li>
          </ul>
          <div className={styles.buttonsBlock}>
            <CreateTemplateModal
              control={
                <Button size="small">{t('guide.set_it_up_for_me') }</Button>
              }
              defaultMode="select"
            />
            <Button size="small" viewStyle="secondary" as="a" href="/select-tariff">{t('guide.view_tariffs')}</Button>
          </div>
        </div>
        {!isPhone && (
          <Video
            className={styles.video}
            src={`${staticUrl}/videos/onboarding/guide_hello.mp4`}
            onPlay={onPlay}
          />
        )}
      </div>
      <div className={styles.steps}>
        <Dropdown
          className={classNames(styles.step, hasTemplates && styles.complete)}
          arrow={hasTemplates && <ReactSVG className={styles.stepIcon} src="/icons/tick.svg" wrapper="span" />}
          title={
            <>
              <span className={styles.number}>1</span>
              {t('guide.create_your_first_document')}
            </>
          }
        >
          <div className={styles.step1}>
            <div className={styles.step1content}>
              <ol>
                <li>{t('guide.create.step_1')}</li>
                <li>{t('guide.create.step_2')}</li>
                <li>{t('guide.create.step_3')}</li>
              </ol>
              <CreateTemplateModal
                control={
                  <Button
                    className={styles.step1button}
                    viewStyle="primary"
                  >
                    {t('create_template')}
                  </Button>
                }
                onCreate={onCreateTemplate}
              />
            </div>
            {!isPhone && (
              <Video
                className={styles.stepVideo}
                src={`${staticUrl}/videos/onboarding/guide_create.mp4`}
              />
            )}
          </div>
        </Dropdown>
        <Dropdown
          className={classNames(styles.step, hasUsers && styles.complete)}
          arrow={hasUsers && <ReactSVG className={styles.stepIcon} src="/icons/tick.svg" wrapper="span" />}
          title={
            <>
              <span className={styles.number}>2</span>
              {t('guide.invite_users_to_account')}
            </>
          }
        >
          {hasUsers ? (
            <>
              <div><Trans i18nKey="guide.invite_success" /></div>
              <div><Trans i18nKey="guide.invite_success_description" /></div>
            </>
          ) : (
            <>
              <div>{t('guide.invite_users_description')}</div>
              {(groups && currentTariff) ? (
                <UserInvite
                  groups={groups}
                  onSubmit={onInvite}
                  isSendLoading={isInviteLoading}
                  usersNow={currentTariff?.currentUsersCount ?? 0}
                  usersMax={currentTariff?.usersCount ?? 0}
                  showTitle={false}
                />
              ) : (
                <UserInviteSkelet />
              )}
            </>
          )}
        </Dropdown>
        <Discount />
      </div>
    </div>
  );
};

export default Guide;
