import classNames from 'classnames';

import styles from './static-templates-list-item.module.css';

const StaticTemplatesListItemSkelet = () => {
  return (
    <div className={classNames(styles.root, 'skeleton-loader')}>
    </div>
  );
};

export default StaticTemplatesListItemSkelet;
