import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IAuthState {
  token?: string|null
}

const initialState: IAuthState = {
  token: localStorage.getItem('token'),
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.token = null;
    },
    setToken: (state, action) => {
      localStorage.setItem('token', action.payload);
      state.token = action.payload;
    },
  }
});

export default authSlice.reducer;

export const { logoutUser, setToken } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
