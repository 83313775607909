import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, SearchInput } from '@forma/forma-ui-kit';
import { ICounteragentType } from 'interfaces/counteragents.interface';

import styles from './contragents-header.module.css';

interface ContragentsHeaderProps {
  type: ICounteragentType,
  onChangeSearch: (value: string) => void,
  onClickImport: () => void,
}

const ContragentsHeader: FC<ContragentsHeaderProps> = ({ onChangeSearch, type, onClickImport }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={type === 'entity' ? t('enter_inn_or_organization_name') : t('name_surname_lastname')}
      />
      <Button viewStyle="secondary" onClick={onClickImport} shadow>{t('add_from_file')}</Button>
      <Button as={Link} viewStyle="secondary" to={`/create-contragent/${type}`} shadow>{t('add_contragent')}</Button>
    </div>
  );
};

export default ContragentsHeader;