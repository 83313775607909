import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Button, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import SelectVariableModal from 'views/FilesEditor/VariableSelectModal';

import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery, useGetUserVariablesQuery
} from 'store/schemas/schemasApi';
import { delColumnTable, saveTableHeader, selectSaveTable } from 'store/counteragents/counteragentsSlice';
import { IUserVariableItem, IVariableItem } from 'interfaces/variables.interface';

import styles from './SaveContragentsTable.module.css';

const SaveContragentsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ changingColIndex, setChangingColIndex ] = useState<number|null>(null);

  const dataTable = useSelector(selectSaveTable);

  const { data: variablesSections } = useGetTemplateSectionsGroupsQuery();
  const { data: availableVariables } = useGetTemplateSectionsVariablesQuery();
  const { data: userVariables } = useGetUserVariablesQuery();

  const onToggleVar = (data: IVariableItem|IUserVariableItem) => {
    const index = dataTable?.header?.findIndex(({ tattrsNumber }) => tattrsNumber === data.id);
    if (index >= 0) dispatch(saveTableHeader({ tattrsNumber: null, name: data.name, type: null, index: index }));
    dispatch(saveTableHeader({ tattrsNumber: data.id, name: data.name, type: data.type, index: changingColIndex }));

    setChangingColIndex(null);
  };

  const handleClickChange = (index: number) => {
    setChangingColIndex(index);
  };

  const headers = !!dataTable.header?.length && dataTable.header.map((head, index) => {
    return {
      className: styles.column,
      children: (
        <div className={styles.headerItem}>
          <div className={classnames(styles.nameVariable, head.tattrsNumber && styles.selected) }>{head.name}</div>
          <Button
            viewStyle={head.tattrsNumber ? 'secondary' : 'primary'}
            onClick={() => { handleClickChange(index); }}
            size="extraSmall"
          >
            {head.tattrsNumber ? t('change_variable') : t('assign_variable')}
          </Button>
          <Button
            viewStyle="secondary"
            onClick={() => {
              dispatch(delColumnTable(index));
            }}
            size="extraSmall"
          >
            {t('delete_column')}
          </Button>
        </div>
      )
    };
  });

  return (
    <div className={classnames(styles.root, 'styled-scrollbar')}>
      <Table columns={headers}>
        {dataTable.data.slice(0, 5).map((row, index) => (
          <TableRow className={classnames(index === 3 && styles.opacityRowPreLast, index === 4 && styles.opacityRowLast)} key={index}>
            {row.map((cell, cellIndex) => (
              <TableCell className={styles.wrapperSaveTableCell} key={`${index}_${cellIndex}`}>
                <div className={styles.saveTableCell}>{cell}</div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <SelectVariableModal
        open={!!changingColIndex || changingColIndex === 0}
        onClose={() => setChangingColIndex(null)}
        variablesGroups={variablesSections}
        availableVariables={availableVariables}
        userVariables={userVariables}
        icon={checked => checked && <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkedIcon} />}
        onToggleVar={onToggleVar}
        selectedVariables={dataTable?.header?.map(({ tattrsNumber }) => tattrsNumber) || []}
      />
    </div>
  );
};

export default SaveContragentsTable;
