import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './pack-item.module.css';

interface PackItemAddProps {
  id: string
}

const PackItemAdd: FC<PackItemAddProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, styles.rootAdd)}>
      <Link className={styles.container} to={`/templates-packs/${id}`}>
        <div className={classNames(styles.iconContainer, styles.iconAdd)}>
          <img
            className={styles.icon}
            src="/icons/templates_pack_add.svg"
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {t('add_document')}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PackItemAdd;
