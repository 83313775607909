import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input } from '@forma/forma-ui-kit';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';

import styles from './create-folder.module.css';

interface CreateFolderProps {
  control: ReactElement,
  parentid?: string|null,
  onCreate: IFoldersEditingProps['onCreate']
}

const CreateFolder: FC<CreateFolderProps> = ({ control, parentid, onCreate }) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState('');

  const handleCreateFolder = () => {
    if (!name || name.length < 3) return null;

    setTimeout(() => {
      if (onCreate) onCreate({ name, type: 'folder', parentid });
      setName('');
    }, 0);
  };

  return (
    <Modal
      title={t('creating_folder')}
      control={control}
      closeOnDocumentClick={false}
      buttons={[
        {
          type: 'submit',
          children: t('create'),
          onClick: handleCreateFolder,
          disabled: name === '' || name.length < 3,
        },
        {
          children: t('cancel'),
          viewStyle: 'tertiary',
        }
      ]}
    >
      <div className={styles.modalContent}>
        <Input
          type="text"
          id="new_folder_name"
          name="name"
          placeholder={t('enter_folder_name')}
          onChange={e => setName(e.target.value)}
          showClearButton
          value={name}
        />
      </div>
    </Modal>
  );
};

export default CreateFolder;
