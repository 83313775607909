import { ITemplateSide } from 'interfaces/templates.interface';

type TCacheItem = {
  id: string,
  timestamp: number
}

export const checkCachedItems = (cacheTime: number) => {
  const cached = localStorage.getItem('editorItems');
  if (!cached) return null;

  let items = JSON.parse(cached);
  if (!items.length) return null;

  const now = Date.now();
  items = items.filter(({ id, timestamp }: TCacheItem) => {
    if (timestamp*1 + cacheTime*1000 < now) {
      localStorage.removeItem(id);
      return false;
    }
    return true;
  });

  const newCached = JSON.stringify(items);
  if (cached !== newCached) localStorage.setItem('editorItems', newCached);
};

export const getCachedItem = (id: string): { content: string, sides: ITemplateSide[] }|null => {
  const content = localStorage.getItem(`content_${id}`);
  const sides = localStorage.getItem(`sides_${id}`);
  if (sides && content) return { content, sides: JSON.parse(sides) };
  return null;
};

export const setCachedItem = (id: string, data: { content: string, sides: ITemplateSide[] }) => {
  const cached = localStorage.getItem('editorItems');
  const items = cached ? JSON.parse(cached) : [];
  const current = items.findIndex((item: TCacheItem) => item.id === id);
  if (current !== -1) items.splice(current, 1);
  items.push({ id, timestamp: Date.now() });
  localStorage.setItem('editorItems', JSON.stringify(items));
  localStorage.setItem(`content_${id}`, data.content);
  localStorage.setItem(`sides_${id}`, JSON.stringify(data.sides));
};

export const deleteCachedItem = (id: string): void => {
  localStorage.removeItem(`content_${id}`);
  localStorage.removeItem(`sides_${id}`);
};
