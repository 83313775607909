import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';

import styles from './modal-success.module.css';

interface ModalSuccessProps {
  open: boolean,
  onClose: () => void,
  isTransfer: boolean,
  onClickDownload: () => void
}

const ModalSuccess: FC<ModalSuccessProps> = ({ open, onClose, isTransfer, onClickDownload }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="100%"
      maxWidth="928px"
      open={open}
      onClose={onClose}
      containerClassName={classNames(styles.modal, 'modal-pay-success')}
      closeOnDocumentClick={false}
    >
      <img className={styles.image} src="/images/success-robot.svg" alt="" width={950} height={297} />
      <div className={styles.title}>
        {isTransfer ? t('subscription.payment_bill_created') : t('subscription.payment_success')}
      </div>
      <div className={styles.description}>
        {isTransfer ? t('subscription.pay_bill_or_lose_access') : t('subscription.your_tariff_activated')}
      </div>
      <ButtonsContainer className={styles.buttons}>
        {isTransfer ? (
          <Button
            className={styles.button}
            viewStyle="primary"
            onClick={onClickDownload}
          >
            {t('download_again')}
          </Button>
        ) : (
          <Button
            className={styles.button}
            viewStyle="primary"
            onClick={onClose}
          >
            {t('ok')}
          </Button>
        )}
      </ButtonsContainer>
    </Modal>
  );
};

export default ModalSuccess;
