import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { QuantityPlaceholder } from '@forma/forma-ui-kit';

import styles from './tariffs-list.module.css';

interface TariffItemSkeletProps {
  showAdditional?: boolean
}

const TariffItemSkelet: FC<TariffItemSkeletProps> = ({ showAdditional }) => {
  return (
    <div className={styles.item}>
      {showAdditional && (
        <div className={styles.additional}>
          <div className={styles.additionalMain}>
            <div className={classNames(styles.additionalTitle, 'skeleton-loader')} />
            <div className={classNames(styles.additionalSubtitle, 'skeleton-loader')} />
          </div>
          <div className={classNames(styles.additionalCount, styles.additionalCountPlaceholder)}>
            <QuantityPlaceholder />
          </div>
          <div className={styles.additionalDescr}>
            <span className={classNames(styles.additionalDescrRow, 'skeleton-loader')} />
            <span className={classNames(styles.additionalDescrRow, 'skeleton-loader')} />
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={classNames(styles.name, 'skeleton-loader')} />
          <div className={classNames(styles.oldPrice, 'skeleton-loader')} />
          <div className={classNames(styles.price, 'skeleton-loader')} />
          <div className={classNames(styles.priceDescr, 'skeleton-loader')} />
          <div className={classNames(styles.priceDescr, 'skeleton-loader')} />
        </div>
        <div className={styles.features}>
          {[...Array(6)].map((item, index) => (
            <div className={styles.feature} key={index}>
              <ReactSVG src="/icons/tick.svg" className={classNames(styles.featureIcon, 'skeleton-loader')} wrapper="span" />
              <span className={styles.featureText}>
                <span className={classNames(styles.featureTextRow, 'skeleton-loader')} />
                <span className={classNames(styles.featureTextRow, 'skeleton-loader')} />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TariffItemSkelet;
