import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import classNames from 'classnames';
import useEmblaCarousel, { UseEmblaCarouselType } from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

import './slider.css';
import { useDotButton } from './useDotButton';

interface SliderProps {
  className?: string,
  children: ReactNode,
  autoPlay?: number,
  loop?: boolean,
  draggable?: boolean,
  ref?: ForwardedRef<UseEmblaCarouselType[1]>
};

const Slider: FC<SliderProps> = forwardRef(({ className, children, autoPlay, loop, draggable = true }, ref) => {
  const dragCallBack = () => {
    if (draggable) return true;
    return;
  };

  const [ sliderRef, sliderApi ] = useEmblaCarousel({
    loop, watchDrag: dragCallBack
  }, [
    Autoplay({ playOnInit: !!autoPlay, delay: autoPlay })
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(sliderApi);

  if (ref && 'current' in ref) ref.current = sliderApi;

  return (
    <div className={classNames('slider', className)} ref={sliderRef}>
      <div className="slider__container">
        {children}
      </div>
      <div className="slider__dots">
        {scrollSnaps?.length > 1 && scrollSnaps.map((_, index) => (
          <span
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={classNames('slider__dot', index === selectedIndex && 'slider__dot--active')}
          />
        ))}
      </div>
    </div>
  );
});

export default Slider;