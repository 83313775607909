import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ThemeContext } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import { GridItem } from 'components/ItemsGrid';
import { ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';
import { format } from 'date-fns';

import styles from './static-templates-grid-item.module.css';

interface StaticTemplatesGridItemProps extends ISharedTemplatesItem {
  searchValue?: string,
  onClickAdd: (id: string) => Promise<string|null>,
  onClosePopup?: () => void,
  showAddTemplatesModal?: boolean,
  addedName?: string
}

const StaticTemplatesGridItem: FC<StaticTemplatesGridItemProps> = ({
  id, name, previews, searchValue, approvedAt, onClickAdd, onClosePopup, showAddTemplatesModal, addedName
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ isOver, setIsOver ] = useState<boolean>(false);

  const handleClickAdd = async () => {
    if (addedName) return;
    setIsOver(false);
    onClickAdd(id);
  };

  const handleClickUse = async () => {
    setIsOver(false);
    if (onClosePopup) onClosePopup();
    if (!addedName) {
      const result = await onClickAdd(id);
      // navigate(`/fill-document/${result}`); doesn't work
      window.location.href = `/fill-document/${result}`;
    } else {
      navigate(`/fill-document/${addedName}`);
    }
  };

  const searchIndex = searchValue ? name.toLowerCase().indexOf(searchValue.toLowerCase()) : -1;
  const endIndex = searchValue ? searchIndex + searchValue.length : -1;

  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  const [showMobilePopup, setShowMobilePopup] = useState<boolean>(false);

  const handlerClick = () => {
    if (!isPhone || showMobilePopup) return;
    setShowMobilePopup(true);
  };


  const handlerClickToClose = (event: any) => {
    setShowMobilePopup(false);
    event.stopPropagation();
  };

  const mobilePopup = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const e = event.target as Node;
      if (document.querySelector('.modal__container') && !document.querySelector('.modal__container')?.contains(e)) {
        return;
      };
      if (showAddTemplatesModal && document.querySelector('.modal__container')?.contains(e) && mobilePopup.current &&!mobilePopup.current.contains(e)) {
        setShowMobilePopup(false);
      };
      if (mobilePopup.current && !mobilePopup.current.contains(e) && !document.querySelector('.modal__container')?.contains(e)) {
        setShowMobilePopup(false);
      };
    };

    if (showMobilePopup) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMobilePopup, showAddTemplatesModal]);

  return (
    <GridItem className={styles.root}>
      <div
        className={classNames(styles.container, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={handlerClick}
      >
        <Button
          viewStyle="tertiary"
          size="extraSmall"
          className={classNames(styles.addButton, addedName && styles.active)}
          onClick={handleClickAdd}
          fullWidth
        >
          {addedName ? t('added_to_my_templates') : t('add_to_my_templates')}
        </Button>
        <div className={styles.imageContainer}>
          {previews?.length ? (
            <img className={styles.image} src={previews[0].url} alt={name} />
          ) : (
            <img className={styles.noImage} src="/icons/no-image.svg" width={64} height={64} alt="" />
          )}
          {!isPhone &&
          <div className={styles.buttons}>
            <div className={styles.informationBlock}>
              {approvedAt && <div>{t('date_update', { date: format(new Date(approvedAt), 'dd.MM.yyyy') })}</div>}
              <div>{t('template_has_been_prepared')}</div>
              <div className={styles.aboutPersonBlock}>
                <img src="/images/photomanager.jpg" className={styles.imagePhoto} alt="" />
                <div>
                  <div>{t('name_lawyer')}</div>
                  <div>{t('post_lawyer')}</div>
                </div>
              </div>
              <div>{t('more_experience')}</div>
            </div>
            <Button
              viewStyle="primary"
              className={styles.imageButton}
              onClick={handleClickUse}
              fullWidth
            >
              {t('use_template')}
            </Button>
            <Modal
              control={
                <Button
                  viewStyle="tertiary"
                  className={styles.imageButton}
                  fullWidth
                >
                  {t('preview')}
                </Button>
              }
              titleClassName={styles.modalTitle}
              title={
                <>
                  {name}
                  <Button
                    viewStyle="primary"
                    onClick={handleClickUse}
                  >
                    {t('use_this_template')}
                  </Button>
                </>
              }
            >
              <div>
                {previews.map(({ url }) => (
                  <img className={styles.modalImage} width="100%" src={url} alt={name} key={url} />
                ))}
              </div>
            </Modal>
          </div>}
          <div ref={mobilePopup}>
            {isPhone && showMobilePopup && <div className={classNames(styles.mobileButtons, showMobilePopup && styles.show)}>
              <div className={styles.closeBlock} onClick={handlerClickToClose}></div>
              <h3>{name}</h3>
              <div className={styles.informationBlock}>
                {approvedAt && <div>{t('date_update', { date: format(new Date(approvedAt), 'dd.MM.yyyy') })}</div>}
                <div>{t('template_has_been_prepared')}</div>
                <div className={styles.aboutPersonBlock}>
                  <img src="/images/photomanager.jpg" className={styles.imagePhoto} alt="" />
                  <div>
                    <div>{t('name_lawyer')}</div>
                    <div>{t('post_lawyer')}</div>
                  </div>
                </div>
                <div>{t('more_experience')}</div>
              </div>
              <div className={styles.buttonsForMobile}>
                <Button
                  viewStyle="primary"
                  onClick={handleClickUse}
                  fullWidth
                >
                  {t('use_template')}
                </Button>
                <Modal
                  control={
                    <Button
                      viewStyle="tertiary"
                      fullWidth
                    >
                      {t('preview')}
                    </Button>
                  }
                  showClose
                  closeOnDocumentClick={true}
                  titleClassName={styles.modalTitle}
                  title={
                    <>
                      {name}
                      <Button
                        viewStyle="primary"
                        onClick={handleClickUse}
                      >
                        {t('use_this_template')}
                      </Button>
                    </>
                  }
                >
                  <div>
                    {previews.map(({ url }) => (
                      <img className={styles.modalImage} width="100%" src={url} alt={name} key={url} />
                    ))}
                  </div>
                </Modal>
              </div>
            </div>}
          </div>
        </div>
      </div>
      <div className={styles.name}>
        {searchValue && searchIndex !== -1 ? <>
          {name.slice(0, searchIndex)}<span className="accent-text">{name.slice(searchIndex, endIndex)}</span>{name.slice(endIndex)}
        </> : name}
      </div>
    </GridItem>
  );
};

export default StaticTemplatesGridItem;
