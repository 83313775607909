import classNames from 'classnames';
import { GridItem } from '@forma/forma-ui-kit';

import styles from './static-templates-grid-item.module.css';

const StaticTemplatesGridItemSkelet = () => {
  return (
    <GridItem className={styles.root}>
      <div className={styles.container}>
        <div className={classNames(styles.addButton, 'skeleton-loader')} />
        <div className={styles.imageContainer} />
      </div>
      <div className={classNames(styles.name, 'skeleton-loader')} />
    </GridItem>
  );
};

export default StaticTemplatesGridItemSkelet;
