import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Switcher } from '@forma/forma-ui-kit';

import styles from './fill-from-card-button.module.css';

interface FillFromCardButtonProps {
  selectedId?: string,
  selectedName?: string,
  onClick: () => void,
  onCheck: (checked: boolean) => void
}

const FillFromCardButton = ({ selectedId, selectedName, onClick, onCheck }: FillFromCardButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <Button
        viewStyle="secondary"
        className={classNames(styles.fillButton, selectedId && styles.active)}
        iconClassName={styles.fillButtonIcon}
        shadow
        fullWidth
        onClick={onClick}
        icon={selectedId && (
          <Switcher
            name="update_counteragent"
            containerClass={styles.fillButtonUpd}
            onChange={e => onCheck(e.target.checked)}
            label={t('uikit:update_card_data')}
          />
        )}
        reversed
      >
        {selectedId ? (
          <span className={styles.fillButtonName}>
            {t('uikit:filled_from_card')} {selectedName}
          </span>
        ) : (
          t('uikit:fill_from_card')
        )}
      </Button>
    </div>
  );
};

export default FillFromCardButton;
