import { FC, useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, Input, Switcher, ThemeContext } from '@forma/forma-ui-kit';

import styles from './documents-request.module.css';

interface DocumentsRequestProps {
  items: string[],
  onChange: (items: string[]) => void
}

const DocumentsRequest: FC<DocumentsRequestProps> = ({ items, onChange }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ isEnabled, setEnabled ] = useState<boolean>(false);

  const handleChangeItem = (index: number, value: string) => {
    if (!items) return;
    const next = [...items];
    next[index] = value;
    onChange(next);
  };

  const handleRemoveItem = (index: number) => {
    if (!items) return;
    const next = [...items];
    next.splice(index, 1);
    onChange(next);
  };

  const handleAddItem = () => {
    if (!items) return;
    onChange([ ...items, '' ]);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Switcher
          containerClass={styles.title}
          size="small"
          id="request_documents"
          name="request_documents"
          label={t('request_photo_or_scan')}
          onChange={(e) => {
            if (e.target.checked) {
              setEnabled(true);
              onChange(['']);
            } else {
              setEnabled(false);
              onChange([]);
            }
          }}
        />
        {(viewport === 'phone' && isEnabled) && (
          <div className={styles.subtitle}>{t('write_what_need_attach')}</div>
        )}
      </div>
      <div className={styles.items}>
        {isEnabled && items.map((item, index) => (
          <div className={styles.item} key={index}>
            <span className={styles.number}>{index+1}.</span>
            <Input
              name={`request_documents_${index}`}
              className={styles.input}
              onChange={e => handleChangeItem(index, e.target.value)}
              maxLength={255}
              value={item}
              placeholder={t(viewport === 'phone' ? 'input_text' : 'write_what_need_attach')}
            />
            <Button
              className={styles.remove}
              viewStyle="textLight"
              icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
              title={t('delete')}
              onClick={() => handleRemoveItem(index)}
            />
          </div>
        ))}
      </div>
      {isEnabled && (
        <ButtonsContainer className={styles.buttons}>
          <Button
            className={styles.add}
            viewStyle="tertiary"
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            iconClassName={styles.addIcon}
            title={t('add')}
            onClick={handleAddItem}
          />
        </ButtonsContainer>
      )}
    </div>
  );
};

export default DocumentsRequest;
