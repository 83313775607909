import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { ButtonsContainer, Button } from '@forma/forma-ui-kit';

import styles from './InviteSuccess.module.css';

const UserInvite = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const users = state?.users as { id: string, name: string }[];

  useEffect(() => {
    if (state && !state.users) navigate('/profile');
    // eslint-disable-next-line
  }, [state]);

  return (
    <AdminLayout
      title={t('invite_user')}
      breadcrumbs={{ items: [
        { name: t('settings'), to: '/profile', as: Link },
        { name: t('my_users'), to: '/users', as: Link },
        { name: t('send_invite') }
      ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('send_invite')}</PageTitle>

      <div className={styles.inviteSuccess}>
        <h1 className={styles.inviteSuccessText}>
          {state?.users && (
            state.users.length === 1 ? (
              <Trans
                i18nKey="invite_send_name"
                values={{
                  name: state.users[0].name
                }}
                components={{
                  link_tag: <Link to={`/users/${state.users[0].id}`} className="accent-text" />
                }}
              />
            ) : (
              <Trans
                i18nKey="invite_send_names"
                components={{
                  link_tag:
                    <Fragment>
                      {users.map(({ id, name }, index) => (
                        <Fragment key={index}>
                          <Link to={`/users/${id}`} className="accent-text">{name}</Link>{index < state.users.length-1 && ', '}
                        </Fragment>
                      ))}
                    </Fragment>
                }}
              />
            )
          )}
        </h1>

        <ButtonsContainer className={styles.inviteSuccessButtons}>
          <Button
            as={Link}
            viewStyle="primary"
            className={styles.inviteSuccessButton}
            to="/users"
            shadow
          >
            {t('back_to')} {t('my_users')}
          </Button>
        </ButtonsContainer>
      </div>
    </AdminLayout>
  );
};

export default UserInvite;
