import { ComponentProps, Fragment, lazy, Suspense, useContext, useState } from 'react';
import update from 'immutability-helper';
import {
  FillProducts,
  calculateTotalValues,
  mapTotalForCalc,
  calculateProductValues,
  nanoid,
  ThemeContext,
  recalculateNumbers,
} from '@forma/forma-ui-kit';

const ProductsImportModal = lazy(() => import('pages/Products/ProductsImportModal'));

const FillSideTable = (props: ComponentProps<typeof FillProducts>) => {
  const [ importIndex, setImportIndex ] = useState<number>(0);
  const [ isImportOpen, setImportOpen ] = useState<boolean>(false);
  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  return (
    <Fragment>
      <FillProducts
        {...props}
        onClickImport={(index) => {
          setImportIndex(index);
          setImportOpen(true);
        }}
      />
      <Suspense>
        <ProductsImportModal
          open={isImportOpen}
          onClose={setImportOpen}
          columns={props.data.columns}
          onAddProducts={async (products) => {
            const customProductsIds = props.customProductsIds ?? [];
            const addedProducts = products.map(product => {
              const id = nanoid(12);
              const cols = props.data.columns.reduce((acc: { [key: string]: string }, current ) => (
                { ...acc, [current.id]: product[current.id] ?? '' }
              ), {});
              return calculateProductValues({ ...cols, id, number: '1', count: '1' }, currency.code);
            });

            const next = recalculateNumbers(update(props.products, { $splice: [[ importIndex+1, 0, ...addedProducts ]] }));
            const totalValues = calculateTotalValues(next, mapTotalForCalc(props.data.total, props.data.showTotalRow), currency.code);
            props.onChangeTable(next, totalValues, [ ...customProductsIds, ...addedProducts.map(({ id }) => id) ]);

            return true;
          }}
        />
      </Suspense>
    </Fragment>
  );
};

export default FillSideTable;
