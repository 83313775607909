import {
  ISharedFoldersTreeItem,
  ISharedTemplatesItem,
  ISharedTemplate,
  ISharedTemplatesFolder,
  ISharedTemplatesCategory
} from 'interfaces/sharedTemplates.interface';
import { baseApi } from 'store/store';

export const sharedTemplatesApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['SharedFoldersTree', 'SharedTemplates', 'SharedCategories'] })
  .injectEndpoints({
    endpoints: builder => ({
      getSharedFoldersTree: builder.query<ISharedFoldersTreeItem[], void>({
        query: () => ({
          url: '/sharedfolders/all',
          method: 'GET'
        }),
        providesTags: ['SharedFoldersTree']
      }),
      getSharedTemplates: builder.query<
        { categories: ISharedTemplatesCategory[], folders: ISharedTemplatesFolder[], templates: ISharedTemplatesItem[] },
        { name?: string, folderid?: string, categories?: string }|void
      >({
        query: data => ({
          url: '/sharedtemplates/',
          method: 'GET',
          params: data ? { ...data, approved: true } : { approved: true }
        }),
        providesTags: ['SharedTemplates']
      }),
      getSharedTemplateById: builder.query<ISharedTemplate, string>({
        query: id => ({
          url: `/sharedtemplates/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'SharedTemplates', id: id }],
      }),
      copySharedTemplate: builder.mutation<{ id: string, translatedName: string }, { id: string, folderid?: string|null }>({
        query: ({ id, ...data }) => ({
          url: `/sharedtemplates/${id}/copy`,
          method: 'POST',
          body: data
        }),
        invalidatesTags: ['FoldersTree', 'Folders', 'Search'],
      }),
      copySharedTemplatesByCategory: builder.mutation<{ templateIds: string }[], { categoryid: string, folderid?: string|null }>({
        query: data => ({
          url: `/sharedtemplates/copy`,
          method: 'POST',
          body: data
        }),
        invalidatesTags: ['FoldersTree', 'Folders', 'Search'],
      }),
      getSharedCategories: builder.query<ISharedTemplatesCategory[], void>({
        query: () => ({
          url: '/sharedtemplates/categories',
          method: 'GET'
        }),
        providesTags: ['SharedCategories']
      }),
      sendTemplateRequest: builder.mutation<
        { username: string, useremail: string, primaryemail: string, request: string }, { request: string }
      >({
        query: data => ({
          url: '/sharedtemplates/request',
          method: 'POST',
          body: data
        }),
      }),
    })
  });

export const {
  useGetSharedFoldersTreeQuery,
  useGetSharedTemplatesQuery,
  useGetSharedTemplateByIdQuery,
  useCopySharedTemplateMutation,
  useCopySharedTemplatesByCategoryMutation,
  useGetSharedCategoriesQuery,
  useSendTemplateRequestMutation
} = sharedTemplatesApi;