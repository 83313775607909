import { selectUserData } from 'store/user/userSlice';
import { useAppSelector } from 'store/hooks';
import ConfirmEmail from './ConfirmEmail';

import styles from './notification-bar.module.css';

const NotificationBar = () => {
  const user = useAppSelector(selectUserData);

  if (!user || user.emailConfirmed) return null;

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <ConfirmEmail email={user.login} />
      </div>
    </div>
  );
};

export default NotificationBar;
