import styles from './review.module.css';

const Review = () => {
  return (
    <div className={styles.root}>
      <div className={styles.message}>«С Формой мы вообще забыли про заполнение шаблонов руками»</div>
      <div className={styles.author}>
        <img src="/images/sudas_denis.webp" className={styles.photo} alt="Судас Денис Александрович" />
        <div className={styles.info}>
          <div className={styles.name}>Судас Денис Александрович</div>
          <div className={styles.position}>Коммерческий директор "Аватон"</div>
        </div>
      </div>
    </div>
  );
};

export default Review;
