import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import { selectLayout, setLayout } from 'store/common/commonSlice';

import styles from './view-switch.module.css';

interface ViewSwitchProps {}

const ViewSwitch: FC<ViewSwitchProps> = () => {
  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);

  return (
    <div className={styles.root}>
      <Button
        className={classNames(styles.button, layout === 'grid' && styles.active)}
        viewStyle="text"
        iconClassName={styles.icon}
        icon={<ReactSVG src="/icons/view-grid.svg" wrapper="span" />}
        onClick={() => dispatch(setLayout('grid'))}
      />
      <Button
        className={classNames(styles.button, layout === 'list' && styles.active)}
        viewStyle="text"
        iconClassName={styles.icon}
        icon={<ReactSVG src="/icons/view-list.svg" wrapper="span" />}
        onClick={() => dispatch(setLayout('list'))}
      />
    </div>
  );
};

export default ViewSwitch;
