import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyConfirmProfileEmailQuery } from 'store/user/userApi';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [ confirmUser, { isLoading, isSuccess, isError } ] = useLazyConfirmProfileEmailQuery();

  useEffect(() => {
    if (!key) navigate('/404');
    else confirmUser({ key });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    if (isSuccess) navigate('/profile#email-confirm-success');
    if (isError) navigate('/profile#email-confirm-error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <div />;
};

export default ConfirmEmail;
