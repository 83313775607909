import { FC, useEffect } from 'react';

interface IPageTitleProps {
  children: string
}

const PageTitle: FC<IPageTitleProps> = ({ children }) => {
  useEffect(() => {
    document.title = children;
    // eslint-disable-next-line
  }, [children]);

  return null;
};

export default PageTitle;
