import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import BillingList from 'views/tariffs/BillingList/BillingList';

import { useGetInvoicesQuery } from 'store/billing/billingApi';

const Billings = () => {
  const { t } = useTranslation();
  const { data: invoices, isLoading: isInvoicesLoading } = useGetInvoicesQuery();

  return (
    <AdminLayout
      title={t('subscription.billings_and_acts')}
      breadcrumbs={{ items: [
        { name: t('settings'), to: '/profile', as: Link },
        { name: t('subscription.selecting_tariff'), to: '/select-tariff', as: Link },
        { name: t('subscription.billings_and_acts') }
      ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('subscription.billings_and_acts')}</PageTitle>
      <BillingList items={invoices} isLoading={isInvoicesLoading} />
    </AdminLayout>
  );
};

export default Billings;
