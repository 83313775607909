import { useTranslation } from 'react-i18next';

import styles from './no-items.module.css';

const NoItems = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {t('empty_pack_description')}
      </div>
    </div>
  );
};

export default NoItems;
