import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './items-grid.module.css';

interface GridItemProps {
  children: ReactNode|ReactNode[],
  className?: string
}

export const GridItem: FC<GridItemProps> = ({ children, className, ...props }) => {
  return (
    <div className={classNames(styles.item, className)} {...props}>
      {children}
    </div>
  );
};

interface GridProps {
  id?: string,
  children: ReactNode|ReactNode[],
  className?: string,
  columns: number
}

export const Grid: FC<GridProps> = ({ id, children, className, columns }) => {
  return (
    <div className={classNames(styles.list, className, styles[`grid-${columns}`])} id={id}>
      {children}
    </div>
  );
};
