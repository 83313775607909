import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from '@forma/forma-ui-kit';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import StaticTemplates from 'views/StaticTemplatess';
import SelectActivityModal from 'views/profile/SelectActivityModal';

import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import {
  useCopySharedTemplateMutation,
  useGetSharedFoldersTreeQuery,
  useGetSharedTemplatesQuery
} from 'store/sharedTemplates/sharedTemplatesApi';
import { useGetActivitiesListQuery, useGetWorkspaceDataQuery } from 'store/user/userApi';
import { useCreateTemplateMutation } from 'store/templates/templatesApi';

import styles from './SpecialTemplates.module.css';

const SpecialTemplates = () => {
  const { t } = useTranslation();
  const [ isChangeActivity, setChangeActivity ] = useState<boolean>(false);

  const { data: workspace, isLoading: isWorkspaceLoading } = useGetWorkspaceDataQuery();
  const { data: sharedFolders, isLoading: isFoldersLoading } = useGetSharedFoldersTreeQuery();
  const { data: sharedTemplates, isFetching: isTemplatesLoading } = useGetSharedTemplatesQuery(
    { categories: workspace?.areaOfActivity?.id }, { skip: !workspace?.areaOfActivity }
  );
  const { data: categories } = useGetActivitiesListQuery();
  const [ copySharedTemplate ] = useCopySharedTemplateMutation();
  const [ createTemplate ] = useCreateTemplateMutation();

  const handleClickCopyTemplate = async (id: string) => {
    const result = await copySharedTemplate({ id });
    if ('data' in result && result.data) return result.data.translatedName;
    return null;
  };

  const handleCreateTemplate: IFoldersEditingProps['onCreate'] = async ({ name }) => {
    const result = await createTemplate({ name });
    if (!('data' in result)) return null;
    return { id: result.data.id, name: result.data.name, translatedName: result.data.translatedName };
  };

  return (
    <AdminLayout
      title={t('my_templates')}
      breadcrumbs={{ items: [ { as: Link, name: t('my_templates'), to: '/templates' }, { name: t('forma_templates') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <StaticTemplates
        isLoading={isWorkspaceLoading || isFoldersLoading || isTemplatesLoading}
        mainFolder={{
          id: 'special',
          name: <>
            {t('we_selected_templates_for_you_by_okved')}
            <div className={styles.headButtons}>
              <Button
                viewStyle="textLight"
                icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
                onClick={() => setChangeActivity(true)}
                className={styles.activityButton}
              >
                {workspace?.areaOfActivity?.name}
              </Button>
            </div>
          </>
        }}
        templates={sharedTemplates?.templates}
        tree={sharedFolders}
        categories={categories}
        onClickAddTemplate={handleClickCopyTemplate}
        onCreateTemplate={handleCreateTemplate}
      />

      <SelectActivityModal
        open={isChangeActivity}
        onClose={() => setChangeActivity(false)}
        selected={workspace?.areaOfActivity?.id}
      />
    </AdminLayout>
  );
};

export default SpecialTemplates;
