import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Select, Table, TableRow, TableCell, PaginationProps, PaginationWrapper, RemoveModal } from '@forma/forma-ui-kit';
import UsersListHeader from './UsersListHeader';

import { IUserItem, IUserGroupsItem } from 'interfaces/users.interface';

import styles from './users-list.module.css';

interface IUsersListProps {
  users?: IUserItem[],
  groups?: IUserGroupsItem[],
  pagination: PaginationProps
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { [key: string]: string }) => void,
  onChangeUserGroups: (id: string, groups: string[]) => void,
  onRemove: (id: string) => void
}

const UsersList: FC<IUsersListProps> = ({ users, groups, pagination, onChangeSearch, onChangeFilter, onChangeUserGroups, onRemove }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <UsersListHeader
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          groups={groups}
        />
      </div>
      <div className={styles.content}>
        <PaginationWrapper {...pagination}>
          <Table
            className={styles.table}
            columns={[
              { children: t('fullname_short'), className: styles.fio },
              { children: t('email_short'), className: styles.email },
              { children: t('group'), className: styles.group },
              { children: '', className: styles.buttons },
            ]}
          >
            {!!users?.length && (
              users.map(({ id, name, login, usergroups }) => (
                <TableRow key={id}>
                  <TableCell className={styles.fio} title={t('fullname_short')}>
                    <Link to={`/users/${id}`} className={styles.link}>{name}</Link>
                  </TableCell>
                  <TableCell className={styles.email} title={t('email_short')}>
                    <Link to={`/users/${id}`} className={styles.link}>{login}</Link>
                  </TableCell>
                  <TableCell className={styles.group} title={t('group')}>
                    {groups &&
                      <div className={styles.select}>
                        <Select
                          name={`usergroups_${id}`}
                          multiple
                          options={groups.map(({ id, name }) => ({ label: name, value: id }))}
                          onChange={e => onChangeUserGroups(id, e.target.value.split(','))}
                          value={usergroups.map(groupId => {
                            if (groups && !groups.find(group => group.id === groupId)) return null;
                            return groupId;
                          }).join(',')}
                        />
                      </div>
                    }
                  </TableCell>
                  <TableCell className={styles.buttons}>
                    <RemoveModal
                      title={t('deleting_user')}
                      control={
                        <Button
                          title={t('delete')}
                          className={styles.remove}
                          viewStyle="textLight"
                          icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                        />
                      }
                      onRemove={() => onRemove(id)}
                      message={t('do_yo_want_delete_user')}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </Table>
        </PaginationWrapper>
      </div>
    </div>
  );
};

export default UsersList;
