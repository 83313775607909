import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import '@forma/forma-ui-kit/dist/style.css';

import App from './App';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';
import './i18n';

import './main.css';
import './slider.css';

const root = document.getElementById('root');

if (root) {
  const reactRoot = ReactDOM.createRoot(root);

  reactRoot.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
