import { IRenderSideValues, ITemplatesPack } from 'interfaces/templates.interface';
import { IRenderProductsData } from 'store/products/productsApi';
import { baseApi } from '../store';

interface IRenderPackData {
  id: string,
  format: 'pdf'|'zip'|'docx',
  sideValues: IRenderSideValues[],
  products?: {
    [tableId: string]: { id: string, products: IRenderProductsData[] }
  }
}

export const packsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Packs'] }).injectEndpoints({
  endpoints: builder => ({
    getPacksList: builder.query<ITemplatesPack[], { name?: string, limit?: number }|void>({
      query: data => ({
        url: '/packs/',
        method: 'GET',
        params: data
      }),
      providesTags: ['Packs'],
    }),
    getPack: builder.query<ITemplatesPack, string>({
      query: id => ({
        url: `/packs/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Packs', id: id }]
    }),
    addPack: builder.mutation<ITemplatesPack, Omit<ITemplatesPack, 'id'>>({
      query: data => ({
        url: '/packs/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Packs'],
    }),
    updatePack: builder.mutation<ITemplatesPack, ITemplatesPack>({
      query: ({ id, ...data }) => ({
        url: `/packs/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => ['Packs', { type: 'Packs', id: data.id }],
    }),
    removePack: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/packs/${id}`,
        method: 'DELETE'
      }),
      // invalidatesTags: ['Packs'],
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        const putResult = dispatch(
          packsApi.util.updateQueryData('getPacksList', undefined, (draft: any[]) => { // fixme
            const index = draft.findIndex(item => item.id === id);
            draft.splice(index, 1);
          })
        );
        queryFulfilled.catch(putResult.undo);
      },
    }),

    renderPack: builder.mutation<string, IRenderPackData>({
      query: ({ id, ...data }) => ({
        url: `/packs/${id}/render`,
        method: 'POST',
        body: data,
        responseHandler: (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
      invalidatesTags: ['Documents'],
    }),
  })
});

export const {
  useGetPacksListQuery,
  useGetPackQuery,
  useAddPackMutation,
  useUpdatePackMutation,
  useRemovePackMutation,
  useRenderPackMutation
} = packsApi;
