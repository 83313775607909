import { FC, ReactNode, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './dropdown.module.css';

interface DropdownProps {
  title: ReactNode,
  children: ReactNode,
  arrow?: ReactNode,
  className?: string
}

const Dropdown: FC<DropdownProps> = ({ title, children, className, arrow }) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  return (
    <div className={classNames(styles.root, isOpen && styles.open, className)}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.title}>{title}</div>
        {arrow ? (
          arrow
        ) : (
          <ReactSVG src="/icons/dropdown-thin.svg" className={classNames(styles.arrow, isOpen && styles.open)} wrapper="span" />
        )}
      </div>
      {isOpen && (
        <div className={styles.content}>{children}</div>
      )}
    </div>
  );
};

export default Dropdown;
