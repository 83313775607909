import classNames from 'classnames';
import styles from './side-block.module.css';

const SidePlaceholder = () => {
  return (
    <div className={classNames(styles.placeholder, 'skeleton-loader')}></div>
  );
};

export default SidePlaceholder;
