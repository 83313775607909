import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer, Button, LoadingButton, Select, Input } from '@forma/forma-ui-kit';

import getValidationSchema from 'data/validationSchema';
import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './user-invite.module.css';

interface FormValues {
  users: {
    name: string,
    login: string,
    groupids: string
  }[]
}

interface UserInviteProps {
  groups: IUserGroupsItem[],
  onSubmit: (data: FormValues) => void,
  isSendLoading?: boolean,
  usersNow: number,
  usersMax: number,
  showTitle?: boolean
}

const UserInvite: FC<UserInviteProps> = ({ groups, onSubmit, isSendLoading, usersNow, usersMax, showTitle }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema({ users: ['name', 'login', 'groupids'] })),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (!fields.length && usersMax > usersNow) append({ name: '', login: '', groupids: groups ? groups[groups.length - 1].id : '' });
    // eslint-disable-next-line
  }, [fields, usersNow, usersMax]);

  return (
    <div>
      {showTitle && (
        <div className={styles.usersText}>
          {t('now_you_have')} {usersNow} <ReactSVG src="/icons/users.svg" className={styles.usersTextIcon} wrapper="span" />
        </div>
      )}
      {showTitle && (
        <div className={styles.usersText}>
          <span className={styles.accentText}>{t('still_available')} {usersMax > usersNow ? usersMax-usersNow : 0} <ReactSVG src="/icons/users.svg" className={styles.usersTextIcon} wrapper="span" /></span>
          <span className={styles.moreText}>
            <Trans
              i18nKey="users_limit_change_tariff"
              components={{
                link_tag: <Link to="/select-tariff" />
              }}
            />
          </span>
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div className={classNames(styles.item)} key={field.id}>
            <div className={styles.fields}>
              <Controller
                control={control}
                name={`users.${index}.name`}
                render={({ field: { value = '', ...rest } }) => (
                  <Input
                    id={`name_${index}`}
                    label={t('fields.name')}
                    placeholder={t('placeholders.name')}
                    viewStyle="secondary"
                    containerClass={styles.inputContainer}
                    labelClass={styles.label}
                    className={styles.input}
                    error={!!(errors.users?.[index]?.name?.message)}
                    errorMessage={errors.users?.[index]?.name?.message && t(`errors.${errors.users?.[index]?.name?.message}`)}
                    {...rest}
                    {...register}
                    value={value}
                    showClearButton
                  />
                )}
              />
              <Controller
                control={control}
                name={`users.${index}.login`}
                render={({ field: { value = '', ...rest } }) => (
                  <Input
                    id={`login_${index}`}
                    label={t('fields.login')}
                    placeholder={t('placeholders.login')}
                    inputMode="email"
                    viewStyle="secondary"
                    containerClass={styles.inputContainer}
                    labelClass={styles.label}
                    className={styles.input}
                    error={!!(errors.users?.[index]?.login?.message)}
                    errorMessage={errors.users?.[index]?.login?.message && t(`errors.${errors.users?.[index]?.login?.message}`)}
                    {...rest}
                    {...register}
                    value={value}
                    showClearButton
                  />
                )}
              />
              <Controller
                control={control}
                name={`users.${index}.groupids`}
                render={({ field: { value = '', ...rest } }) => (
                  <Select
                    id={`groupids_${index}`}
                    label={<>&nbsp;</>}
                    placeholder={t('placeholders.groups')}
                    viewStyle="secondary"
                    containerClass={classNames(styles.inputContainer, value && styles.filled)}
                    labelClass={styles.label}
                    className={styles.select}
                    error={!!(errors.users?.[index]?.groupids?.message)}
                    errorMessage={errors.users?.[index]?.groupids?.message && t(`errors.${errors.users?.[index]?.groupids?.message}`)}
                    {...rest}
                    {...register}
                    value={value}
                    options={groups?.map(({ id, name }) => ({ label: name, value: id }))}
                    multiple
                  />
                )}
              />
            </div>
            <Button
              viewStyle="textLight"
              icon={<ReactSVG src="/icons/close.svg" wrapper="span" />}
              iconClassName={styles.inviteRemoveIcon}
              className={styles.inviteRemove}
              onClick={() => remove(index)}
              title={t('delete')}
            />
          </div>
        ))}
        {usersMax-usersNow > fields.length && (
          <div
            className={classNames(styles.item, styles.itemAdd)}
            onClick={() => append({ name: '', login: '', groupids: groups ? groups[groups.length - 1].id : '' })}
          >
            <Button
              viewStyle="tertiary"
              className={styles.buttonAdd}
              iconClassName={styles.buttonAddIcon}
              icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            />
          </div>
        )}

        {usersMax > usersNow && (
          <ButtonsContainer className={styles.buttons}>
            <LoadingButton
              id="send_invite_button"
              type="submit"
              viewStyle="primary"
              disabled={!isValid}
              className={styles.buttonInvite}
              isLoading={isSendLoading}
              fullWidth
              shadow
            >
              {t('send_invite')}
            </LoadingButton>
          </ButtonsContainer>
        )}
      </form>
    </div>
  );
};

export default UserInvite;
