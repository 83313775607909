import { nanoid } from '@forma/forma-ui-kit';
import { createSlice } from '@reduxjs/toolkit';
import { INotificationItem } from 'interfaces/notifications.interface';
import { RootState } from '../store';

interface INotificationsState {
  items: INotificationItem[]
}

const initialState: INotificationsState = {
  items: []
};

export const notificationsSlice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    addNotification: (state, action) => {
      if (!action.payload.id) action.payload.id = nanoid();
      state.items.push(action.payload);
    },
    removeNotification: (state, action) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    }
  }
});

export default notificationsSlice.reducer;

export const { addNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications.items;
export const selectNotificationById = (id: string, state: RootState) => state.notifications.items.find(item => item.id === id);

