import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, FillFolder, FillingItemSkelet } from '@forma/forma-ui-kit';

import styles from './products-edit.module.css';

interface ProductsServicesEditProps {
  data?: { [key: string]: string },
  tattrs: any[], // fixme
  isLoading?: boolean,
  onSave: (data: { [key: string]: string }) => void,
  isSaveLoading: boolean
}

const ProductsServicesEdit: FC<ProductsServicesEditProps> = ({
  data, tattrs, isLoading, onSave, isSaveLoading
}) => {
  const { t } = useTranslation();

  const { register, control, reset, trigger, setValue, handleSubmit, formState: { errors, isValid } } = useForm<{ [key: string]: string }>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!data) return;
    reset(data);
    setTimeout(() => trigger(), 300);
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.items}>
            {[...Array(10)].map((key, index) => (
              <FillingItemSkelet key={index} />
            ))}
          </div>
        ) : (
          <FillFolder
            id="product"
            tattrs={tattrs}
            register={register}
            errors={errors}
            control={control}
            setValue={setValue}
          />
        )}
      </div>

      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          isLoading={isSaveLoading}
          disabled={!isValid}
          onClick={handleSubmit(onSave)}
        >
          {data ? t('save') : t('add')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );
};

export default ProductsServicesEdit;
