import useTimer from 'hooks/useTimer';

import styles from './timer.module.css';

type TTimerProps = {
  seconds: number,
  callback: () => void
}

const Timer = ({ seconds = 0, callback }: TTimerProps) => {
  const { left } = useTimer(seconds, callback);

  if (left === 0) return null;

  return (
    <div className={styles.circle}>{left}</div>
  );
};

export default Timer;
