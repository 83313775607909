import { FC, useContext, useState } from 'react';
import update from 'immutability-helper';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Input, ThemeContext, sideVariableHelpers, RemoveModal } from '@forma/forma-ui-kit';
import SideBlockGroups from '../SideBlockGroups';
import { VariablesPlaceholder } from '../Variables';
import VariableSelectModal from '../VariableSelectModal';

import { useAppDispatch } from 'store/hooks';
import { setOnboardingModal } from 'store/common/commonSlice';
import { ITemplateSideGroup } from 'interfaces/templates.interface';
import { IUserVariableItem, IVariableItem, IVariablesHierarhyItem } from 'interfaces/variables.interface';

import styles from './side-block.module.css';

interface SideBlockProps {
  id: string,
  index: number,
  name: string,
  folders: ITemplateSideGroup[],
  opacity?: number,
  isDragging?: boolean,
  fullScreen?: boolean,
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  onRemove: (sideId: string) => void,
  onChange: (data: { id: string, name: string, tattrFolders: ITemplateSideGroup[] }) => void,
  onAddVariable: (sideId: string, sideName: string, tattrId: string, tattrName: string, tattrType: string) => void,
  onChangeVariable: (tattrId: string, tattrName: string, tattrType: string) => void,
  onClickAddVariations: (sideId: string) => void
}

interface ControlsAddProps {
  onClickAddVariations: () => void,
  onClickAddVariable: () => void
}

const ControlsAdd = ({ onClickAddVariations, onClickAddVariable }: ControlsAddProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        className={styles.addButton}
        viewStyle="secondary"
        onClick={() => onClickAddVariations()}
        icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
        size="extraSmall"
        data-testid="add_variations"
      >
        {t('add_varitions_block')}
      </Button>
      {/* class side-add-variable for onboarding */}
      <Button
        className={classNames(styles.addButton, 'side-add-variable')}
        viewStyle="secondary"
        onClick={() => onClickAddVariable()}
        icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
        size="extraSmall"
        data-testid="add_variable"
      >
        {t('add_variable')}
      </Button>
    </>
  );
};

const SideBlock: FC<SideBlockProps> = ({
  id, index, name, folders, opacity, isDragging, fullScreen, variablesGroups, availableVariables, userVariables,
  onRemove, onChange, onAddVariable, onChangeVariable, onClickAddVariations
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewport } = useContext(ThemeContext);
  const [ isShow, setIsShow ] = useState<boolean>(true);
  const [ isShowModalSelect, setShowModalSelect ] = useState<boolean>(false);
  const [ isShowModalRemove, setShowModalRemove ] = useState<boolean>(false);
  const [ folderToAdd, setFolderToAdd ] = useState<string|null>(null);

  const {
    attributes,
    listeners,
    setNodeRef: sortRef,
    transform,
    transition,
  } = useSortable({ id, data: { index } });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity
  };

  const handleChangeVar = (varData: IUserVariableItem) => {
    const next = sideVariableHelpers.updateVariableInFolders(folders, varData);
    onChange({ id, name, tattrFolders: next });
    onChangeVariable(varData.id, varData.name, varData.type);
  };

  const handleToggleVar = (varData: IVariableItem|IUserVariableItem, checked: boolean, group?: { id: string, name: string }) => {
    let next = folders;
    if (checked) next = sideVariableHelpers.addVariableToFolder(folders, varData, { id: folderToAdd || group?.id, name: group?.name });
    else next = sideVariableHelpers.removeVariableFromFolders(folders, varData.id);

    onChange({ id, name, tattrFolders: next });
  };

  const handleRemoveVar = (varId: string) => {
    const next = sideVariableHelpers.removeVariableFromFolders(folders, varId);
    onChange({ id, name, tattrFolders: next });
  };

  const handleMoveFolders = (oldIndex: number, newIndex: number) => {
    onChange({ id, name, tattrFolders: update(folders, {
      $splice: [
        [ oldIndex, 1 ],
        [ newIndex, 0, folders[oldIndex] ]
      ]
    }) });
  };

  const handleChangeFolders = (folders: ITemplateSideGroup[]) => {
    onChange({ id, name, tattrFolders: folders });
  };

  return (
    <div className={classNames(styles.block, isDragging && styles.dragging)} ref={sortRef} style={style}>
      <div className={styles.header}>
        {/* class side-name for onboarding */}
        <div className={classNames(styles.title, 'side-name', `side-name-${id}`)} id={`side_name_${id}`}>
          <Input
            name={`side_${id}`}
            className={styles.nameInput}
            value={name}
            onChange={e => onChange({ id, name: e.target.value, tattrFolders: folders })}
          />
          <Button
            viewStyle="secondary"
            size="extraSmall"
            className={classNames(styles.toggler, isShow && styles.open)}
            onClick={() => setIsShow(!isShow)}
            icon={<ReactSVG className={styles.togglerIcon} src="/icons/dropup.svg" wrapper="span" />}
            iconClassName={styles.togglerIcon}
          />
        </div>
        <Button
          viewStyle="textLight"
          iconClassName={styles.questionIcon}
          icon={<ReactSVG src="/icons/info.svg" wrapper="span" />}
          onClick={() => dispatch(setOnboardingModal('sides_edit_modal'))}
        />
        {(fullScreen && !['phone', 'tabletS', 'tablet'].includes(viewport)) && (
          isShow ? (
            <ControlsAdd
              onClickAddVariations={() => onClickAddVariations(id)}
              onClickAddVariable={() => setShowModalSelect(true)}
            />
          ) : (
            <span className={styles.varsCount}>
              {t('count_variations', { count: folders.reduce((acc, { tattrs }) => acc + tattrs.length, 0) })}
            </span>
          )
        )}
        <Button
          className={styles.delete}
          viewStyle="text"
          title={t('delete')}
          onClick={() => setShowModalRemove(true)}
          icon={<ReactSVG className={styles.deleteIcon} src="/icons/trash.svg" wrapper="span" />}
        >
          {(fullScreen && !['phone', 'tabletS'].includes(viewport)) && t('remove_side')}
        </Button>
      </div>
      {isShow &&
        <>
          {(!fullScreen || ['phone', 'tabletS', 'tablet'].includes(viewport)) && (
            <div className={styles.add}>
              <ControlsAdd
                onClickAddVariations={() => onClickAddVariations(id)}
                onClickAddVariable={() => setShowModalSelect(true)}
              />
            </div>
          )}
          <div className={styles.vars}>
            {folders.length ? (
              <SideBlockGroups
                showInline={fullScreen && !['phone', 'tabletS'].includes(viewport)}
                sideId={id}
                sideName={name}
                folders={folders}
                onMove={handleMoveFolders}
                onChange={handleChangeFolders}
                onAddVariable={onAddVariable}
                onClickAddVariable={(folderId: string) => {
                  setFolderToAdd(folderId);
                  setShowModalSelect(true);
                }}
              />
            ) : (
              !!availableVariables && (
                <div>
                  <div className={styles.varsTitle}>{t('for_example')}:</div>
                  <VariablesPlaceholder
                    items={Object.values(availableVariables).slice(0, 3)}
                  />
                </div>
              )
            )}
          </div>
        </>
      }
      <div className={styles.handler} {...attributes} {...listeners}>
        <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
      </div>

      <RemoveModal
        open={isShowModalRemove}
        title={t('do_you_want_delete_side')}
        onClose={() => setShowModalRemove(false)}
        onRemove={() => setTimeout(() => onRemove(id), 100)}
      />
      <VariableSelectModal
        open={isShowModalSelect}
        selectedVariables={folders.reduce((acc: string[], current) => [ ...acc, ...Object.values(current.tattrs).map(({ id }) => id) ], [])}
        onClose={() => {
          setShowModalSelect(false);
          setFolderToAdd(null);
        }}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onToggleVar={handleToggleVar}

        onChange={handleChangeVar}
        onRemove={handleRemoveVar}
      />
    </div>
  );
};

export default SideBlock;
