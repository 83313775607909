import classnames from 'classnames';

import styles from './distribute-side-item.module.css';

const DistributeSideItemPlaceholder = () => {
  return (
    <div className={classnames(styles.root, styles.loading, 'skeleton-loader')} />
  );
};

export default DistributeSideItemPlaceholder;
