import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import classNames from 'classnames';
import { ButtonsContainer, Button, Modal, Checkbox } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { ITariff } from 'interfaces/tariffs.interface';
import { IPaymentSubscription } from 'interfaces/billing.interface';
import { currencies } from 'data/mock';

import {
  useGetAcquiringCardsQuery,
  useGetBillingInfoQuery,
  useRemoveAcquiringSubscriptionMutation
} from 'store/billing/billingApi';

import styles from './saved-payment-methods.module.css';

interface SavedPaymentMethodsProps {
  tariff?: ITariff,
  subscription?: IPaymentSubscription
}

const SavedPaymentMethods: FC<SavedPaymentMethodsProps> = ({ tariff, subscription }) => {
  const { t } = useTranslation();
  const currency = currencies.ru;

  const [ isCancelSubscription, setCancelSubscription ] = useState<boolean>(false);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const { data: cards, isLoading: isCardsLoading } = useGetAcquiringCardsQuery(null, { skip: !isOpen });
  const { data: billingInfo, isLoading: isBillingLoading } = useGetBillingInfoQuery(null, { skip: !isOpen });
  const [ cancelSubscription ] = useRemoveAcquiringSubscriptionMutation();

  const handleCancelSubscription = async () => {
    if (!subscription?.id) return;
    const result = await cancelSubscription();
    if ('data' in result) setCancelSubscription(false);
  };

  return (
    <>
      <Button
        viewStyle="secondary"
        onClick={() => setIsOpen(true)}
        shadow
      >
        {t('subscription.payment_methods')}
      </Button>
      <Modal
        width="100%"
        maxWidth="928px"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnDocumentClick={false}
      >
        <div className={styles.modal}>
          <div className={styles.title}>{t('subscription.your_tariff')} “{tariff?.tariff.name}”</div>
          <div className={styles.head}>
            <div>
              {subscription?.id && (
                <div className={styles.description}>
                  {t(
                    'subscription.next_bill_text',
                    { date: format(new Date(subscription.nextTransactionDate), 'dd.MM.yyyy'), price: formatPrice(subscription.amount, currency) }
                  )}
                </div>
              )}
            </div>
            <Button
              className={styles.buttonChange}
              viewStyle="tertiary"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              {t('subscription.change_tariff')}
            </Button>
          </div>
          {isCancelSubscription ? (
            <>
              <div className={styles.cancellation}>
                <div className={styles.title}>{t('subscription.cancel_subscription_title')}</div>
                <div className={styles.title}>{t('subscription.cancel_subscription_description')}</div>
              </div>
              <ButtonsContainer className={styles.buttons}>
                <Button
                  className={styles.buttonBack}
                  viewStyle="primary"
                  onClick={() => setCancelSubscription(false)}
                >
                  {t('subscription.not_cancel')}
                </Button>
                <Button
                  className={styles.buttonBack}
                  viewStyle="tertiary"
                  onClick={() => handleCancelSubscription()}
                >
                  {t('subscription.yes_cancel_subscription')}
                </Button>
                <div className={styles.notice}>
                  <Button
                    className={styles.noticeLink}
                    viewStyle="textLight"
                  >
                    {t('subscription.cancel_subscription')}
                  </Button>
                  <div className={styles.noticeText}>
                    {t('subscription.cancel_subscription_notice')}
                  </div>
                </div>
              </ButtonsContainer>
            </>
          ) : (
            <>
              <div className={styles.title}>{t('subscription.payment_methods')}:</div>
              <div className={styles.methods}>
                {cards?.map(({ id, cardLastFour }) => {
                  const isActive = subscription?.card?.id === id;
                  return (
                    <div className={classNames(styles.item, isActive && styles.active)} key={id}>
                      <Checkbox
                        containerClass={styles.checkbox}
                        name={`payment_${id}`}
                        checked={isActive}
                      />
                      <ReactSVG className={styles.icon} src="/icons/payment-card.svg" wrapper="span" />
                      <span className={styles.itemName}>****{cardLastFour}</span>
                      {/* <Button
                        viewStyle="text"
                        icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                      />
                      <Button
                        viewStyle="text"
                        icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
                      /> */}
                    </div>
                  );
                })}
                {billingInfo?.companyname && (
                  <div className={styles.item}>
                    <Checkbox containerClass={styles.checkbox} name="payment_billing" />
                    <ReactSVG className={styles.icon} src={`/icons/payment-${billingInfo.ogrn?.length === 15 ? 'org' : 'ip'}.svg`} wrapper="span" />
                    <span className={styles.itemName}>{billingInfo.companyname}</span>
                    {/* <Button
                      viewStyle="text"
                      icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                    />
                    <Button
                      viewStyle="text"
                      icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
                    /> */}
                  </div>
                )}
                {(!isCardsLoading && !cards?.length && !isBillingLoading && !billingInfo?.companyname) && (
                  <div className={styles.noItems}>
                    {t('subscription.saved_payment_methods_empty')}
                  </div>
                )}
              </div>
              <ButtonsContainer className={styles.buttons}>
                <Button
                  className={styles.buttonBack}
                  viewStyle="tertiary"
                  onClick={() => setIsOpen(false)}
                >
                  {t('go_back')}
                </Button>
                <div className={styles.notice}>
                  {subscription?.id && (
                    <Button
                      className={styles.noticeLink}
                      viewStyle="textLight"
                      onClick={() => setCancelSubscription(true)}
                    >
                      {t('subscription.cancel_subscription')}
                    </Button>
                  )}
                  {(tariff?.isPaid && tariff.isActive) && (
                    <div className={styles.noticeText}>
                      {t('subscription.cancel_subscription_description')}
                    </div>
                  )}
                </div>
              </ButtonsContainer>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SavedPaymentMethods;
