import { FC } from 'react';
import { DragOverlay, useDndContext } from '@dnd-kit/core';
import DistributeSideItem, { DistributeSideItemPlaceholder } from '../DistributeSideItem';

import { ITemplatesListSides } from 'interfaces/templates.interface';

import styles from './distribute-sides-list.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface GridProps {
  isLoading?: boolean,
  sides?: SortSideItem[],
  templatesSides?: ITemplatesListSides,
}

const DistributeSidesList: FC<GridProps> = ({ sides, templatesSides, isLoading }) => {
  const dndContext = useDndContext();
  const draggingItem = dndContext.active?.data.current;

  return (
    <div className={styles.root} id="templates_pack_fill_grid">
      {isLoading ? (
        [...Array(4).keys()].map(key => (
          <div className={styles.item} key={key}>
            <DistributeSideItemPlaceholder />
          </div>
        ))
      ) : (
        !!(sides?.length) && sides.map(({ templateId, sideIndex }) => {
          if (!(templatesSides && templatesSides[templateId])) return null;

          const templateName = templatesSides[templateId].name;
          const side = templatesSides[templateId].sides[sideIndex];

          return (
            <div className={styles.item} key={templateId + sideIndex}>
              <DistributeSideItem
                templateId={templateId}
                sideIndex={sideIndex}
                templateName={templateName}
                side={side}
              />
            </div>
          );
        })
      )}

      {(templatesSides && draggingItem) && (
        <DragOverlay zIndex={10}>
          <DistributeSideItem
            templateId={draggingItem.templateId}
            sideIndex={draggingItem.sideIndex}
            templateName={templatesSides[draggingItem.templateId].name}
            side={templatesSides[draggingItem.templateId].sides[draggingItem.sideIndex]}
          />
        </DragOverlay>
      )}
    </div>
  );
};

export default DistributeSidesList;
