import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import styles from './password-check.module.css';

type TPasswordCheckProps = {
  password: string
};

const PasswordCheck = ({ password = '' }: TPasswordCheckProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={classNames(styles.item, password.match(/[a-z]/) && styles.success)}>
        <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} /> {t('account.password_small_symbols')}
      </div>
      <div className={classNames(styles.item, password.length > 7 && styles.success)}>
        <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} /> {t('account.password_length')}
      </div>
      <div className={classNames(styles.item, password.match(/[1-9]/) && styles.success)}>
        <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} /> {t('account.password_numbers')}
      </div>
      <div className={classNames(styles.item, password.match(/[A-Z]/) && styles.success)}>
        <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} /> {t('account.password_large_symbols')}
      </div>
    </div>
  );
};

export default PasswordCheck;
