import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FillFolder, IExternalFillOwner, Modal } from '@forma/forma-ui-kit';
import { organizationTattrs } from 'data/mock';

import { useCreateExternalFillOwnerMutation, useUpdateExternalFillOwnerMutation } from 'store/externalfill/externalfillApi';

import styles from './edit-organization-modal.module.css';

interface EditOrganizationModalProps {
  open: boolean,
  onClose: () => void,
  data?: IExternalFillOwner,
  onSubmit: (ownerId: string) => void
}

const EditOrganizationModal: FC<EditOrganizationModalProps> = ({ open, onClose, data, onSubmit }) => {
  const { t } = useTranslation();
  const [ createExternalFillOwner, { isLoading: isAddLoading } ] = useCreateExternalFillOwnerMutation();
  const [ updateExternalFillOwner, { isLoading: isUpdateLoading } ] = useUpdateExternalFillOwnerMutation();

  const { register, control, setValue, reset, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset({ companyname: data?.name ?? '', address: data?.address ?? '', inn: data?.inn ?? '' });
    // eslint-disable-next-line
  }, [data]);

  const onSubmitForm = async (values: { [key: string]: string }) => {
    const res = data ? await updateExternalFillOwner({ id: data.id, name: values.companyname, address: values.address, inn: values.inn })
      : await createExternalFillOwner({ name: values.companyname, address: values.address, inn: values.inn });
    if (!('data' in res)) return;
    onSubmit(res.data.id);
    reset({ companyname: '', address: '', inn: '' });
    onClose();
  };

  const handleClose = () => {
    reset({ companyname: '', address: '', inn: '' });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('fill_info_organization_collect_privacy')}
      size="large"
      buttons={[
        {
          type: 'submit',
          form: 'contragent_form',
          children: t('save'),
          className: styles.save,
          isLoading: isAddLoading || isUpdateLoading,
          disabled: !isValid,
          closing: false
        }
      ]}
    >
      <form id="contragent_form" className={styles.form} onSubmit={handleSubmit(onSubmitForm)}>
        <FillFolder
          tattrs={organizationTattrs}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          itemsClassName={styles.modalField}
        />
      </form>
    </Modal>
  );
};

export default EditOrganizationModal;
