import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, RemoveModal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import { ICounteragentsItem, IDadataItem } from 'interfaces/counteragents.interface';
import { parseOrgData } from '../../utils';

import styles from './contragents-list.module.css';

interface ContragentsListProps {
  items?: ICounteragentsItem[],
  outside?: IDadataItem[],
  onRemove: (id: string) => void,
  onAdd: (attrValues: { [key: string]: string }) => void
}

const ContragentsListJuristic: FC<ContragentsListProps> = ({ items, outside, onRemove, onAdd }) => {
  const { t } = useTranslation();

  const handleAddCounteragent = async ({ data }: IDadataItem) => {
    const values = parseOrgData(data);
    onAdd(values);
  };

  return (
    <div className={classNames(styles.root, 'styled-scrollbar')}>
      <Table columns={[ { children: t('juristic_name') }, { children: t('inn') } ]}>
        {!!(items?.length) && (
          items.map(({ id, fullName, inn }) => (
            <TableRow key={id}>
              <TableCell className={styles.juristicName} title={t('juristic_name')}>
                <Link to={`/contragents/${id}`} className={styles.link}>{fullName}</Link>
              </TableCell>
              <TableCell className={styles.inn} title={t('inn')}>
                <Link to={`/contragents/${id}`} className={styles.link}>{inn}</Link>
              </TableCell>
              <TableCell className={styles.buttons}>
                <RemoveModal
                  control={
                    <Button
                      title={t('delete')}
                      className={styles.remove}
                      viewStyle="textLight"
                      icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                    />
                  }
                  onRemove={() => onRemove(id)}
                  title={t('do_yo_want_delete_contragent')}
                />
              </TableCell>
            </TableRow>
          ))
        )}
        {!!(outside?.length) && (
          <TableRow>
            <TableCell colSpan={3}>
              <div className={styles.subtitle}>{t('global_search_from_egrul')}:</div>
            </TableCell>
          </TableRow>
        )}
        {outside?.map(({ value, unrestricted_value, data }) => (
          <TableRow key={data.inn ?? value}>
            <TableCell className={styles.juristicName} title={t('juristic_name')} onClick={() => handleAddCounteragent({ value, unrestricted_value, data })}>
              <div className={styles.link}>
                {data.name?.full_with_opf || data.name?.full || value || (data.fio && `${data.fio.surname} ${data.fio.name} ${data.fio.patronymic}`)}
              </div>
            </TableCell>
            <TableCell className={styles.inn} title={t('inn')} onClick={() => handleAddCounteragent({ value, unrestricted_value, data })}>
              <div className={styles.link}>{data.inn}</div>
            </TableCell>
            <TableCell className={styles.buttons} onClick={() => handleAddCounteragent({ value, unrestricted_value, data })}>
              {' '}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default ContragentsListJuristic;
