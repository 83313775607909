import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ICommonState {
  layout: 'grid'|'list',
  isMenuOpen: boolean,
  onboardingModal?: string,
}

const initialState: ICommonState = {
  layout: localStorage.getItem('layout') === 'list' ? 'list' : 'grid',
  isMenuOpen: localStorage.getItem('menuOpen') === 'true',
};

export const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setLayout: (state, action) => {
      state.layout = action.payload;
      localStorage.setItem('layout', action.payload);
    },
    setMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
      localStorage.setItem('menuOpen', action.payload);
    },
    setOnboardingModal: (state, action) => {
      state.onboardingModal = action.payload;
    },
  }
});

export default commonSlice.reducer;

export const {
  setLayout,
  setMenuOpen,
  setOnboardingModal,
} = commonSlice.actions;

export const selectLayout = (state: RootState) => state.common.layout;
export const selectMenuOpen = (state: RootState) => state.common.isMenuOpen;
export const selectOnboardingModal = (state: RootState) => state.common.onboardingModal;
