import { FC, ReactNode } from 'react';

import styles from './create-template-button.module.css';

interface CreateTemplateButtonProps {
  label?: ReactNode
  name: ReactNode,
  description?: ReactNode,
  icon: string,
  iconWidth?: number,
  onClick: () => void
}

const CreateTemplateButton: FC<CreateTemplateButtonProps> = ({ label, name, description, icon, iconWidth = 83, onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.container}>
        <img className={styles.icon} src={icon} width={iconWidth} alt="" />
        <div className={styles.content}>
          <div className={styles.title}>{name}</div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </div>
    </div>
  );
};

export default CreateTemplateButton;
