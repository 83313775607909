import { useEffect, useState, Fragment, FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDndContext, DragOverlay } from '@dnd-kit/core';
import classNames from 'classnames';
import { LoadingIndicator, ThemeContext } from '@forma/forma-ui-kit';
import { Grid, GridItem } from 'components/ItemsGrid';

import NoItems from '../NoItems';
import { ExplorerGridItem, ExplorerGridItemBack, Placeholder } from './ExplorerGridItem';
import DragItem from './DragItem';
import DropItem from './DropItem';

import { IFolderListChidlren, IFoldersList } from 'interfaces/folders.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import {
  IFoldersEditingProps,
  IFoldersNavigationProps,
  IFoldersActionsProps,
  IFoldersOptionsStateParams,
  IFoldersFavouritesProps
} from 'interfaces/fileExplorer.interface';

import styles from './explorer-grid.module.css';

const preloadImages = (images: string[] = []) => {
  if (!images.length) return null;
  images.map(src => {
    const image = new Image();
    image.src = src;
    return image;
  });
};

const responsiveColumns = {
  desktop: 5,
  desktopM: 5,
  desktopS: 5,
  tablet: 4,
  tabletS: 3,
  phone: 2,
};

interface ExplorerGridProps {
  folder?: IFoldersList,
  items?: IFolderListChidlren[],
  active?: string|null,
  updated?: string|null,
  selected?: string[],
  isLoading: boolean,
  subitems?: IFolderListChidlren[],
  subitemsIsLoading: boolean,
  onToggleMenu: (id: string|null) => void,
  onToggleModal: (open: boolean) => void,
  isModalOpen?: boolean,
  onHoverSubmenuItem: (targetId: string|null) => void,
  favourites?: IFoldersFavouritesProps,
  navigation: IFoldersNavigationProps,
  editing?: IFoldersEditingProps,
  actions?: IFoldersActionsProps,
  userGroups?: IUserGroupsItem[],
  hideOptions?: boolean,
  optionsState: IFoldersOptionsStateParams
}

const SCROLL_OFFSET = 60;

const ExplorerGrid: FC<ExplorerGridProps> = ({
  folder, items, active, updated, isLoading, onToggleMenu, onToggleModal, isModalOpen, onHoverSubmenuItem, selected, favourites,
  subitems, subitemsIsLoading, navigation, editing, actions, userGroups, hideOptions, optionsState
}) => {
  let toShowGroup = false;
  const activeItem = (items && active) && items.find(({ id }) => id === active);
  const { t } = useTranslation();
  const [ columns, setColumns ] = useState<number>(0);
  const { viewport } = useContext(ThemeContext);

  const dndContext = useDndContext();
  const draggingItem = items?.find(({ id }) => id === dndContext.active?.data.current?.id)
    ?? subitems?.find(({ id }) => id === dndContext.active?.data.current?.id);

  useEffect(() => {
    preloadImages([
      '/icons/explorer/file_i.gif',
      '/icons/explorer/folder.gif',
      '/icons/explorer/folder_lock.svg',
      '/icons/explorer/folder_locked.svg',
      '/icons/explorer/folder_locked_hover.svg'
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setColumns(responsiveColumns[viewport]);
    // eslint-disable-next-line
  }, [viewport]);

  useEffect(() => {
    if (updated) {
      // timeout delay for update opened folder content
      setTimeout(() => {
        const elem = document.getElementById(`file_explorer_template_${updated}`);
        if (elem) window.scrollTo({ top: window.scrollY + elem.getBoundingClientRect().top - SCROLL_OFFSET, behavior: 'smooth' });
      }, 200);
    }

    // eslint-disable-next-line
  }, [updated]);

  const handleClickBack = () => {
    if (folder?.path && folder.path.length) {
      const last = folder.path[folder.path.length-1];
      navigation.onClickBack(last);
    } else {
      navigation.onClickBack();
    }
  };

  return (
    <>
      <DropItem
        id={folder?.id ?? null}
        dropId="root"
        name=""
        type="folder"
        className={styles.dropContainer}
      >
        <Grid columns={5}>
          {folder?.id && (
            <GridItem className={styles.item}>
              <DropItem
                id={folder.path?.length ? folder.path[folder.path.length-1].id : null}
                dropId={'back_' + (folder.path?.length ? folder.path[folder.path.length-1].id : '')}
                name=""
                type="folder"
                className={styles.dropContainer}
              >
                <ExplorerGridItemBack onClick={handleClickBack} isActive={optionsState.moveToItemId === 'back'} />
              </DropItem>
            </GridItem>
          )}
          {isLoading ? (
            [...Array(10)].map((item, index) => (
              <GridItem className={styles.item} key={index}>
                <Placeholder />
              </GridItem>
            ))
          ) : (
            (items && items.length) ? (
              <>
                {items.map((item, index) => {
                  const isLastOnRow = ((index + 1) % columns === 0) && index !== 0;
                  if (active === item.id) toShowGroup = true;
                  const showGroup = ((active === item.id || toShowGroup) && (isLastOnRow || index + 1 === items.length));
                  if (showGroup) toShowGroup = false;

                  return (
                    <Fragment key={item.id}>
                      <GridItem className={styles.item}>
                        <DragItem
                          id={item.id}
                          name={item.name}
                          type={item.type}
                          folderId={folder?.id ?? null}
                          isUpdated={updated === item.id}
                          isMenuOpen={optionsState.itemId === item.id}
                          isModalOpen={isModalOpen}
                        >
                          <DropItem
                            id={item.id}
                            dropId={item.id}
                            name={item.name}
                            type={item.type}
                            className={styles.dropContainer}
                          >
                            <ExplorerGridItem
                              item={item}
                              parent={(folder?.id && folder.name && folder.translatedName) ? {
                                id: folder.id,
                                name: folder.name,
                                translatedName: folder.translatedName,
                                parentId: folder.path?.length ? folder.path[folder.path.length-1].id : null
                              } : undefined}
                              isActive={active === item.id || optionsState.moveToItemId === item.id}
                              isUpdated={updated === item.id}
                              isSelected={selected?.includes(item.id)}
                              isMenuOpen={optionsState.itemId === item.id}
                              userGroups={userGroups}
                              onToggleMenu={onToggleMenu}
                              onToggleModal={onToggleModal}
                              onHoverSubmenuItem={onHoverSubmenuItem}
                              hideOptions={hideOptions}
                              editing={editing}
                              actions={actions}
                              navigation={navigation}
                              items={items}
                            />
                          </DropItem>
                        </DragItem>
                      </GridItem>
                      {(activeItem && showGroup) &&
                        <GridItem className={styles.group}>
                          <DropItem
                            id={active}
                            dropId={'group_' + active}
                            name={activeItem.name}
                            type={activeItem.type}
                            className={styles.dropContainer}
                          >
                            {subitemsIsLoading ? (
                              <div className={styles.groupLoader}>
                                <LoadingIndicator />
                              </div>
                            ) : (
                              subitems?.length ? (
                                <>
                                  <Grid columns={5} className={styles.groupItems}>
                                    {subitems.map((subitem: IFolderListChidlren) => (
                                      <GridItem className={styles.item} key={subitem.id}>
                                        <DragItem
                                          id={subitem.id}
                                          name={subitem.name}
                                          type={subitem.type}
                                          folderId={active}
                                          isUpdated={updated === subitem.id}
                                          isMenuOpen={optionsState.itemId === subitem.id}
                                          isModalOpen={isModalOpen}
                                        >
                                          <DropItem
                                            id={subitem.id}
                                            dropId={subitem.id}
                                            name={subitem.name}
                                            type={subitem.type}
                                            className={styles.dropContainer}
                                          >
                                            <ExplorerGridItem
                                              item={subitem}
                                              isActive={active === subitem.id || optionsState.moveToItemId === subitem.id}
                                              isUpdated={updated === subitem.id}
                                              isSelected={selected?.includes(subitem.id)}
                                              isMenuOpen={optionsState.itemId === subitem.id}
                                              userGroups={userGroups}
                                              parent={{
                                                id: activeItem.id,
                                                name: activeItem.name,
                                                translatedName: activeItem.translatedName,
                                                parentId: folder?.id
                                              }}
                                              onToggleMenu={onToggleMenu}
                                              onToggleModal={onToggleModal}
                                              onHoverSubmenuItem={onHoverSubmenuItem}
                                              hideOptions={hideOptions}
                                              items={items}
                                              navigation={{ ...navigation, onShowFolderContent: () => {} }}
                                              editing={editing}
                                              actions={actions}
                                              isInGroup
                                            />
                                          </DropItem>
                                        </DragItem>
                                      </GridItem>
                                    ))}
                                  </Grid>

                                  <div className={styles.showAll}>
                                    <button
                                      onClick={() => navigation.onOpenFolder(activeItem)}
                                      className={styles.showAllLink}
                                    >
                                      {t('show_all')}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <NoItems id={active} onCreate={editing?.onCreate} />
                              )
                            )}
                          </DropItem>
                        </GridItem>
                      }
                    </Fragment>
                  );
                })}
                {/* {pseudoItemsCount ? [...Array(pseudoItemsCount).keys()].map(key => (
                  <div className={styles.item} key={key}></div>
                )) : null} */}
              </>
            ) : (
              <GridItem className={styles.group}>
                <NoItems id={folder?.id} onCreate={editing?.onCreate} favourites={favourites} />
              </GridItem>
            )
          )}
        </Grid>
      </DropItem>

      <DragOverlay zIndex={10}>
        <GridItem className={classNames(styles.item, styles.draggable)}>
          {!!(draggingItem && items) && (
            <ExplorerGridItem
              item={draggingItem}
              parent={(folder?.id && folder.name && folder.translatedName) ? {
                id: folder.id,
                name: folder.name,
                translatedName: folder.translatedName,
                parentId: folder.path?.length ? folder.path[folder.path.length-1].id : null
              } : undefined}
              isSelected={selected?.includes(draggingItem.id)}
              userGroups={userGroups}
              onToggleMenu={onToggleMenu}
              onToggleModal={onToggleModal}
              onHoverSubmenuItem={onHoverSubmenuItem}
              hideOptions={hideOptions}
              editing={editing}
              actions={actions}
              navigation={navigation}
              items={items}
            />
          )}
        </GridItem>
      </DragOverlay>
    </>
  );
};

export default ExplorerGrid;
