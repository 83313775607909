import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';

import { IRendersItem } from 'interfaces/templates.interface';

interface ModalSignedDocumentProps {
  open: boolean,
  onClose: () => void,
  item: IRendersItem,
  onSigned: (id: string) => void
}

const ModalSignedDocument: FC<ModalSignedDocumentProps> = ({ open, onClose, item, onSigned }) => {
  const { t } = useTranslation();
  return <Modal
    size="small"
    open={open}
    onClose={onClose}
    title={<div>{!item?.confirmed ? t('need_signed') : t('need_remove_signed')} “{item?.name}”?</div>}
    buttons={[
      {
        children: !item?.confirmed ? t('sign') : t('remove_sign'),
        viewStyle: 'primary',
        onClick: () => onSigned(item.id),
      },
      {
        children: t('no'),
        viewStyle: 'tertiary',
        onClick: () => onClose(),
      }
    ]}
  >
  </Modal>;
};

export default ModalSignedDocument;