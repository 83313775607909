import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import useTimer from 'hooks/useTimer';
import { Link } from 'react-router-dom';
import { DISCOUNT_DURATION, DISCOUNT_PROMOCODE, DISCOUNT_TARIFF } from 'data/constants';
import { setCookie } from 'helpers/cookies';

import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';

import styles from './discount.module.css';
import { analytics } from 'helpers/analytics';

const Discount = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserData);

  const now = new Date();
  const endDate = user && new Date(user.workspace.createdAt);
  if (endDate) endDate.setDate(endDate.getDate() + 1);
  const diff = (endDate && endDate > now) ? Math.floor((endDate.getTime() - now.getTime())/1000) : 0;

  const { left } = useTimer(diff);

  if (!diff || !left) return null;

  const hours = `0${Math.floor(left / 3600)}`.slice(-2);
  const _left = left % 3600;
  const minutes = `0${Math.floor(_left / 60)}`.slice(-2);
  const seconds = `0${_left % 60}`.slice(-2);

  return (
    <div className={styles.root}>
      <img className={styles.icon} src="/images/gift.svg" width={44} height={40} alt="" />
      <div className={styles.content}>
        <div className={styles.title}>{t('guide.bonus')}</div>
        <div className={styles.description}>{t('guide.bonus_description')}</div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.timer}>
          {hours}:{minutes}:{seconds}
        </div>
        <Button
          as={Link}
          className={styles.button}
          viewStyle="primary"
          size="extraSmall"
          to="/select-tariff"
          onClick={() => {
            const date = new Date();
            localStorage.setItem('promocode', DISCOUNT_PROMOCODE);
            analytics.useDiscount();
            setCookie(
              'tariff',
              JSON.stringify({ tariff: DISCOUNT_TARIFF, duration: DISCOUNT_DURATION }),
              {
                path: '/',
                expires: new Date(date.setDate(date.getDate() + 7)),
                domain: window.location.hostname
              }
            );
          }}
        >
          {t('guide.use_discount')}
        </Button>
      </div>
    </div>
  );
};

export default Discount;
