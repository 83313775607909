const copyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  let result;

  textArea.value = text;

  textArea.style.top = '-1000px';
  textArea.style.left = '-1000px';
  textArea.style.width = '0';
  textArea.style.height = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    result = successful ? 'success' : 'error';
  } catch (err) {
    result = 'error';
  }

  document.body.removeChild(textArea);

  return result;
};

export default copyToClipboard;
