import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, PaginationProps, PaginationWrapper } from '@forma/forma-ui-kit';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import ProductsServicesHeader from './ProductsServicesHeader';
import ProductsServicesItem from './ProductsServicesItem';

import { IProductsCategoriesItem, IProductsItem } from 'interfaces/products.interface';

import styles from './products-services-list.module.css';

interface ProductsServicesListProps {
  items?: IProductsItem[],
  filters: { [key: string]: string|number },
  categories?: IProductsCategoriesItem[],
  pagination: PaginationProps,
  onClickImport?: () => void,
  onRemoveItem?: (id: string) => void,
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { category: string }) => void,
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const ProductsServicesList: FC<ProductsServicesListProps> = ({
  items, filters, categories, pagination, onRemoveItem, onClickImport,
  onChangeSearch, onChangeFilter
}) => {
  const { t } = useTranslation();

  const columns: { children: string|null, className: string }[] = [
    { children: t('sku'), className: styles.cellSku },
    { children: t('name'), className: styles.cellName },
    { children: t('category'), className: styles.cellCategory },
    { children: t('unit'), className: styles.cellUnit },
    { children: t('price'), className: styles.cellPrice }
  ];

  if (!!onRemoveItem) columns.push({ children: null, className: styles.cellButtons });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ProductsServicesHeader
          categories={categories}
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          onClickImport={onClickImport}
        />
      </div>

      <div className={styles.content}>
        {(!pagination.isLoading
          && !items?.length
          && !Object.values(filters).find(val => !!val)
          && (!pagination.page || pagination.page === 1)
        ) ? (
            <NoItemsVideo
              title={t('you_not_added_any_products')}
              src={`${staticUrl}/videos/onboarding/products_list_no_items.mp4`}
            />
          ) : (
            <PaginationWrapper {...pagination}>
              <Table columns={columns} fixed>
                {!!items?.length && (
                  items.map(item => <ProductsServicesItem {...item} onRemove={onRemoveItem} key={item.id} />)
                )}
              </Table>
            </PaginationWrapper>
          )}
      </div>
    </div>
  );
};

export default ProductsServicesList;
