const apiUrl = process.env.REACT_APP_API_URL;

const webView = {
  sendMessage: <T>(data: T) => {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  },
  renderTemplate: (id: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/templates/${id}/render`,
      body
    };
    webView.sendMessage(message);
  },
  renderPack: (id: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/packs/${id}/render`,
      body
    };
    webView.sendMessage(message);
  },
  renderDownload: (id: string, renderId: string, name: string, format: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/templates/${id}/render/${renderId}?format=${format}`
    };
    webView.sendMessage(message);
  },
  createInvoice: (name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/accounting/documents/invoice`,
      body
    };
    webView.sendMessage(message);
  },
  externalFillAttachemnts: (id: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/externalinfills/${id}/attachments`,
    };
    webView.sendMessage(message);
  },
  safeDownload: (id: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/attachments/${id}/download`,
    };
    webView.sendMessage(message);
  },
  safeDownloadArchive: (ids: string[], name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/attachments/downloadArchive?ids=${ids.join(',')}`,
    };
    webView.sendMessage(message);
  }
};

export default webView;
