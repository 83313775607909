import { FC, useState, useEffect, ReactElement } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './dropdown.module.css';

interface DropdownProps {
  open?: boolean,
  onToggle?: (open: boolean) => void,
  id?: string,
  title: string,
  children: ReactElement|ReactElement[],
  viewStyle?: 'white'|'empty'|false,
  size?: 'medium',
  className?: string
}

const Dropdown: FC<DropdownProps> = ({ open = false, onToggle, id, title, children, viewStyle, size, className }) => {
  const [ isOpen, setIsOpen ] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    // eslint-disable-next-line;
  }, [open]);

  const handleClose = () => {
    if (onToggle) onToggle(!isOpen);
    else setIsOpen(!isOpen);
  };

  return (
    <div className={classNames(styles.root, viewStyle && styles[viewStyle], size && styles[size], className)} id={id}>
      <div className={styles.header} onClick={handleClose} data-testid={id + '_header'}>
        {!size && <div className={styles.arrow} />}
        <span className={styles.title}>{title}</span>
        <ReactSVG src="/icons/dropdown-thin.svg" className={classNames(styles.arrow, isOpen && styles.open)} />
      </div>
      {isOpen &&
        <div className={styles.content}>{children}</div>
      }
    </div>
  );
};

export default Dropdown;
