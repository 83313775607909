import { FC } from 'react';
import classNames from 'classnames';

import { IVariableItem } from 'interfaces/variables.interface';

import styles from './variables-group.module.css';

interface VariablesPlaceholderProps {
  items: IVariableItem[],
}

const VariablesPlaceholder: FC<VariablesPlaceholderProps> = ({ items }) => {
  return (
    <div className={styles.groupPlacehoder}>
      <div className={styles.items}>
        {items.map(({ name }, index) => (
          <button
            type="button"
            className={classNames(styles.item, styles.disabled)}
            disabled
            key={index}
          >
            <span className={styles.itemText}>
              {name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default VariablesPlaceholder;
