const formatPhoneNumber = (number: string, mask: string) => {
  let result = '';
  let numberIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === '#') {
      if (numberIndex < number.length) {
        result += number[numberIndex];
        numberIndex++;
      } else {
        break;
      }
    } else if (mask[i] === number[numberIndex]) {
      result += number[numberIndex];
      numberIndex++;
    } else {
      result += mask[i];
    }
  }

  return result;
};

export default formatPhoneNumber;
