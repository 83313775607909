import { FC, ReactElement, ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FileDropzone, Modal } from '@forma/forma-ui-kit';

import { useAppDispatch } from 'store/hooks';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useSendTcRequestMutation } from 'store/tcrequests/tcrequestsApi';

import styles from './fill-request-modal.module.css';

interface FillRequestModalProps {
  open?: boolean,
  onClose?: (open: false) => void,
  control?: ReactElement,
  title?: ReactNode,
  showClose?: boolean
}

const FillRequestModal: FC<FillRequestModalProps> = ({ open, onClose, control, showClose = true }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [ files, setFiles ] = useState<File[]>([]);
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const [ sendRequest, { isLoading: isSendLoading } ] = useSendTcRequestMutation();

  const handleClickUpload = async () => {
    for await (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      await sendRequest(formData)
        .catch(() => {
          dispatch(addNotification({ content: t('errors.file_not_send', { name: file.name }), type: 'ERROR' }));
        });
    }

    setIsSuccess(true);
    setFiles([]);
  };

  const handleModalClose = () => {
    setIsSuccess(false);
    if (onClose) onClose(false);
  };

  return (
    <>
      <Modal
        open={open}
        control={control}
        maxWidth="730px"
        width="100%"
        onClose={handleModalClose}
        title={isSuccess ? null : t('setting_templates_for_me')}
        buttonsClassName={styles.buttons}
        showClose={showClose}
        closeOnDocumentClick={showClose}
        buttons={
          isSuccess ? [{
            className: styles.successButton,
            viewStyle: 'primary',
            children: t('fine')
          }] : [{
            className: styles.cancelButton,
            viewStyle: 'tertiary',
            children: t('setting_all_myself'),
          }, {
            className: styles.uploadButton,
            viewStyle: 'primary',
            children: t('send_templates_to_setting'),
            onClick: handleClickUpload,
            isLoading: isSendLoading,
            disabled: !files.length,
            closing: false,
          }]
        }
      >
        <input type="hidden" autoFocus />
        {isSuccess ? (
          <div className={styles.successModal}>
            <img className={styles.successImage} src="/images/onboarding_send_files_animation.svg" alt="" />
            <div className={styles.successTitle}>
              {t('thank_you')}
            </div>
            <div className={styles.successDescr}>
              {t('our_team_setting_templates_and_show_how')}
            </div>
          </div>
        ) : (
          <FileDropzone
            className={styles.dropzone}
            title={<Trans i18nKey="setting_templates_for_me_description" />}
            showName={false}
            placeholder=""
            accept={{
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/msword': ['.doc'],
              'application/rtf': ['.rtf'],
              'application/vnd.apple.pages': ['.pages'],
            }}
            maxFiles={20}
            onChange={setFiles}
          />
        )}
      </Modal>
    </>
  );
};

export default FillRequestModal;
