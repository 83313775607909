import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';
import { ITariffPrices } from 'interfaces/tariffs.interface';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';

import styles from './modal-calc.module.css';

interface ModalCalcProps {
  open: boolean,
  onClose: () => void,
  tariffName: string,
  tariffPrices: ITariffPrices
}

const ModalCalc: FC<ModalCalcProps> = ({ open, onClose, tariffName, tariffPrices }) => {
  const { t } = useTranslation();
  const currency = currencies.ru;
  const { basePrice, addUsersAmount, discountAmount, discountRestAmount, price } = tariffPrices;

  return (
    <Modal
      width="100%"
      maxWidth="928px"
      open={open}
      onClose={onClose}
      title={t('subscription.how_it_calc')}
      closeOnDocumentClick={false}
    >
      <div className={styles.modal}>
        <div className={styles.content}>
          {formatPrice(basePrice, currency)} ({t('subscription.tariff')} {tariffName})
          {!!addUsersAmount &&
            <> + {formatPrice(addUsersAmount, currency)} ({t('subscription.additional_accounts')})</>
          }
          {!!discountAmount &&
            <> - {formatPrice(discountAmount, currency)} ({t('subscription.duration_discount')})</>
          }
          {!!discountRestAmount &&
            <> - {formatPrice(discountRestAmount, currency)} ({t('subscription.current_tariff_left')})</>
          } = {formatPrice(price, currency)}
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            className={styles.button}
            viewStyle="primary"
            onClick={onClose}
          >
            {t('ok')}
          </Button>
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

export default ModalCalc;
