import classNames from 'classnames';

import styles from './menu-button.module.css';

type TypeMenuButtonProps = {
  isOpen: boolean,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const MenuButton = ({ isOpen, onClick }: TypeMenuButtonProps) => {
  return (
    <button className={classNames(styles.button, isOpen && styles.active)} onClick={onClick}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </button>
  );
};

export default MenuButton;