import { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import StaticTemplatesCategories from './StaticTemplatesCategories';
import StaticTemplatesHeader from './StaticTemplatesHeader';
import StaticTemplatesFolder from './StaticTemplatesFolder';
import NoItems from './NoItems';

import { IActivityItem } from 'interfaces/users.interface';
import { ISharedFoldersTreeItem, ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { useAppSelector } from 'store/hooks';
import { selectLayout } from 'store/common/commonSlice';

import styles from './static-templates.module.css';

interface StaticTemplatesProps {
  isLoading?: boolean,
  filterId?: string|null,
  mainFolder: { id?: string, name: ReactNode, folders?: ISharedFoldersTreeItem[] },
  folders?: ISharedFoldersTreeItem[],
  templates?: ISharedTemplatesItem[],
  tree?: ISharedFoldersTreeItem[],
  categories?: IActivityItem[],
  showFilters?: boolean,
  onClickAddTemplate: (id: string) => Promise<string|null>,
  onCreateTemplate: IFoldersEditingProps['onCreate']
}

const filterTemplates = (value: string, templates: ISharedTemplatesItem[]): ISharedTemplatesItem[] => (
  templates?.filter(({ name, keyWords }) => (
    name.toLowerCase().includes(value.toLowerCase()) || !!keyWords.find(name => name.toLowerCase().includes(value.toLowerCase()))
  ))
);

const filterTemplatesInFolders = (value: string, folders: ISharedFoldersTreeItem[]): ISharedFoldersTreeItem[] => (
  folders?.reduce((acc: ISharedFoldersTreeItem[], folder) => {
    const filteredTemplates = folder.templates && filterTemplates(value, folder.templates);
    const filteredFolders = folder.children && filterTemplatesInFolders(value, folder.children);

    if (filteredTemplates.length || filteredFolders?.length)
      return [ ...acc, { ...folder, templates: filteredTemplates, children: filteredFolders } ];

    return acc;
  }, [])
);

const StaticTemplates: FC<StaticTemplatesProps> = ({
  isLoading, filterId, mainFolder, folders, templates, categories, tree, showFilters, onClickAddTemplate, onCreateTemplate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const layout = useAppSelector(selectLayout);
  const { viewport } = useContext(ThemeContext);
  const [ searchValue, setSearchValue ] = useState<string>('');

  const filteredTemplates =
    useMemo(() => (searchValue && templates) ? filterTemplates(searchValue, templates) : templates, [searchValue, templates]);

  const filteredFolders =
    useMemo(() => (searchValue && folders) ? filterTemplatesInFolders(searchValue, folders) : folders, [searchValue, folders]);

  const handleSelectCategory = (categoryId: string|null) => {
    if (mainFolder.id) {
      if (categoryId) navigate(`/static-templates/${mainFolder.id}?filterId=${categoryId}`);
      else navigate(`/static-templates/${mainFolder.id}`);
    } else {
      if (categoryId) navigate(`/static-templates/?filterId=${categoryId}`);
      else navigate(`/static-templates`);
    }
  };

  return (
    <div className={styles.root}>
      {!['phone', 'tabletS'].includes(viewport) && (
        <div className={styles.filters}>
          <Button
            as={Link}
            to="/static-templates/special"
            viewStyle={mainFolder.id === 'special' ? 'primary' : 'secondary'}
            size="small"
            icon={<ReactSVG src="/icons/stars.svg" wrapper="span" />}
            className={styles.templatesButton}
          >
            {t('templates_selected_for_you')}
          </Button>
          <StaticTemplatesCategories
            isLoading={isLoading}
            items={tree}
            selected={mainFolder.id}
            categories={categories}
          />
        </div>
      )}
      <div className={styles.content}>
        <StaticTemplatesHeader
          isLoading={isLoading}
          name={mainFolder.name}
          categories={mainFolder.folders}
          selectedCategory={filterId}
          onSelectCategory={handleSelectCategory}
          onChangeSearch={setSearchValue}
          showFilters={showFilters}
          onCreateTemplate={onCreateTemplate}
        />

        {(!isLoading && !filteredTemplates?.length && !filteredFolders?.length) && (
          <NoItems categories={tree} />
        )}

        <StaticTemplatesFolder
          level={1}
          searchValue={searchValue}
          layout={layout}
          isLoading={isLoading}
          templates={filteredTemplates}
          onClickAddTemplate={onClickAddTemplate}
        />

        {filteredFolders?.map(({ id, name, children, templates }) => (
          <StaticTemplatesFolder
            level={1}
            searchValue={searchValue}
            layout={layout}
            name={name}
            folders={children}
            templates={templates}
            onClickAddTemplate={onClickAddTemplate}
            key={id}
          />
        ))}
      </div>
    </div>
  );
};

export default StaticTemplates;
